import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Text, Link, FontSizes, Icon } from '@fluentui/react';
import { IVistoPlan, VistoFocusItem, IAttachment, IVistoListItem, VistoActionItem, VistoKind } from 'sp';
import { ConfirmDeleteDialog, EditFocusDialog } from 'dialogs';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { CommandsFocus } from 'services/CommandFocus';
import { PlanDataService } from 'services/PlanDataService';
import { IconButtonAttachments, IconButtonMore, AttachDocument, ProgressBlock } from './common';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { SidebarActionListGroup } from './SidebarActionListGroup';
import { ExpandButton, RichTextTooltipHost } from 'components';
import { HistoryDialog } from './common/HistoryDialog';
import { StyledDraggable } from 'components/StyledDraggable';
import { ActionLassoDialog } from 'dialogs/common/ActionLassoDialog';
import { TextService } from 'services/TextService';
import { mx } from '../drawing/common/mx';
import { useSidebarShareDialog } from './SidebarShareHook';
import { CellKind } from 'shared/CellKind';

interface ISidebarFocusItemProps {
  plan: IVistoPlan;
  focus: VistoFocusItem;
  isCurrent: boolean;
}

export function SidebarFocusItem(props: ISidebarFocusItemProps) {

  const { isPlanEditEnabled, isPlanLive, isPlanLocal, isPopupOpen, setIsPopupOpen, editorUiRef, propertyBag, dispatchCommand, notify, selectionRef, setSelection } = React.useContext(AppContext);

  const [editDialogVisible, setEditDialogVisible] = React.useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [isLassoDialogVisible, setIsLassoDialogVisible] = React.useState(false);

  React.useEffect(() => {
    const isOpen = editDialogVisible || deleteDialogVisible || isLassoDialogVisible;
    setIsPopupOpen(isOpen);
    if (isOpen) {
      setSelection({ ...selectionRef.current, kind: VistoKind.Focus, guid: props.focus.guid});
    }
  }, [editDialogVisible, deleteDialogVisible, isLassoDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const onSetActive = () => {
    dispatchCommand(CommandsFocus.makeSetActiveFocusCommand(props.plan, props.focus, notify), { wrap: true, repaintCellIds: [] });
  };

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();
  
  const menuItems = [
    MenuItems.getSetActiveFocusMenuItem(isEditDisabled || props.focus.active, () => onSetActive()),
    MenuItems.getDefaultFocusMenuItem(isPopupOpen, isPlanEditEnabled, () => setEditDialogVisible(true)),
    MenuItems.getSelectFocusActionsMenuItem(isEditDisabled, () => setIsLassoDialogVisible(true)),
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, props.focus)),
    MenuItems.getDeleteFocusMenuItem(isEditDisabled || props.focus.active, () => setDeleteDialogVisible(true)),
  ];

  const deps = PlanDataService.getDependencis(props.plan.items, props.focus);

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(CommandsFocus.makeDeleteFocusCommand(props.plan, items, notify), { wrap: false, repaintCellIds: [] });
  };

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.focus, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const isExpanded = propertyBag?.expanded?.[props.focus.guid];
  const isSelected = selectionRef.current?.guid === props.focus.guid
  const isActive =  editDialogVisible || deleteDialogVisible || isAttachDocumentOpen;

  // const krs = PlanDataService.getFocusKPIs(props.plan, props.focus.guid);

  const onHeaderItemClick = React.useCallback((a: VistoActionItem) => {
    const graph = editorUiRef.current?.editor?.graph;
    mx.selectCell(graph, cell => mx.getCellGuid(cell) === a.dpGuid);
  }, [editorUiRef.current?.editor?.graph]);

  const actionFooter = React.useCallback((a: VistoActionItem) => {
    const dp = PlanDataService.getItemByGuid(props.plan.items, a.dpGuid);
    return dp ? <Text variant='mediumPlus'>{TextService.getVistoKindName(dp.kind)}: <Link onClick={() => onHeaderItemClick(a)}>{dp.name}</Link></Text> : null;
  }, [props.plan.items]);

  const actions = PlanDataService.getFocusActions(props.plan, props.focus.guid);
  const label = TextService.format(strings.SidebarActions_FocusGroup, { 
    title: TextService.formatTitle(props.focus, props.plan.items),
    count: actions.length 
  })

  const onItemClick = () => {
    setSelection({ ...selectionRef.current, kind: VistoKind.SO, guid: props.focus.guid });
  }

  return (
    <StyledDraggable isActive={isActive} isSelected={isSelected} dimmed={!props.isCurrent} key={props.focus.guid}>
      <Stack horizontal grow verticalAlign='start' onClick={onItemClick}>
        <ExpandButton expandKey={props.focus.guid} />
        <Stack grow>
          <Stack horizontal grow className='draghandle' verticalAlign='center' horizontalAlign='space-between'>
            <RichTextTooltipHost tooltip={props.focus.description} >
              <Link disabled={isPopupOpen} onClick={() => setEditDialogVisible(true)}>
                <Text variant='xLarge'>
                  <span style={{ paddingRight: 8 }}>{label}</span>
                  {props.isCurrent && <Icon iconName='FavoriteStarFill' />}
                </Text>
              </Link>
            </RichTextTooltipHost>
            <Stack horizontal>
              <IconButtonAttachments item={props.focus} />
              <IconButtonMore menuItems={menuItems} fontSize={FontSizes.large} />
            </Stack>
          </Stack>
          <ProgressBlock plan={props.plan} item={props.focus} />
          {isExpanded &&
            <React.Fragment>
              <div className='ql-editor' dangerouslySetInnerHTML={{ __html: props.focus.description }} ></div>
              <Stack tokens={{ padding: 's1 0 0 0', childrenGap: 's1' }}>
              <SidebarActionListGroup dragDisabled expandKey={props.focus.guid} plan={props.plan} actions={actions} />
              </Stack>
            </React.Fragment>
          }
          {editDialogVisible && <EditFocusDialog onDismiss={() => setEditDialogVisible(false)} plan={props.plan} focus={props.focus} />}
          {deleteDialogVisible && <ConfirmDeleteDialog planItems={props.plan.items} items={[props.focus, ...deps]} onDelete={deleteConfirmed} onDismiss={() => setDeleteDialogVisible(false)} />}
          {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
          {historyDialogVisible && <HistoryDialog item={props.focus} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
          {isLassoDialogVisible && <ActionLassoDialog plan={props.plan} focus={props.focus} onDismiss={() => setIsLassoDialogVisible(false)} />}
          {ShareLinkDialog}
        </Stack>
      </Stack>
    </StyledDraggable>
  );
}
