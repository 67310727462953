import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TextField, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { InfoBar } from 'components';
import { KeyResultDatePicker } from './KeyResultDatePicker';
import { KeyResultValueField } from './KeyResultValueField';

export function EditKeyResultValueDialog(props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  krv: VistoKeyResultValueItem;
  values: VistoKeyResultValueItem[];
  onDismiss: (changed: boolean, krv?: VistoKeyResultValueItem) => void;
}) {

  React.useEffect(() => trackClient.page('EditKeyResultValueDialog'), []);

  const { isPlanEditEnabled } = React.useContext(AppContext);

  const oldKrv = props.krv;
  const [newKrv, setNewKrv] = React.useState<VistoKeyResultValueItem>({ ...oldKrv });

  const duplicate = props.values.find(x => TextService.formatDate(x.valueDate) === TextService.formatDate(newKrv.valueDate) && x.guid !== newKrv.guid);

  const errorInfo = duplicate && ({
    type: NotificationType.warn,
    message: TextService.format(strings.EditKeyResultValueDialog_DuplicateDateWarning, { date: TextService.formatDate(duplicate.valueDate) }),
    error: TextService.format(strings.EditKeyResultValueDialog_DuplicateDateWarningDetails, {
      value: PlanKeyresultsService.formatKeyResultValue(duplicate.value, props.kr, false),
      units: props.kr.units
    })
  });

  const isTarget = newKrv.valueKind === KeyResultValueKind.Target;

  return (<ItemEditDialog
    onDismiss={(changed) => props.onDismiss(changed, newKrv)}
    plan={props.plan}
    oldItem={oldKrv}
    newItem={newKrv}
    isModal
    onCommit={() => Promise.resolve(props.plan)}
    validationIssues={typeof (newKrv.value) !== 'number' || !newKrv.valueDate}
    zIndex={10020}
  >
    <Stack tokens={{ childrenGap: 's1' }}>
      <InfoBar {...errorInfo} />
      <KeyResultValueField
        disabled={!isPlanEditEnabled}
        label={TextService.format(strings.EditKeyResultValueDialog_ValueLabel)}
        kr={props.kr}
        value={newKrv.value}
        setValue={val => setNewKrv({ ...newKrv, value: val })}
      />
      <KeyResultDatePicker
        plan={props.plan}
        label={TextService.format(strings.EditKeyResultValueDialog_DateLabel)}
        showMilestones={isTarget}
        disabled={!isPlanEditEnabled}
        date={newKrv.valueDate}
        setDate={d => setNewKrv({ ...newKrv, valueDate: d })}
      />
      <TextField
        disabled={!isPlanEditEnabled}
        autoComplete='off'
        label={TextService.format(strings.EditKeyResultValueDialog_CommentLabel)}
        multiline
        rows={3}
        value={newKrv.description}
        onChange={(evt, val) => setNewKrv({ ...newKrv, description: val })}
      />
    </Stack>
  </ItemEditDialog>);
}
