import * as React from 'react';
import { IDocumentCardStyles } from '@fluentui/react/lib/DocumentCard';
import { IStackStyles } from '@fluentui/react/lib/Stack';
import { IStyle, mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useTheme } from '@fluentui/react';

interface returnObjectStyles {
  itemContainerStyles: IStackStyles;
  deleteButtonContainerStyles: Partial<IStackStyles>;
  renderUserContainerStyles: Partial<IStackStyles>;
  documentCardStyles: Partial<IDocumentCardStyles>;
  documentCardDisabledStyles: Partial<IDocumentCardStyles>;
  documentCardHighlightedStyles: Partial<IDocumentCardStyles>;
  documentCardUserStyles: Partial<IDocumentCardStyles>;
  configurationListClasses: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const useCommentsStyles = (): returnObjectStyles => {
  const { palette } = useTheme();
  
  const itemContainerStyles: IStackStyles = {
    root: { paddingTop: 0, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 } as IStyle,
  };

  const deleteButtonContainerStyles: Partial<IStackStyles> = {
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  };

  const renderUserContainerStyles: Partial<IStackStyles> = {
    root: { paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 },
  };
  const documentCardStyles: Partial<IDocumentCardStyles> = {
    root: {
      marginBottom: 7,
      backgroundColor: palette.neutralLighterAlt,
      ':hover': {
        borderColor: palette.themePrimary,
        borderWidth: 1,
      } as IStyle,
    } as IStyle,
  };

  const documentCardHighlightedStyles: Partial<IDocumentCardStyles> = {
    root: {
      marginBottom: 7,
      backgroundColor: palette.themeLighter,
      border: 'solid 3px '+palette.themePrimary,
      ':hover': {
        borderColor: palette.themePrimary,
        borderWidth: 1,
      } as IStyle,
    } as IStyle,
  };

  const documentCardDisabledStyles: Partial<IDocumentCardStyles> = {
    root: {
      marginBottom: 7,
      backgroundColor: palette.neutralLighterAlt,
      opacity: 0.5,
      ':hover': {
        borderColor: palette.themePrimary,
        borderWidth: 1,
      } as IStyle,
    } as IStyle,
  };

  const documentCardUserStyles: Partial<IDocumentCardStyles> = {
    root: {
      marginTop: 2,
      backgroundColor: palette?.white,
      boxShadow: '0 5px 15px rgba(50, 50, 90, .1)',

      ':hover': {
        borderColor: palette.themePrimary,
        backgroundColor: palette.neutralLighterAlt,
        borderWidth: 1,
      } as IStyle,
    } as IStyle,
  };

  const configurationListClasses = mergeStyleSets({
    listIcon: mergeStyles({
      fontSize: 18,
      width: 18,
      height: 18,
      color: palette.themePrimary,
    }),
    nolistItemIcon: mergeStyles({
      fontSize: 28,
      width: 28,
      height: 28,
      color: palette.themePrimary,
    }),
    divContainer: {
      display: 'block',
    } as IStyle,
    titlesContainer: {
      marginBottom: 10,
      marginTop: 15,
      overflow: 'auto',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.neutralLighter,
      },
      '&::-webkit-scrollbar': {
        width: 5,
      },
    } as IStyle,
  });

  return {
    itemContainerStyles,
    deleteButtonContainerStyles,
    renderUserContainerStyles,
    documentCardStyles,
    documentCardDisabledStyles,
    documentCardHighlightedStyles,
    documentCardUserStyles,
    configurationListClasses,
  };
};
