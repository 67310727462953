import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Text, Link, FontSizes } from '@fluentui/react';
import { IVistoPlan, VistoKind, VistoActionItem, VistoFocusItem, IAttachment, IVistoListItem, VistoAssocItem } from 'sp';
import { ExpandButton, RichTextTooltipHost } from 'components';
import { EditActionDialog, ConfirmDeleteDialog, EditAssocDialog, CopyProgressLinkDialog } from 'dialogs';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { SidebarAssoc } from './SidebarAssoc';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { ICheckListItem } from 'services/ICheckListItem';
import { TextService } from 'services/TextService';
import { AttachDocument, ProgressBlock, IconButtonAttachments, IconButtonProgress, IconButtonMore } from 'frames/TopFrame/sidebars/common';
import { DeleteSourceLinkDialog, EditSourceLinkDialog } from 'dialogs/common';
import { makeGuidString } from 'shared/guid';
import { MoveActionToLopDpDialog } from 'dialogs/MoveActionToLopDp';
import { StyledDraggable } from 'components/StyledDraggable';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { HistoryDialog } from './common/HistoryDialog';
import { SidebarCheckLists } from 'frames/TopFrame/sidebars/common/SidebarCheckList';
import { IntegrationService } from 'services/IntegrationService';
import { useSidebarShareDialog } from './SidebarShareHook';

export function SidebarAction(props: {
  plan: IVistoPlan;
  action: VistoActionItem;
  dragDisabled?: boolean;
}) {

  // const actionFooter = React.useCallback((a: VistoActionItem) => {
  //   const focus = PlanDataService.getItemByGuid(props.plan.items, a.focusGuid);
  //   return focus ? <Text variant='mediumPlus'>{TextService.getVistoKindName(focus.kind)}: <Link onClick={() => onHeaderItemClick(a)}>{focus.name}</Link></Text> : null;
  // }, [props.plan.items]);

  // const onHeaderItemClick = React.useCallback((a: VistoActionItem) => {
  //   const graph = editorUiRef.current?.editor?.graph;
  //   mx.selectCell(graph, cell => mx.getCellKind(cell) === CellKind.FOCUS);
  // }, [editorUiRef.current?.editor?.graph]);

  const { propertyBag, dispatchCommand, notify, isPopupOpen, isPlanEditEnabled, isPlanLive, isPlanLocal, setIsPopupOpen, selectionRef, setSelection } = React.useContext(AppContext);
  
  const [editDialogVisible, setEditDialogVisible] = React.useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);
  const [newAssocDialogVisible, setNewAssocDialogVisible] = React.useState(false);
  // const [hideActionDialogVisible, setHideActionDialogVisible] = React.useState(false);
  const [editLinkDialogVisible, setEditLinkDialogVisible] = React.useState(false);
  const [deleteLinkDialogVisible, setDeleteLinkDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);

  const isSelected = selectionRef.current?.guid === props.action.guid;
  const isActive = editDialogVisible || deleteDialogVisible || newAssocDialogVisible || editLinkDialogVisible || deleteLinkDialogVisible || isAttachDocumentOpen;

  const isExpanded = propertyBag?.expanded?.[props.action.guid];

  const [checkList, setCheckList] = React.useState<ICheckListItem[]>(null);

  React.useEffect(() => {
    if (isExpanded) {
      setCheckList([]);
      IntegrationService.getCheckList(props.action.sourceItemUrl, props.plan).then(data => {
        setCheckList(data);
      }, err => {
        trackClient.error(`Unable to get check list data`, err);
      });
    }
  }, [isExpanded]);

  const validations = PlanDataService.getActionAssocs(props.plan, props.action.guid);

  React.useEffect(() => {
    const isOpen = editDialogVisible || newAssocDialogVisible || deleteDialogVisible || editLinkDialogVisible || deleteLinkDialogVisible;
    setIsPopupOpen(isOpen);
    if (isOpen) {
      setSelection({ ...selectionRef.current, kind: VistoKind.Action, guid: props.action.guid});
    }
  }, [editDialogVisible, deleteDialogVisible, newAssocDialogVisible, editLinkDialogVisible, deleteLinkDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const makeUpdateFocusCommand = (focusGuid: string) => {
    const cmd = {
      prepare: async () => {
        const newFocusGuid = focusGuid !== props.action.focusGuid ? focusGuid : null;
        const newFocus = PlanDataService.getItemByGuid<VistoFocusItem>(props.plan.items, newFocusGuid);

        const useFocusDates = props.action.useFocusDates;

        const changes = ChangesService.getChanges<Partial<VistoActionItem>>({
          focusGuid: props.action.focusGuid,
          startDate: props.action.startDate,
          endDate: props.action.endDate,
        }, {
          focusGuid: newFocusGuid,
          startDate:  useFocusDates ? (newFocus?.startDate ?? null) : props.action.startDate,
          endDate: useFocusDates ? (newFocus?.endDate ?? null) : props.action.endDate,
        });

        return Commands.makeUpdateUndoUnit([{ item: props.action, changes }], notify);
      },
      message: TextService.format(strings.Command_SettingActionFocus), 
      name: CommandName.UpdateFocusSidebar
    };
    return cmd;
  };

  const onSetFocus = (focusGuid: string) => {
    const cmd = makeUpdateFocusCommand(focusGuid);
    dispatchCommand(cmd, { wrap: true });
  };

  const focuses = PlanDataService.getItems<VistoFocusItem>(props.plan.items, VistoKind.Focus);

  const [moveActionDialogVisible, setMoveActionDialogVisible] = React.useState(false);
  const [copyLinkDialogVisible, setCopyLinkDialogVisible] = React.useState(false);

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();
  
  const menuItems = [
    MenuItems.getNewAssocMenuItem(isEditDisabled, () => setNewAssocDialogVisible(true)),
    MenuItems.getDefaultMenuItemAction(isPopupOpen, isPlanEditEnabled, () => setEditDialogVisible(true)),
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, props.action)),
    MenuItems.getDividerMenuItem(1),
    // MenuItems.getHideActionMenuItem(isEditDisabled, () => setHideActionDialogVisible(true)),
    MenuItems.getDeleteActionMenuItem(isEditDisabled, () => setDeleteDialogVisible(true)),
    MenuItems.getDividerMenuItem(2),
    MenuItems.getMoveActionMenuItem(isEditDisabled, () => setMoveActionDialogVisible(true)),
    MenuItems.getSetFocusMenuItem(isEditDisabled, focuses, props.action.focusGuid, onSetFocus),
    MenuItems.getDividerMenuItem(3),
    MenuItems.getProgressLinkMenuItem([
      MenuItems.getOpenProgressLinkMenuItem(!props.action.sourceItemUrl, props.action),
      MenuItems.getEditProgressLinkMenuItem(isEditDisabled, () => setEditLinkDialogVisible(true)),
      MenuItems.getBreakProgressLinkMenuItem(isEditDisabled || !props.action.sourceItemUrl, () => setDeleteLinkDialogVisible(true)),
      MenuItems.getDividerMenuItem(1),
      MenuItems.getCopyToClipboardMenuItem(() => setCopyLinkDialogVisible(true)),
    ])
  ];

  const deps = PlanDataService.getDependencis(props.plan.items, props.action);

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.action, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const newAssoc: VistoAssocItem = {
    kind: VistoKind.Assoc,
    guid: makeGuidString(),
    krGuid: null,
    soGuid: null,
    actionGuid: props.action.guid ?? null,
    confidence: 9
  };

  const onItemClick = (e: React.MouseEvent) => {
    setSelection({ ...selectionRef.current, kind: VistoKind.SO, guid: props.action.guid });
    e.stopPropagation();
  }

  return (
    <StyledDraggable dragDisabled={props.dragDisabled} isActive={isActive} isSelected={isSelected} key={props.action.guid}>
      <Stack horizontal grow verticalAlign='start' onClick={onItemClick}>
        <ExpandButton expandKey={props.action.guid} />
        <Stack grow tokens={{ childrenGap: 's1' }}>
          <Stack grow className='draghandle' horizontal horizontalAlign='space-between' verticalAlign='center'>
            <RichTextTooltipHost tooltip={props.action.description} >
              <Link disabled={isPopupOpen} onClick={() => setEditDialogVisible(true)}>
                <Text variant='large'>{props.action.name}</Text>
              </Link>
            </RichTextTooltipHost>
            <Stack horizontal>
              <IconButtonAttachments item={props.action} />
              <IconButtonProgress plan={props.plan} disabled={isPopupOpen} item={props.action} />
              <IconButtonMore menuItems={menuItems} fontSize={FontSizes.mediumPlus} />
            </Stack>
          </Stack>
          {isExpanded && <div className='ql-editor' dangerouslySetInnerHTML={{ __html: props.action.description }} ></div>}
          {isExpanded && propertyBag?.showValidations && validations.length > 0 &&
            <Stack grow horizontal={!isExpanded} tokens={{ childrenGap: 's2' }} >
              {validations.map(assoc => <SidebarAssoc key={assoc.guid} plan={props.plan} assoc={assoc} />)}
            </Stack>
          }
          <ProgressBlock item={props.action} plan={props.plan} />
          {isExpanded && <SidebarCheckLists checkList={checkList} />}
          {/* {isExpanded && props.footer} */}
        </Stack>
        {editDialogVisible && <EditActionDialog onDismiss={() => setEditDialogVisible(false)} plan={props.plan} action={props.action} />}
        {newAssocDialogVisible && <EditAssocDialog disableAction onDismiss={() => setNewAssocDialogVisible(false)} plan={props.plan} assoc={newAssoc} />}
        {deleteDialogVisible && <ConfirmDeleteDialog planItems={props.plan.items} items={[props.action, ...deps]} onDelete={deleteConfirmed} onDismiss={() => setDeleteDialogVisible(false)} />}
        {editLinkDialogVisible && <EditSourceLinkDialog plan={props.plan} item={props.action} onDismiss={() => setEditLinkDialogVisible(false)} />}
        {deleteLinkDialogVisible && <DeleteSourceLinkDialog plan={props.plan} item={props.action} onDismiss={() => setDeleteLinkDialogVisible(false)} />}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
        {historyDialogVisible && <HistoryDialog item={props.action} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
        {moveActionDialogVisible && <MoveActionToLopDpDialog onDismiss={() => setMoveActionDialogVisible(false)} plan={props.plan} action={props.action} />}
        {copyLinkDialogVisible && <CopyProgressLinkDialog plan={props.plan} onDismiss={() => setCopyLinkDialogVisible(false)} item={props.action} />}
        {ShareLinkDialog}
      </Stack>
    </StyledDraggable>
  );
}
