import { VistoKind } from './VistoKind';
import { IUserInfo } from './IUserInfo';
import { Operation } from './Operation';

export enum WSMessageType {
  status,
  edit,
  select,
  drawingRevision,
  item,
  disconnected
}

export interface IWSMessage {
  type: WSMessageType;
}

export interface IWSPlanEdit extends IWSMessage {
  type: WSMessageType.edit;
  set: boolean;
}

export interface IWSPlanSelect extends IWSMessage {
  type: WSMessageType.select;
  shape: string;
}

export interface IWSUserInfo extends IUserInfo {
  connection_id: string;
}

export interface IWSPlanStatus extends IWSMessage {
  type: WSMessageType.status;
  editor: string;
  drawing_revision: number;
  selection: { [connectionId: string]: string };
  users: IWSUserInfo[];
}

export interface IWSPlanRevision extends IWSMessage {
  type: WSMessageType.drawingRevision;
  drawing_revision: number;
}

export interface IWSItemOperationInfo {
  type: WSMessageType.item;
  operation: Operation;
  planId: string;
  kind: VistoKind;
  guid: string;
}
