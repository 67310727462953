import React from 'react';
import { DefaultButton, Stack, Text, useTheme } from '@fluentui/react';
import { AssistantFileDialog } from './AssistantFileDialog';
import { ApiOpenaiVectorStoreFileService } from 'services/api/ApiOpenaiVectorStoreFileService';
import { ApiOpenaiVectorStoreService } from 'services/api/ApiOpenaiVectorStoreService';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import strings from 'VistoWebPartStrings';

export const AssistantFilePicker = (props: {
  disabled: boolean;
}) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const { tid } = React.useContext(EnvContext);

  const onDismiss = () => {
    setIsOpen(false);
  }

  const [title, setTitle] = React.useState<string | undefined>(TextService.format(strings.AssistanFile_NoAttachments));

  const updateTitle = async () => {
    const store = await ApiOpenaiVectorStoreService.findVectorStore(tid);
    if (store) {
      const attachments = await ApiOpenaiVectorStoreFileService.getVectorStoreFileIds(store.id);
      setTitle(`${attachments.length} attachment(s)`);
    }
  }

  React.useEffect(() => {
    if (!props.disabled) {
      try {
        setTitle(TextService.format(strings.AssistanFile_FindingAttachments));
        updateTitle();
      } catch (e) {
        trackClient.error(`Unable to find attachments for ${tid}`, e);
        setTitle(TextService.format(strings.AssistanFile_UnableToFindAttachments));
      }
    }
  }, [tid, props.disabled]);

  const theme = useTheme();

  return (
    <Stack grow horizontal tokens={{ padding: 's2 0 0 0' }}>
      <Stack.Item grow>
        <Text variant='smallPlus' styles={{ root: { color: props.disabled ? theme.palette.neutralTertiary : theme.palette.neutralPrimary } }}>{title}</Text>
      </Stack.Item>
      <Stack.Item>
        <DefaultButton disabled={props.disabled} text={TextService.format(strings.AssistanFile_AttachmentsButton)} onClick={() => setIsOpen(true)} />
      </Stack.Item>
      {isOpen && <AssistantFileDialog onDismiss={onDismiss} onItemsChanged={updateTitle} />}
    </Stack>
  );
}
