import * as React from 'react';
import { BasicDialog } from './BasicDialog';
import { IVistoListItemWithProgress, IVistoPlan, VistoKind } from 'sp';
import { Checkbox, Stack, Text } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { TextService } from 'services/TextService';

import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';

export function DeleteSourceLinkDialog(props: {
  plan: IVistoPlan,
  item: IVistoListItemWithProgress,
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('DeleteSourceLinkDialog'), []);

  const { dispatchCommand, notify } = React.useContext(AppContext);

  const [breakChildLinks, setBreakChildLinks] = React.useState(true);

  const save = async () => {
    const command = Commands.makeBreakLinkAction(props.plan, props.item, breakChildLinks, CommandName.BreakLinkUsingDialog, notify);
    const plan = await dispatchCommand(command, { wrap: false });
    if (notify) {
      notify.plan(plan);
    }
  };

  const showBreakChildLinks = (props.item.kind === VistoKind.DP || props.item.kind === VistoKind.LOP);

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={save}
      buttonOkText={TextService.format(strings.DeleteSourceLinkDialog_ButtonOk)}
      buttonOkBusyText={TextService.format(strings.ConfirmDeleteDialog_ButtonOkBusyLabel)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.DeleteSourceLinkDialog_Title)}
      subText={TextService.format(strings.DeleteSourceLinkDialog_SubTitle)}
      >
      <Stack tokens={{ childrenGap: 'm' }}>
        <Text>{TextService.format(strings.DeleteSourceLinkDialog_Content, { title: TextService.formatTitle(props.item, props.plan.items)} )}</Text>
        {showBreakChildLinks && <Checkbox
          label={TextService.format(strings.DeleteSourceLinkDialog_CheckboxDeleteChild)}
          checked={breakChildLinks}
          onChange={(_, val) => setBreakChildLinks(!!val)}
        />}
      </Stack>
    </BasicDialog>
  );
}
