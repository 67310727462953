import { IFieldValueUser } from 'sp';
import { ImageCacheService } from './ImageCacheService';
import { UserInfoService } from './UserInfoService';

export class UserInfoPhotoService {

  public static async cacheUserPictures(assignedTo: IFieldValueUser[]) {
    await Promise.all(assignedTo.map(async (user) => await this.getUserPhotoUrl(user.userName)));
  }
  
  public static getCachedUserPhotoUrl(rawUserObjectId: string) {
    if (!rawUserObjectId)
      return null;

    const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);
    const imageUrl = `visplan://photo/${userObjectId}`;
    return ImageCacheService.getCachedImageUrl(imageUrl);
  }

  public static async getUserPhotoUrl(rawUserObjectId: string) {
    if (rawUserObjectId) {
      const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);
      const imageUrl = `visplan://photo/${userObjectId}`;
      return ImageCacheService.getImageAsync(imageUrl, () => {
        const graph = UserInfoService.getClient(userObjectId);
        return graph.users.getById(userObjectId).photo.getBlob();
      });
    }
  }
}
