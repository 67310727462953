import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Text, Link, ProgressIndicator, FontSizes } from '@fluentui/react';
import { IVistoPlan, VistoSoItem, IAttachment, IVistoListItem, VistoKeyResultItem, VistoKind, KeyResultType } from 'sp';
import { ConfirmDeleteDialog, EditSoDialog } from 'dialogs';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IconButtonAttachments, IconButtonMore, AttachDocument } from './common';
import { ExpandButton, RichTextTooltipHost } from 'components';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { HistoryDialog } from './common/HistoryDialog';
import { SidebarKeyResultActionList } from './SidebarKeyResultActionList';
import { EditKeyResultDialog } from 'dialogs/EditKeyResultDialog';
import { makeGuidString } from 'shared/guid';
import { CommandsKeyResult, IKeyResultChanges } from 'services/CommandsKeyResult';
import { AssigneeBlock } from './common/AssigneeBlock';
import { LicenseService } from 'services/LicenseService';
import { KeyResultService } from 'services/KeyResultService';
import { useSidebarShareDialog } from './SidebarShareHook';
import { StyledDraggable } from 'components/StyledDraggable';

export function SidebarPositionSo(props: {
  plan: IVistoPlan;
  so: VistoSoItem;
}) {

  const [editDialogVisible, setEditDialogVisible] = React.useState(false);
  const [deleteDialogVisible, setDeleteSoDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [newKeyResultDialogVisible, setNewKeyResultDialogVisible] = React.useState(false);

  const { isPlanEditEnabled, isPlanLive, isPlanLocal, isPopupOpen, setIsPopupOpen, dispatchCommand, notify, propertyBag, selectionRef, setSelection } = React.useContext(AppContext);
  React.useEffect(() => {
    const isOpen = editDialogVisible || deleteDialogVisible || newKeyResultDialogVisible;
    setIsPopupOpen(isOpen);
    if (isOpen) {
      setSelection({ ...selectionRef.current, kind: VistoKind.SO, guid: props.so.guid});
    }
  }, [editDialogVisible, deleteDialogVisible, newKeyResultDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const newKeyResult: VistoKeyResultItem = {
    kind: VistoKind.KeyResult,
    soGuid: props.so.guid,
    guid: makeGuidString(),
    units: '%',
    keyResultType: KeyResultType.Numeric
  };

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const menuItems = [
    MenuItems.getDefaultSoMenuItem(isPopupOpen, isPlanEditEnabled, () => setEditDialogVisible(true)),
    ...(LicenseService.license?.okrEnabled ? [MenuItems.getNewKeyResultMenuItem(isEditDisabled, () => setNewKeyResultDialogVisible(true))] : []),
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, props.so)),
    MenuItems.getDeleteSoMenuItem(isEditDisabled, () => setDeleteSoDialogVisible(true)),
  ];

  const deps = PlanDataService.getDependencis(props.plan.items, props.so);

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.so, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const progressPercents = typeof (props.so.percentComplete) && props.so.percentComplete;
  const progressLabel = TextService.format(strings.SidebarPositionSo_ProgressLabel, { percents: TextService.formatPercents(progressPercents, false, true) });

  const isExpanded = propertyBag?.expanded?.[props.so.guid];

  const onSaveKeyResult = (changes: IKeyResultChanges) => {
    const targetsChanges = CommandsKeyResult.getKeyResultListChanges(changes.oldTargets, changes.newTargets);
    return dispatchCommand(CommandsKeyResult.makeKeyResultUpdateCommand(changes.oldKr, changes.newKr, targetsChanges, undefined, notify), { wrap: false });
  };

  const isSelected = selectionRef.current?.guid === props.so.guid;
  const isActive = editDialogVisible || deleteDialogVisible || newKeyResultDialogVisible;

  const onItemClick = () => {
    setSelection({ ...selectionRef.current, kind: VistoKind.SO, guid: props.so.guid });
  }

  return (
    <StyledDraggable isActive={isActive} isSelected={isSelected}>
      <Stack horizontal grow verticalAlign='start' onClick={onItemClick}>
        <ExpandButton expandKey={props.so.guid} />
        <Stack grow>
          <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
            <RichTextTooltipHost tooltip={props.so.description} >
              <Link onClick={() => setEditDialogVisible(true)}>
                <Text variant='xLarge'>{props.so.name}</Text>
              </Link>
            </RichTextTooltipHost>
            <Stack horizontal>
              <IconButtonMore menuItems={menuItems} fontSize={FontSizes.large} />
              <IconButtonAttachments item={props.so} />
            </Stack>
          </Stack>
          <AssigneeBlock assignedTo={props.so.assignedTo} />
          {isExpanded && <div className='ql-editor' dangerouslySetInnerHTML={{ __html: props.so.description }} ></div>}
          {LicenseService.license?.okrEnabled && propertyBag?.showIndicators && <SidebarKeyResultActionList includeActions={false} plan={props.plan} item={props.so} />}
        </Stack>
        {editDialogVisible && <EditSoDialog onDismiss={() => setEditDialogVisible(false)} plan={props.plan} so={props.so} />}
        {deleteDialogVisible && <ConfirmDeleteDialog planItems={props.plan.items} items={[props.so, ...deps]} onDelete={deleteConfirmed} onDismiss={() => setDeleteSoDialogVisible(false)} />}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
        {historyDialogVisible && <HistoryDialog item={props.so} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
        {newKeyResultDialogVisible && <EditKeyResultDialog plan={props.plan} kr={newKeyResult} oldTargets={[]} newTargets={KeyResultService.makeDeafultResultTargets(props.plan, newKeyResult)} onDismiss={() => setNewKeyResultDialogVisible(false)} onCommit={onSaveKeyResult} isNew={true} />}
        {ShareLinkDialog}
      </Stack>
    </StyledDraggable>
  );
}
