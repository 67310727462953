import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { BasicDialog } from 'dialogs/common';
import { DefaultButton, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { EditKeyResultBlock } from './EditKeyResultBlock';
import { CommandsKeyResult, IKeyResultChanges } from 'services/CommandsKeyResult';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { EditKeyResultChart } from './EditKeyResultChart';
import { EditKeyResultDialog } from './EditKeyResultDialog';
import { ProgressBarBlock } from 'frames/TopFrame/sidebars/common/ProgressBarBlock';
import { PlanDataService } from 'services/PlanDataService';
import { ConfirmDiscardDialog } from './common/ConfirmDiscardDialog';
import { PendingChanges } from './common/PendingChanges';
import { ProgressCircle } from 'frames/TopFrame/sidebars/common/ProgressCircle';
import { useErrorInfo } from 'components';

export function EditKeyResultValuesDialog(props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('EditKeyResultValuesDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify, propertyBag } = React.useContext(AppContext);

  const [kr, setKr] = React.useState(props.kr);

  const { targets: oldTargets, actuals: oldActuals } = PlanKeyresultsService.getKeyResultValues(props.plan, kr.guid);

  const [newTargets, setNewTargets] = React.useState(oldTargets);

  const [newActuals, setNewActuals] = React.useState(oldActuals);

  const [isEditKeyResultDialogVisible, setIsEditKeyResultDialogVisible] = React.useState(false);

  const title = TextService.format(strings.EditKeyResultValuesDialog_Title, { itemName: kr.name });

  const onSaveKeyResult = async (changes: IKeyResultChanges) => {
    setKr(changes.newKr);
    setNewTargets(changes.newTargets);
  };

  const parentItem = PlanDataService.getItemByGuid(props.plan.items, kr.parentKrGuid ?? kr.soGuid);
  // const parentKeyResult = PlanDataService.getItemByGuid(props.plan.items, kr.krGuid);

  const targetsChanges = CommandsKeyResult.getKeyResultListChanges(oldTargets, newTargets);
  const actualsChanges = CommandsKeyResult.getKeyResultListChanges(oldActuals, newActuals);

  const onSaveChanges = async () => {
    const cmd = CommandsKeyResult.makeKeyResultUpdateCommand(props.kr, kr, targetsChanges, actualsChanges, notify);
    await dispatchCommand(cmd, { wrap: false });
  };

  const formatSubTitle = () => {
    const parentTitles = [
      // ...(parentKeyResult ? [TextService.formatTitle(parentKeyResult)] : []),
      ...(parentItem ? [TextService.formatTitle(parentItem, props.plan.items)] : []),
    ];
    const parentTitle = TextService.formatList(parentTitles);
    const description = TextService.getPlainText(kr.description);
    const result = TextService.format(strings.EditKeyResultValuesDialog_SubTitle, { description, parentTitle });
    return result;
  };

  const isRowBold = (item: VistoKeyResultValueItem) => {
    return actualsChanges.added.some(x => x.guid === item.guid)
      || actualsChanges.deleted.some(x => x.guid === item.guid)
      || actualsChanges.updated.some(x => x.item.guid === item.guid);
  };

  const pendingChanges = [
    ...actualsChanges.added.map(a => TextService.format(strings.PendingChange_Added, { title: TextService.formatTitle(a, props.plan.items) })),
    ...actualsChanges.deleted.map(a => TextService.format(strings.PendingChange_Deleted, { title: TextService.formatTitle(a, props.plan.items) })),
    ...actualsChanges.updated.map(a => TextService.format(strings.PendingChange_Updated, { title: TextService.formatTitle(a.item, props.plan.items) })),
    ...targetsChanges.added.map(a => TextService.format(strings.PendingChange_Added, { title: TextService.formatTitle(a, props.plan.items) })),
    ...targetsChanges.deleted.map(a => TextService.format(strings.PendingChange_Deleted, { title: TextService.formatTitle(a, props.plan.items) })),
    ...targetsChanges.updated.map(a => TextService.format(strings.PendingChange_Updated, { title: TextService.formatTitle(a.item, props.plan.items) })),
  ];

  const [isConfirmCloseDialogVisible, setIsConfirmCloseDialogVisible] = React.useState(false);

  const onDismiss = (save: boolean) => {
    if (!save && pendingChanges.length > 0) {
      setIsConfirmCloseDialogVisible(true);
    } else {
      props.onDismiss(false);
    }
  };

  const [errorInfo, setErrorInfo] = useErrorInfo();

  return <BasicDialog
    onDismiss={onDismiss}
    buttonOkAction={onSaveChanges}
    title={title}
    subText={formatSubTitle()}
    minWidth={650}
    buttonOkText={TextService.format(strings.ButtonOK)}
    buttonOkBusyText={TextService.format(strings.EnterKey_Validating)}
    buttonCancelText={TextService.format(strings.ButtonCancel)}
    statusBarItem={
      <Stack horizontal tokens={{ childrenGap: 'l1' }}>
        <DefaultButton
          disabled={!isPlanEditEnabled}
          text={TextService.format(strings.EditKeyResultValuesDialog_ConfigureButtonLabel)}
          onClick={() => setIsEditKeyResultDialogVisible(true)} />
        <PendingChanges items={pendingChanges} />
      </Stack>
    }
  >
    <Stack style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto', padding: 1 }} data-is-scrollable='true'>
      <Stack tokens={{ childrenGap: 's1' }}>

        <EditKeyResultChart plan={props.plan} kr={kr} targets={newTargets} actuals={newActuals} minHeight={150} />

        <EditKeyResultBlock
          plan={props.plan}
          kr={kr}
          setKr={setKr}
          valueKind={KeyResultValueKind.Actual}
          values={newActuals}
          onSetValues={setNewActuals}
          rightToolbar={
            <Stack horizontal verticalAlign='center' horizontalAlign='end' tokens={{ childrenGap: 's2' }}>
              <ProgressBarBlock plan={props.plan} targets={newTargets} actuals={newActuals} kr={kr} viewStartDate={propertyBag.viewStartDate} viewEndDate={propertyBag.viewEndDate} />
              <ProgressCircle size={20} plan={props.plan} targets={newTargets} actuals={newActuals} kr={kr} viewStartDate={propertyBag.viewStartDate} viewEndDate={propertyBag.viewEndDate} />
            </Stack>
          }
          isRowBold={isRowBold}
        />
      </Stack>
      {isEditKeyResultDialogVisible && <EditKeyResultDialog
        isNew={false}
        plan={props.plan}
        kr={kr}
        oldTargets={oldTargets}
        newTargets={newTargets}
        onDismiss={() => setIsEditKeyResultDialogVisible(false)}
        onCommit={onSaveKeyResult}
      />}
      {isConfirmCloseDialogVisible && <ConfirmDiscardDialog
        onDismiss={() => props.onDismiss(false)}
        onCommit={onSaveChanges}
        pendingChangesCount={pendingChanges.length}
      />}
    </Stack>
  </BasicDialog>;

}
