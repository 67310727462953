import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { PlanWizard } from 'wizard';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { Stack } from '@fluentui/react';
import { TokenKind } from 'shared/TokenKind';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { IEnvContext } from 'services/EnvContext';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { AuthService } from 'services/AuthService';
import { ApiService } from 'services/api/ApiService';
import { LicenseService } from 'services/LicenseService';
import { stringifyError } from 'shared/parse';

/**
 * Implementation of VistoTeams Tab configuration page
 */
export const VistoTeamsTabConfig = () => {

  const planId = React.useRef('');
  const planName = React.useRef(TextService.format(''));

  AuthService.resetAuth(TokenKind.sharepoint);

  const [isWizardOpen, setIsWizardOpen] = React.useState(false);
  const [siteUrl, setSiteUrl] = React.useState<string>();

  const onLoad = async (ctx: IEnvContext) => {

    const onSaveHandler = async (saveEvent: microsoftTeams.settings.SaveEvent) => {
      const host = 'https://' + window.location.host;
      const settings = TeamsAuthService.makeTabSettings(host, planId.current, ctx.siteUrl);
      settings.suggestedDisplayName = planName.current;
      try {
        await microsoftTeams.pages.config.setConfig(settings);
          saveEvent.notifySuccess();
      } catch (err) {
          saveEvent.notifyFailure(stringifyError(err));
      };
    };

    ApiService.configure(() => TeamsAuthService.getTeamsToken());
    const license = await ApiService.getLicense();
    LicenseService.setLicense(license);

    planName.current = TextService.format(strings.PlanConfiguration_NewPlanName);

    // existing plan
    if (ctx.entityId) {
      setSiteUrl(ctx.siteUrl);
    } else {
      microsoftTeams.pages.config.registerOnSaveHandler(onSaveHandler);
      setIsWizardOpen(true);
      setSiteUrl(ctx.siteUrl);
    }
  };

  const onWizardFinished = (changed: boolean, newPlanId?: string, newPlanName?: string) => {
    if (changed) {
      planId.current = newPlanId;
      planName.current = newPlanName;
    }
    setIsWizardOpen(false);
    microsoftTeams.pages.config.setValidityState(!!newPlanId);
  };

  return (
    <TeamsAuthScope name='Teams_Config' kind={TokenKind.sharepoint} onLoad={onLoad}>
      {siteUrl ? (
        isWizardOpen ?
          <PlanWizard
            isPopup={false}
            planId={planId.current}
            planName={planName.current}
            siteUrl={siteUrl}
            onDismiss={onWizardFinished}
            trigger='TeamsConfigTab'
          />
          :
          <Stack grow>
            {planId.current
              ? <Placeholder iconName='CheckMark' iconText={TextService.format(strings.TabConfig_ReadyIconText)} description={TextService.format(strings.TabConfig_Ready)} hideButton />
              : <Placeholder iconName='CheckMark' iconText={TextService.format(strings.TabConfig_ConfiguredIconText)} description={TextService.format(strings.TabConfig_Configured)} hideButton />
            }
          </Stack>
      ) : (
        <Placeholder iconName='Info'
          iconText={TextService.format(strings.PlanConfiguration_AddingMobileNotSupportedTItle)}
          description={TextService.format(strings.PlanConfiguration_AddingMobileNotSupportedDescription)}
          hideButton
        />
      )
      }
    </TeamsAuthScope>
  );
};
