import * as React from 'react';

import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { PlanDataService } from 'services/PlanDataService';
import { EditDpDialog, EditActionDialog, CopyProgressLinkDialog } from 'dialogs';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { ProgressBlock, SidebarItem, ISidebarItemProps, AttachDocument } from 'frames/TopFrame/sidebars/common';
import { VistoActionItem, VistoDpItem, VistoKind, IAttachment, VistoKeyResultItem, VistoKeyResultValueItem, KeyResultValueKind, KeyResultType, IVistoListItem } from 'sp';
import { DeleteSourceLinkDialog, EditSourceLinkDialog } from 'dialogs/common';

import { makeGuidString } from 'shared/guid';
import { AddProjectActionDialog } from 'integrations/project/components/AddProjectActionDialog';
import { HistoryBlock } from './common/HistoryBlock';
import { AddDevOpsActionDialog } from 'integrations/devops/components/AddDevOpsActionDialog';
import { HistoryDialog } from './common/HistoryDialog';
import { LicenseService } from 'services/LicenseService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { useSidebarShareDialog } from './SidebarShareHook';
import { Commands } from 'services/Commands';
import { SidebarActionList } from './SidebarActionList';
import { TextService } from 'services/TextService';

export function SidebarDp(props: ISidebarItemProps<VistoDpItem>) {

  const [editDpDialogVisible, setEditDpDialogVisible] = React.useState(false);
  const [newActionDialogVisible, setNewActionDialogVisible] = React.useState(false);
  const [addProjectActionDialogVisible, setAddProjectActionDialogVisible] = React.useState(false);
  const [addDevOpsActionDialogVisible, setAddDevOpsActionDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [editLinkDialogVisible, setEditLinkDialogVisible] = React.useState(false);
  const [copyLinkDialogVisible, setCopyLinkDialogVisible] = React.useState(false);
  const [deleteLinkDialogVisible, setDeleteLinkDialogVisible] = React.useState(false);
  
  const { dispatchCommand, isPopupOpen, isPlanEditEnabled, isPlanLive, isPlanLocal, setIsPopupOpen, notify, propertyBag, setPropertyBag, editorUiRef } = React.useContext(AppContext);

  React.useEffect(() => {
    setIsPopupOpen(editDpDialogVisible || newActionDialogVisible || addDevOpsActionDialogVisible || addProjectActionDialogVisible);
  }, [editDpDialogVisible, newActionDialogVisible, addDevOpsActionDialogVisible, addProjectActionDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);

  const menuItems = [
    MenuItems.getNewActionMenuItem(isEditDisabled, () => setNewActionDialogVisible(true), [
      ...(planSettings?.integrations?.project?.enabled && LicenseService.license?.projectEnabled ? [MenuItems.getAddProjectActionMenuItem(isEditDisabled, () => setAddProjectActionDialogVisible(true))] : []),
      ...(planSettings?.integrations?.devops?.enabled && LicenseService.license?.devopsEnabled ? [MenuItems.getAddDevOpsActionMenuItem(isEditDisabled, () => setAddDevOpsActionDialogVisible(true))] : []),
    ]),
  ];

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const overflowMenuItems = [
    MenuItems.getAttachmentsMenuItem(isEditDisabled, () => setIsAttachDocumentOpen(true)),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true)),
    MenuItems.getDividerMenuItem(1),
    MenuItems.getProgressLinkMenuItem([
      MenuItems.getOpenProgressLinkMenuItem(!props.item.sourceItemUrl, props.item),
      MenuItems.getEditProgressLinkMenuItem(isEditDisabled, () => setEditLinkDialogVisible(true)),
      MenuItems.getBreakProgressLinkMenuItem(isEditDisabled || !props.item.sourceItemUrl, () => setDeleteLinkDialogVisible(true)),
      MenuItems.getDividerMenuItem(2),
      MenuItems.getCopyToClipboardMenuItem(() => setCopyLinkDialogVisible(true)),
    ]),
  ];

  const farItems = [
    MenuItems.getGroupByMenu(propertyBag, setPropertyBag),
    MenuItems.getShowMenu([
      MenuItems.getDividerMenuItem(3),
      MenuItems.getShowValidationsMenu(propertyBag, setPropertyBag),
      MenuItems.getShowProgressMenu(propertyBag, setPropertyBag),
      MenuItems.getShowAssigneesMenu(propertyBag, setPropertyBag),
      MenuItems.getShowHistoryMenu(isHistoryDisabled, propertyBag, setPropertyBag),
      MenuItems.getDividerMenuItem(4),
      MenuItems.getShowAdvisoryMenu(propertyBag, setPropertyBag)
    ])
  ];

  const actions = PlanDataService.getDpActions(props.plan, props.item.guid);

  const activeFocus = PlanDataService.getActiveFocus(props.plan);

  const newAction: VistoActionItem = {
    kind: VistoKind.Action,
    guid: makeGuidString(),
    dpGuid: props.item.guid,
    lopGuid: props.item.lopGuid,
    focusGuid: activeFocus?.guid ?? null,
    useFocusDates: !!activeFocus,
    startDate: activeFocus?.startDate ?? null,
    endDate: activeFocus?.endDate ?? null,
    effectGuid: null,
    assignedTo: [],
    sortOrder: actions.reduce((r, a) => (a.sortOrder > r ? a.sortOrder : r), actions.length),
  };

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.item, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const newKeyResult: VistoKeyResultItem = {
    kind: VistoKind.KeyResult,
    parentKrGuid: null,
    guid: makeGuidString(),
    units: '%',
    keyResultType: KeyResultType.Numeric
  };

  const haveHistory = isPlanLive && propertyBag?.showHistory;

  const onSaveItem = async (oldAction: IVistoListItem, newAction: IVistoListItem) => {
    const cmd = Commands.makeSaveCommand(oldAction, newAction, notify);
    await dispatchCommand(cmd, { wrap: false });
  }

  return <SidebarItem
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    menuItems={menuItems}
    overflowMenuItems={overflowMenuItems}
    farItems={farItems}
    advisoryTopicKey='DP'
    plan={props.plan} item={props.item}
    content={
      <React.Fragment>
        {haveHistory && <HistoryBlock />}
        <ProgressBlock hideAssignedTo item={props.item} plan={props.plan} />
        <SidebarActionList plan={props.plan} dp={props.item} />
        {editDpDialogVisible && <EditDpDialog onDismiss={() => setEditDpDialogVisible(false)} plan={props.plan} dp={props.item} />}
        {newActionDialogVisible && <EditActionDialog onDismiss={() => setNewActionDialogVisible(false)} plan={props.plan} action={newAction} />}
        {addProjectActionDialogVisible && <AddProjectActionDialog onDismiss={() => setAddProjectActionDialogVisible(false)} plan={props.plan} dp={props.item} />}
        {addDevOpsActionDialogVisible && <AddDevOpsActionDialog onDismiss={() => setAddDevOpsActionDialogVisible(false)} plan={props.plan} dp={props.item} />}
        {editLinkDialogVisible && <EditSourceLinkDialog plan={props.plan} item={props.item} onDismiss={() => setEditLinkDialogVisible(false)} />}
        {deleteLinkDialogVisible && <DeleteSourceLinkDialog plan={props.plan} item={props.item} onDismiss={() => setDeleteLinkDialogVisible(false)} />}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
        {historyDialogVisible && <HistoryDialog item={props.item} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
        {copyLinkDialogVisible && <CopyProgressLinkDialog plan={props.plan} onDismiss={() => setCopyLinkDialogVisible(false)} item={props.item} />}
        {ShareLinkDialog}
      </React.Fragment>
    }
  />;
}
