import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { BasicDialog } from './BasicDialog';
import { Stack, Text } from '@fluentui/react';
import { trackClient } from 'shared/clientTelemetry';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { InfoBar, useErrorInfo } from 'components';

export function DeleteUrlDialog(props: {
  onCommit: () => Promise<any>;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('DeleteSourceLinkDialog'), []);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const onCommit = async () => {
    try {
      await props.onCommit();
      props.onDismiss();
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.DeleteUrlDialog_ErrorMessage), error });
    }
  };

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={onCommit}
      buttonOkText={TextService.format(strings.ButtonDelete)}
      buttonOkBusyText={TextService.format(strings.ButtonDeleting)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.DeleteUrlDialog_Title)}
      >
      <InfoBar {...errorInfo} />
      <Text>{TextService.format(strings.DeleteUrlDialog_Content)}</Text>
    </BasicDialog>
  );
}
