import { IVistoPlan, IVistoPlanStyles, PlanStyles } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { mergeDeep, parseJSON } from 'shared/parse';
import { IProgressData } from './IProgressData';
import { TextService } from './TextService';
import { PlanSettingsService } from './PlanSettingsService';

const defaultStylesJson = require('mxresources/styles/style.jsonc') as string;
const defaultStyles = parseJSON(defaultStylesJson);

export class PlanStylesService {

  public static getPlanStyles(planStyleJson: string): IVistoPlanStyles {
    try {
      return parseJSON(planStyleJson);
    } catch (err) {
      trackClient.error('Failed to load plan styles', err);
      return null;
    }
  }

  public static getDefaultStyleJson(): string {
    return defaultStylesJson;
  }

  public static processHighlightRules(plan: IVistoPlan, progress: IProgressData, action: (style: string) => void) {

    const planSettings = PlanSettingsService.getPlanSettings(plan);

    const progressThresholdRed = typeof (planSettings.progressThresholdRed) === 'number' ? planSettings.progressThresholdRed : -15
    const progressThresholdGreen = typeof (planSettings.progressThresholdGreen) === 'number' ? planSettings.progressThresholdGreen : -3;

    const percent = TextService.isValidNumber(progress.percentComplete) && TextService.isValidNumber(progress.plannedPercentComplete) 
      ? Math.round(progress.percentComplete - progress.plannedPercentComplete) 
      : NaN;
    
    const late = !!(
      (progress.endDate && plan.statusDate > progress.endDate) && 
      (typeof TextService.isValidNumber(progress.percentComplete) && progress.percentComplete < 99.99)
    );

    const done = TextService.isValidNumber(progress.percentComplete) && progress.percentComplete >= 99.99;

    if (percent < progressThresholdRed || late) {
      action('DP_RED');
    }
    if (progressThresholdRed <= percent && percent < progressThresholdGreen && !late) {
      action('DP_YELLOW');
    }
    if (progressThresholdGreen <= percent && !late || done) {
      action('DP_GREEN');
    }
  }

  public static getColor(plan: IVistoPlan, progress: IProgressData, attribute: string): string {
    const planStyles = defaultStyles.styles;
    let result = null;
    PlanStylesService.processHighlightRules(plan, progress, (styleName) => {
      const style = planStyles[styleName];
      if (style && style[attribute])
        result = style[attribute];
    });
    return result;
  }

  public static getMxStylesheet(planStyleJson: string): string {
    const planStyles = JSON.parse(JSON.stringify(defaultStyles.styles));
    const customStyles = PlanStylesService.getPlanStyles(planStyleJson);
    mergeDeep(planStyles, customStyles?.styles);

    const doc = document.implementation.createDocument(null, 'mxStylesheet', null);
    const nodeRoot = doc.createElement('mxStylesheet');
    for (const style in planStyles) {
      const nodeStyle = doc.createElement('add');
      nodeStyle.setAttribute('as', style);
      for (const attr in planStyles[style]) {
        const nodeAttr = doc.createElement('add');
        nodeAttr.setAttribute('as', attr);
        nodeAttr.setAttribute('value', planStyles[style][attr]);
        nodeStyle.appendChild(nodeAttr);
      }
      nodeRoot.appendChild(nodeStyle);
    }
    return nodeRoot.outerHTML;
  }
}
