import * as React from 'react';
import { mergeStyles, useTheme } from '@fluentui/react';
import { Draggable } from '@edorivai/react-smooth-dnd';
import './StyledDraggable.module.scss';

export const StyledDraggable = (props: {
  isActive: boolean;
  isSelected: boolean;
  children: React.ReactNode;
  dragDisabled?: boolean;
  dimmed?: boolean;
}) => {

  const theme = useTheme();

  const draggableStyle = mergeStyles({
    boxShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
    margin: '12px 0',
    padding: '8px 4px',
    borderRadius: '4px',
    backgroundColor: props.isActive ? theme.palette.themeLighter : null,
    borderColor: props.isSelected ? theme.palette.themePrimary : null,
    borderWidth: props.isSelected ? 1 : null,
    borderStyle: props.isSelected ? 'solid' : null,
    opacity: props.dimmed ? 0.7 : 1
  });
  
  
  return props.dragDisabled
    ? <div className={draggableStyle}>{props.children}</div>
    : <Draggable className={draggableStyle}>{props.children}</Draggable>
};
