import { TimelinePipe } from '@pnp/core';
import { InjectHeaders, Queryable } from '@pnp/queryable';

export function SpDefaultHeaders(): TimelinePipe<Queryable> {

  return (instance: Queryable) => {

      instance
          .using(InjectHeaders({
              'Accept': 'application/json;odata=nometadata',
              'Content-Type': 'application/json;charset=utf-8',
          }));

      return instance;
  };
}
