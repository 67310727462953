import * as React from 'react';

import { AppContext } from 'services/AppContext';
import { ICommand } from 'services/ICommand';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { EnvContext } from 'services/EnvContext';
import { notifyInfoBar, NotificationType } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { ProgressService } from 'services/ProgressService';
import { IProgressData } from 'services/IProgressData';
import { TextService } from 'services/TextService';
import { IVistoListItem, IVistoListItemWithAssignee, VistoActionItem, VistoFocusItem, VistoKind } from 'sp';
import { ProgressBlock } from 'frames/TopFrame/sidebars/common';

import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { Stack, Text, useTheme } from '@fluentui/react';
import { setIsLoading } from 'services/LoadingIndicator';
import strings from 'VistoWebPartStrings';
import { EditItemDialog } from 'components/EditItemDialog';
import { GanttService } from './GanttService';
import { ExportGanttToPdfDialog } from 'dialogs/export/ExportGanttToPdfDialog';
import { AppTheme } from 'components/AppTheme';
import { CommandsFocus } from 'services/CommandFocus';

enum GroupByOption {
  Capability = 0,
  Objective = 1,
  Focus = 2,
  Assignee = 3,
}

const GanttComponent = React.lazy(() => import(
  /* webpackChunkName: 'client-gantt' */
  './GanttComponent'));

export function GanttFrame(props: {
  readOnly: boolean;
}) {

  React.useEffect(() => trackClient.page('GanttFrame'), []);

  const { hostKind } = React.useContext(EnvContext);
  const { planRef, dispatchCommand, notify, propertyBag, setPropertyBag } = React.useContext(AppContext);

  const theme = useTheme();

  const groupByOptions = [
    {
      id: GroupByOption.Capability,
      name: TextService.format(strings.GanttFrame_GroupBy, { title: TextService.getVistoKindName(VistoKind.LOP) })
    },
    {
      id: GroupByOption.Objective,
      name: TextService.format(strings.GanttFrame_GroupBy, { title: TextService.getVistoKindName(VistoKind.SO) })
    },
    {
      id: GroupByOption.Focus,
      name: TextService.format(strings.GanttFrame_GroupBy, { title: TextService.getVistoKindName(VistoKind.Focus) })
    },
    {
      id: GroupByOption.Assignee,
      name: TextService.format(strings.GanttFrame_GroupByAssignee)
    },
  ];

  const columns = [
    {
      dataField: 'title',
      caption: TextService.format(strings.GanttName)
    }
  ];

  const getTasks = (g: GroupByOption, showCompleted: boolean) => {
    switch (g) {
      case GroupByOption.Capability:
        return GanttService.getTasksGrouppedByLopDp(planRef.current, theme.palette.themeSecondary, showCompleted);
      case GroupByOption.Objective:
        return GanttService.getTasksGrouppedByObjective(planRef.current, theme.palette.themeSecondary, showCompleted);
      case GroupByOption.Focus:
        return GanttService.getTasksGrouppedByFocus(planRef.current, theme.palette.themeSecondary, showCompleted);
      case GroupByOption.Assignee:
        return GanttService.getTasksGrouppedByAssignee(planRef.current, theme.palette.themeSecondary, showCompleted);
      default:
        return [];
    }
  };

  const defaultGroupBy = propertyBag?.gantt?.groupBy ?? GroupByOption.Capability;
  const [groupBy, _setGroupBy] = React.useState(defaultGroupBy);

  const defaultShowCompleted = propertyBag?.gantt?.showCompleted ?? false;
  const [showCompleted, _setShowCompleted] = React.useState(defaultShowCompleted);

  const [tasks, setTasks] = React.useState(getTasks(defaultGroupBy, defaultShowCompleted));
  
  const setShowCompleted = (val: boolean) => {
    setTasks(getTasks(groupBy, val));
    _setShowCompleted(val);
    setPropertyBag({ gantt: { ...propertyBag?.gantt, showCompleted: val } });
  }

  const setGroupBy = (val: GroupByOption) => {
    setTasks(getTasks(val, showCompleted));
    _setGroupBy(val);
    setPropertyBag({ gantt: { ...propertyBag?.gantt, groupBy: val } });
  };

  const [editItem, setEditItem] = React.useState<IVistoListItem>(null);

  const onEditProperties = (taskKey: string) => {
    const guid = GanttService.getTaskGuid(taskKey);
    const item = PlanDataService.getItemByGuid(planRef.current.items, guid);
    setEditItem(item);
  };

  const onDismissEditItem = (changed: boolean) => {
    setEditItem(null);
  };

  React.useEffect(() => {
    setTasks(getTasks(groupBy, showCompleted));
  }, [planRef.current]);

  const scheduledChanges = React.useRef({ timer: null, itemUpdates: {} });

  const onCancelTaskUpdating = (taskKey: string, values) => {
    const guid = GanttService.getTaskGuid(taskKey);

    const targetItem = PlanDataService.getItemByGuid<VistoActionItem>(planRef.current.items, guid);
    if (values.progress && targetItem && targetItem.kind === VistoKind.Action && targetItem.sourceItemUrl) {
      notifyInfoBar(notify, {
        type: NotificationType.warn,
        message: TextService.format(strings.GanttWarning_ProgressChangeIgnored),
        error: TextService.format(strings.GanttWarning_ProgressChangeIgnoredDetails, { title: TextService.formatTitle(targetItem, planRef.current.items) }),
      });
      return true;
    }
  };

  const onTaskUpdated = (taskKey: string, values) => {

    const guid = GanttService.getTaskGuid(taskKey);

    const targetItem = PlanDataService.getItemByGuid(planRef.current.items, guid);
    if (!targetItem || !(targetItem.kind === VistoKind.Action || targetItem.kind === VistoKind.Focus)) {
      return;
    }

    const itemUpdate: IProgressData = { ...scheduledChanges.current.itemUpdates[guid] };

    if (values.start) {
      itemUpdate.startDate = values.start;
      itemUpdate.useFocusDates = false;
    }
    if (values.end) {
      itemUpdate.endDate = values.end;
      itemUpdate.useFocusDates = false;
    }
    if (values.progress) {
      itemUpdate.percentComplete = values.progress;
    }

    itemUpdate.plannedPercentComplete = ProgressService.getPlannedPercentComplete(itemUpdate.startDate, itemUpdate.endDate, planRef.current.statusDate);

    scheduledChanges.current.itemUpdates[guid] = itemUpdate;

    if (scheduledChanges.current.timer) {
      clearTimeout(scheduledChanges.current.timer);
    }

    scheduledChanges.current.timer = setTimeout(() => {

      const changes = Object.keys(scheduledChanges.current.itemUpdates).flatMap(key => {
        const item = PlanDataService.getItemByGuid(planRef.current.items, key);
        const itemChanges = ChangesService.getChanges(item, scheduledChanges.current.itemUpdates[key]);
        if (itemChanges.detected) {
          if (item.kind === VistoKind.Focus) {
            return CommandsFocus.makeFocusUpdates(planRef.current, item as VistoFocusItem, itemChanges);
          } else {
            return [{ item, changes: itemChanges }];
          }
        } else {
          return [];
        }
      }).filter(v => !!v);

      scheduledChanges.current.itemUpdates = {};

      if (changes.length) {
        const command: ICommand = {
          prepare: async () => {
            return Commands.makeUpdateUndoUnit(changes, notify);
          },
          message: TextService.format(strings.GanttFrame_TaskUpdated, { count: changes.length }),
          name: CommandName.UpdateItemsFromGantt
        };

        dispatchCommand(command, { wrap: true });
      }
    }, 100);
  };

  const Spinner = () => {
    React.useEffect(() => {
      setIsLoading(TextService.format(strings.GanttFrame_Loading));
      return () => setIsLoading('');
    });
    return (<div />);
  };

  const onRenderTooltip = (task) => {
    const guid = GanttService.getTaskGuid(task.id);
    const targetItem = guid && PlanDataService.getItemByGuid<IVistoListItemWithAssignee>(planRef.current.items, guid);
    if (!targetItem) {
      return null;
    }
    return (
      <Stack tokens={{ padding: 'm', childrenGap: 'm' }} style={{
        backgroundColor: theme.palette.white,
        border: theme.palette.neutralDark,
        boxShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
        borderRadius: '2px',
      }}>
        <Text variant='mediumPlus'>{targetItem.name}</Text>
        <ProgressBlock plan={planRef.current} item={targetItem} />
      </Stack>
    );
  };

  const [exportInstance, setExportInstance] = React.useState(null);

  const taskListWidth = (hostKind !== 'WebMobile' && hostKind !== 'TeamsMobile') ? 300 : 100;

  return (
    <React.Suspense fallback={<Spinner />}>
      <GanttComponent
        today={planRef.current.statusDate}
        readOnly={props.readOnly}
        tasks={tasks}
        groupBy={groupBy}
        onGroupByChanged={setGroupBy}
        showCompleted={showCompleted}
        setShowCompleted={setShowCompleted}
        columns={columns}
        groupByOptions={groupByOptions}
        onEditProperties={onEditProperties}
        onExport={instance => setExportInstance(instance)}
        onTaskUpdated={onTaskUpdated}
        onCancelTaskUpdating={onCancelTaskUpdating}
        ganttStrings={{
          EditSelected: TextService.format(strings.GanttComponent_EditSelected),
          Export: TextService.format(strings.GanttComponent_Export),
          Today: TextService.format(strings.GanttComponent_Today),
          ShowCompleted: TextService.format(strings.MatrixFrame_ShowCompleted),
        }}
        locale={TextService.uiLanguage}
        taskListWidth={taskListWidth}
        onRenderTooltip={task => onRenderTooltip(task)}
        autoUpdateParentTasks={groupBy !== GroupByOption.Focus}
        shortUiLanguage={TextService.shortUiLanguage}
        themeName={AppTheme.currentThemeName}
      />
      {editItem && <EditItemDialog onDismiss={onDismissEditItem} plan={planRef.current} item={editItem} />}
      {exportInstance && <ExportGanttToPdfDialog plan={planRef.current} onDismiss={() => setExportInstance(null)} instance={exportInstance} />}
    </React.Suspense>
  );

}
