import React from 'react';
import { InfoBar, useErrorInfo } from 'components';
import { AuthService, IAuthScopeProps } from 'services/AuthService';
import { IEnvContext, EnvContext, EnvHostKind } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { setIsLoading } from 'services/LoadingIndicator';
import strings from 'VistoWebPartStrings';
import { isConsentError, stringifyError } from 'shared/parse';
import { Stack } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { trackClient } from 'shared/clientTelemetry';
import { ApiService } from 'services/api/ApiService';
import { WebAuthService } from 'teams/services/WebAuthService';

import { useIsAuthenticated } from '@azure/msal-react';
import { StorageService } from 'services/StorageService';
import { SharepointService } from 'services/SharepointService';
import { LocalStorageService } from 'services/LocalStorageService';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { UrlService } from 'shared/urlService';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { ProjectDataService } from 'integrations/project';
import { DevOpsDataService } from 'integrations/devops';
import { PlannerConfigurationService } from 'integrations/planner';

export function WebAuthScope(props: IAuthScopeProps) {

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [consentError, setConsentError] = useErrorInfo();
  const [authError, setAuthError] = useErrorInfo();

  const [envContext, setEnvContext] = React.useState<IEnvContext>(null);

  const authenticate = async () => {

    setIsLoading(TextService.format(strings.LoadingIndicator_Authenticating));
    setIsProcessing(true);

    trackClient.origin = document.location.origin;
    trackClient.initialize(props.name);

    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    
    ApiService.configure(() => WebAuthService.getMsalToken());
    StorageService.configure({
      sharepointService: new SharepointService(),
      indexeddbService: new LocalStorageService()
    });

    AuthService.TokenProvider = (kind, host) => AuthService.getServerSideToken(() => WebAuthService.getMsalToken(), kind, host);
    AuthService.AuthScope = WebAuthScope;
    AuthService.getConsent = WebAuthService.getConsent;

    const webPartContext = await SharepointService.makeWebPartContext(props.siteUrl, TextService.format(strings.Context_SharePointSite), 'en-us');

    const msalToken = await WebAuthService.getMsalToken();
    const payload = msalToken && jwtDecode<JwtPayload>(msalToken);

    const hostKind: EnvHostKind =
      (window.innerWidth < 450 || window.innerHeight < 450)
      ? 'WebMobile' : 'WebDesktop';

    const tid = payload?.['tid'];
    const userObjectId = payload?.['oid'];
    const userPrincipalName = payload ? payload['preferred_username'] : TextService.format(strings.Context_Local);
    const userDisplayName = payload ? payload['name'] : TextService.format(strings.Context_Local);

    const ctx: IEnvContext = {
      tid,
      userPrincipalName,
      userDisplayName,
      userObjectId,

      groupId: null,

      hostKind: hostKind,
      siteUrl: props.siteUrl,

      entityId: null,
      subEntityId: null,

      teamId: '',
      teamName: TextService.format(strings.WebAuthScope_DefaultTeam),
      channelId: '',
      channelName: TextService.format(strings.WebAuthScope_DefaultChannel),

      defaultFolderName: null,
      defaultFolderRelativeUrl: null,
      webPartContext,
    };

    AuthService.configure({
      tid,
      userObjectId,
      defaultBaseUrl: props.siteUrl
    });

    if (props.siteUrl && !UrlService.isLocalUrl(props.siteUrl)) {
      const webProperties = await SharepointService.getWebProperties(props.siteUrl);
      ctx.groupId = webProperties?.GroupId;
      ctx.defaultFolderName = webProperties?.GroupDocumentsUrl;
      const serverRelativeUrl = UrlService.getPathName(props.siteUrl);
      ctx.defaultFolderRelativeUrl = `${serverRelativeUrl}/${webProperties?.GroupDocumentsUrl}`;
      PlannerConfigurationService.configure(tid, webProperties.GroupId);
    }

    await props.onLoad(ctx);
    setIsLoading('');
    setIsProcessing(false);

    setEnvContext(ctx);
  };

  const consent = async () => {
    try {
      await AuthService.getConsent(props.kind, '', authenticate);
      setConsentError(null);
    } catch (error) {
        setIsLoading('');
        setIsProcessing(false);
        setConsentError({ type: NotificationType.warn, message: TextService.format(strings.AuthService_ErrorGetConsent, { reason: stringifyError(error) }) });
    }
  };

  const init = async () => {

    try {
      await authenticate();
    } catch (error) {
      if (isConsentError(error)) {
        AuthService.resetAuth(props.kind);
        setConsentError(error);
      } else {
        setAuthError({ type: NotificationType.error, message: TextService.format(strings.AuthError_Connect), error });
      }
      setIsLoading('');
      setIsProcessing(false);
    }
  };

  const refresh = () => {
    AuthService.resetAuth(props.kind);
    window.location.reload();
  };

  const authenticated = useIsAuthenticated();

  React.useEffect(() => {
    init();
  }, [authenticated]);

  return (authenticated
    ? consentError
      ? <Stack grow>
        <InfoBar {...consentError} />
        <Placeholder
          iconName='LaptopSecure'
          iconText={TextService.format(strings.AuthContext_ConsentRequired)}
          description={TextService.format(strings.AuthContext_ConsentRequiredExplanation)}
          buttonLabel={TextService.format(strings.AuthContext_ConsentRequiredAction)}
          disableButton={isProcessing}
          onConfigure={consent}
        />
      </Stack>
      : authError
        ? <Stack grow>
          <InfoBar {...authError} />
          <Placeholder
            iconName='LaptopSecure'
            iconText={TextService.format(strings.AuthContext_AuthError)}
            description={TextService.format(strings.AuthContext_AuthErrorExplanation)}
            buttonLabel={TextService.format(strings.AuthContext_AuthErrorAction)}
            disableButton={isProcessing}
            onConfigure={refresh}
          />
        </Stack>
        : envContext
          ? <EnvContext.Provider value={envContext}>{props.children}</EnvContext.Provider>
          : null
    : envContext
      ? <EnvContext.Provider value={envContext}>{props.children}</EnvContext.Provider>
      : null
  );
}
