import * as React from 'react';
import { MainFrame } from 'frames/MainFrame';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { useParams, useSearchParams } from 'react-router-dom';
import { IEnvContext } from 'services/EnvContext';
import { WebAuthService } from 'teams/services/WebAuthService';
import { PlannerConfigurationService } from 'integrations/planner/services';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { ApiService } from 'services/api/ApiService';
import { ProjectDataService } from 'integrations/project/services';
import { DevOpsDataService } from 'integrations/devops/services';

import { UrlService } from 'shared/urlService';
import { makeGuidString } from 'shared/guid';
import { WSContext } from 'services/WSContext';
import { env } from 'shared/clientEnv';

export const TeamsPlan = (props: {
}) => {

  const [context, setContext] = React.useState<IEnvContext>(null);

  const [searchParams] = useSearchParams();
  const siteUrl = searchParams.get('siteUrl');

  const params = useParams();
  const planId = params.planId;

  const onLoad = async (envContext: IEnvContext) => {

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => WebAuthService.getMsalToken());

    setContext(envContext);
  };

  const getRealtimeService = (userId, containerId, callback) => {
    return new WSContext(UrlService.makeInstanceId(siteUrl, planId), makeGuidString(), callback);
  }

  return (
    <WebAuthScope name='Web_TeamsPlan' kind={TokenKind.sharepoint} onLoad={onLoad} siteUrl={siteUrl}>
      {context && <MainFrame source={{ planId, siteUrl}} getRealtimeService={getRealtimeService} />}
    </WebAuthScope>
  );
};
