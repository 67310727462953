import { IItemPosition, IVistoPlan, IVistoPlanSettings, VistoDpItem, VistoKeyResultItem, VistoKind, VistoLopItem } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { mx, mxglobals } from './common';
import { IntegrationService } from 'services/IntegrationService';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IWSPlanStatus } from 'shared/ws';
import { PlanSettingsService } from 'services/PlanSettingsService';
import strings from 'VistoWebPartStrings';
import { CellKind } from 'shared/CellKind';
import { trackClient } from 'shared/clientTelemetry';
import { ColorService } from 'services/colorService';
import { SvgIndicators } from 'graphics/SvgIndicators';
import { LicenseService } from 'services/LicenseService';
import { IPropertyBag } from 'services/IPropertyBag';
import { getObjectValues, parseJSON } from 'shared/parse';
import { IComment } from '../sidebars/comment/IComment';
import { UrlService } from 'shared/urlService';
import { OverlayService } from './common/OverlayService';

const updateLabel = (model: mxgraph.mxGraphModel, cell: mxgraph.mxCell, newLabel: string) => {
  const oldLabel = model.getValue(cell);
  if (newLabel != oldLabel) {
    model.setValue(cell, newLabel);
  }
};

const defaultSize = { w: 20, h: 20 };

const addPlanIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void,
  onComment?: (param: string) => void
) => {
  const planSettings = PlanSettingsService.getPlanSettings(plan);

  const offset = { x: 10, y: -10 };
  if (planSettings?.showProgressIcons || typeof (planSettings?.showProgressIcons) === 'undefined') {

    const comments = parseJSON(plan.commentsJson, []) as IComment[];
    const planComments = comments.filter(c => !c.subEntityId);
    if (planComments.length > 0) {
      const nav = SvgIndicators.buildCommentShape(strings.Drawing_CommentOverlayLabel);
      const subEntityId = planComments[0].subEntityId;
      const overlay = OverlayService.makeCellOverlay({ url: nav.iconUrl, tooltip: nav.tooltipText }, OverlayService.alignNavigation, offset, { w: 20, h: 20 }, () => onComment(subEntityId));
      graph.addCellOverlay(cell, overlay);
      offset.x += 25;
    }

    const link = plan.navigationLink && IntegrationService.getBrowserLink(plan.navigationLink.url);
    if (link) {
      SvgIndicators.buildSvgNavigation(plan.navigationLink.url).then(nav => {
        if (nav) {
          const overlay = OverlayService.makeCellOverlay({ url: nav.iconUrl, tooltip: plan.navigationLink.description }, OverlayService.alignNavigation, offset, { w: 10, h: 10 }, () => onClick(link));
          graph.addCellOverlay(cell, overlay);
        }
      }, err => {
        trackClient.error(`build navigation failed for ${plan.navigationLink.url}`, err);
      });
    }
  }
};

const showKpiIndicators = (planSettings: IVistoPlanSettings) => LicenseService.license?.okrEnabled &&
  (planSettings?.showKpiIndicators || typeof (planSettings?.showKpiIndicators) === 'undefined');

const addDpIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  item: VistoDpItem,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void,
  onComment: (param: string) => void
) => {

  const planSettings = PlanSettingsService.getPlanSettings(plan);

  if (planSettings?.showProgressIcons || typeof (planSettings?.showProgressIcons) === 'undefined') {

    const offset = { x: 10, y: -10 };

    const comments = parseJSON(plan.commentsJson, []) as IComment[];
    const itemComments = comments.filter(c => c.subEntityId && UrlService.parseSubEntityId(c.subEntityId)?.cellId === cell.id);
    if (itemComments.length > 0) {
      const nav = SvgIndicators.buildCommentShape(strings.Drawing_CommentOverlayLabel);
      const subEntityId = itemComments[0].subEntityId;
      const overlay = OverlayService.makeCellOverlay({ url: nav.iconUrl, tooltip: nav.tooltipText }, OverlayService.alignNavigation, offset, { w: 15, h: 15 }, () => onComment(subEntityId));
      graph.addCellOverlay(cell, overlay);
      offset.x += 25;
    }

    const link = item.sourceItemUrl && IntegrationService.getBrowserLink(item.sourceItemUrl);
    if (link) {
      SvgIndicators.buildSvgNavigation(item.sourceItemUrl).then(nav => {
        if (nav) {
          const overlay = OverlayService.makeCellOverlay({ url: nav.iconUrl, tooltip: nav.tooltipText }, OverlayService.alignNavigation, offset, { w: 15, h: 15 }, () => onClick(link));
          graph.addCellOverlay(cell, overlay);
        }
      }, err => {
        trackClient.error(`build navigation failed for ${item.sourceItemUrl}`, err);
      });
    }
  }

  const offset = { x: -20, y: 0 };
  if (planSettings?.showIndicators || typeof (planSettings?.showIndicators) === 'undefined') {
    const svg = SvgIndicators.buildSvgProgress(10, 2, plan, item);
    if (svg) {
      const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
      const tooltip = TextService.format(strings.DiagramProgressTooltip, {
        percentComplete: TextService.formatPercents(item.percentComplete, false, true)
      });
      const overlay = OverlayService.makeCellOverlay({ url, tooltip }, OverlayService.alignTopRight, offset, defaultSize);
      graph.addCellOverlay(cell, overlay);
      offset.x -= 35;
    }
  }

  if (showKpiIndicators(planSettings)) {

    const krInfoSet = PlanDataService.getDpKeyResultSet(plan, item.guid);
    const krInfos = getObjectValues(krInfoSet)
      .filter(x => x.showOnDiagram)
      .sort((a, b) => TextService.compareNames(a.kr.name, b.kr.name));

    for (const krInfo of krInfos) {
      const kr = krInfo.kr;
      const svg = SvgIndicators.buildSvgIndicator(plan, kr, 10, viewStartDate, viewEndDate);
      if (svg) {
        const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
        const tooltip = TextService.format(strings.Drawing_KeyResultTooltip, { krName: kr.name });
        const overlay = OverlayService.makeCellOverlay({ url, tooltip }, OverlayService.alignTopRight, offset, defaultSize, () => onClick(kr.guid));
        graph.addCellOverlay(cell, overlay);
        offset.x -= 25;
      }
    }
  }


  if (planSettings?.showOwnerIndicators || typeof (planSettings?.showOwnerIndicators) === 'undefined') {
    const assignees = item.assignedTo ?? [];
    const assigneeOffset = { x: -20, y: 0 };
    for (const assignee of assignees) {
      SvgIndicators.buildSvgCircleImageUrl(assignee, 10).then(url => {
        const tooltip = TextService.format(assignee.title);
        const overlay = OverlayService.makeCellOverlay({ url, tooltip }, OverlayService.alignBottomRight, assigneeOffset, defaultSize, () => { });
        graph.addCellOverlay(cell, overlay);
        assigneeOffset.x -= 25;
      });
    }
  }
};

const addSoIndicatorOverlays = (
  graph: mxgraph.mxGraph,
  cell: mxgraph.mxCell,
  plan: IVistoPlan,
  viewStartDate: Date,
  viewEndDate: Date,
  onClick: (param: string) => void
) => {

  const planSettings = PlanSettingsService.getPlanSettings(plan);

  const offset = { x: -20, y: 0 };

  if (showKpiIndicators(planSettings)) {

    const krs = PlanDataService.getItemsHaving<VistoKeyResultItem>(plan.items, x => x.kind === VistoKind.KeyResult && !!x.soGuid && x.showOnDiagram);
    for (const kr of krs.reverse()) {
      const svg = SvgIndicators.buildSvgIndicator(plan, kr, 10, viewStartDate, viewEndDate);
      if (svg) {
        const url = 'data:image/svg+xml;base64,' + window.btoa(svg);
        const tooltip = TextService.format(strings.Drawing_KeyResultTooltip, { krName: kr.name });
        const overlay = OverlayService.makeCellOverlay({ url, tooltip }, OverlayService.alignTopRight, offset, defaultSize, () => onClick(kr.guid));
        graph.addCellOverlay(cell, overlay);
        offset.x -= 25;
      }
    }
  }
};

const highlight = {
  oldHighlightState: '{}',
  oldHighlights: []
};

export const clearHighlights = () => {
  for (const h of highlight.oldHighlights) {
    h.destroy();
  }
  highlight.oldHighlightState = '{}';
  highlight.oldHighlights = [];
};

export const updateHighlights = (graph: mxgraph.mxGraph, status: IWSPlanStatus) => {

  if (!status?.selection) {
    return;
  }

  const newHighlightState = JSON.stringify(status.selection);

  if (newHighlightState === highlight.oldHighlightState)
    return;

  // first, remove all highlights
  for (const h of highlight.oldHighlights) {
    h.destroy();
  }

  const newHighlights = [];

  graph.eventsEnabled = false;

  const allCells = mx.getAllCells(graph);
  for (let cell of allCells) {
    for (const userId in status.selection) {

      const shapeId = status.selection[userId];
      if (shapeId === mx.getCellGuid(cell)) {
        const user = status.users.find(u => u.oid == userId);
        const color = ColorService.getUserColor(user?.preferred_username || userId);
        const cellHighlight = new mxglobals.mxCellHighlight(graph, color, 5);
        cellHighlight.highlight(graph.view.getState(cell));
        newHighlights.push(cellHighlight);
      }
    }
  }

  graph.eventsEnabled = true;

  highlight.oldHighlights = newHighlights;
  highlight.oldHighlightState = newHighlightState;
};

export function getPosition(cell: mxgraph.mxCell): IItemPosition {
  if (cell.edge) {
    return {
      x: (cell.geometry.sourcePoint.x + cell.geometry.targetPoint.x) / 2,
      y: (cell.geometry.sourcePoint.y + cell.geometry.targetPoint.y) / 2,
      width: cell.geometry.width,
      height: cell.geometry.height
    };
  } else {
    return {
      x: cell.geometry.x,
      y: cell.geometry.y,
      width: cell.geometry.width,
      height: cell.geometry.height
    };
  }
}

export function updateDiagramShapeFromPlanWithDates(
  graph: mxgraph.mxGraph, 
  cell: mxgraph.mxCell, 
  plan: IVistoPlan,
  viewStartDate: Date, viewEndDate: Date, 
  onClick: (param: string) => void,
  onComment: (param: string) => void
) {

  const kind = mx.getCellKind(cell);
  switch (kind) {
    case CellKind.DP:
      const dp = PlanDataService.getItemByGuid<VistoDpItem>(plan.items, mx.getCellGuid(cell));
      if (dp) {
        dp.position = getPosition(cell);
        updateLabel(graph.model, cell, dp.name);
        graph.removeCellOverlays(cell);
        addDpIndicatorOverlays(graph, cell, plan, dp, viewStartDate, viewEndDate, onClick, onComment);
      }
      break;
    case CellKind.LOP:
      const lop = PlanDataService.getItemByGuid<VistoLopItem>(plan.items, mx.getCellGuid(cell));
      if (lop) {
        lop.position = getPosition(cell);
        updateLabel(graph.model, cell, lop.name);
      }
      break;
    case CellKind.TITLE:
      graph.removeCellOverlays(cell);
      addPlanIndicatorOverlays(graph, cell, plan, viewStartDate, viewEndDate, onClick, onComment);
      updateLabel(graph.model, cell, plan.name);
      break;
    case CellKind.FOCUS:
      const activeFocus = PlanDataService.getActiveFocus(plan);
      const focusName = activeFocus?.name;
      updateLabel(graph.model, cell, focusName);
      break;
    case CellKind.POSITION:
      updateLabel(graph.model, cell, plan.esName);
      graph.removeCellOverlays(cell);
      addSoIndicatorOverlays(graph, cell, plan, viewStartDate, viewEndDate, onClick);
      break;
  }

}

export function updateDiagramFromPlanWithDates(
  graph: mxgraph.mxGraph,
  plan: IVistoPlan,
  propertyBag: IPropertyBag,
  repaintCellIds: string[],
  onClick: (param: string) => void,
  onComment: (param: string) => void,
) {

  graph.eventsEnabled = false;

  const allCells = mx.getAllCells(graph);
  for (let cell of allCells) {

    if (repaintCellIds?.length && repaintCellIds.indexOf(mx.getCellGuid(cell)) < 0) {
      continue;
    }

    updateDiagramShapeFromPlanWithDates(graph, cell, plan, propertyBag?.viewStartDate, propertyBag?.viewEndDate, onClick, onComment);
  }

  graph.eventsEnabled = true;
}
