import { PlanDataService } from 'services/PlanDataService';
import { IItemChanges } from 'services/Interfaces';
import { IExecutableAction } from 'services/IExecutableAction';
import { StorageService } from 'services/StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { clearInfoBar, INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { TextService } from 'services/TextService';
import { AuthService } from 'services/AuthService';
import { IProgressData } from 'services/IProgressData';
import { ChangesService } from 'services/ChangesService';
import { TokenKind } from 'shared/TokenKind';
import { Operation } from 'shared/Operation';
import { IVistoPlan, VistoKind, VistoLopItem, VistoDpItem, IVistoListItemWithProgress, VistoFocusItem, VistoActionItem, IVistoListItem, IFieldValueUser, IVistoPlanSettings } from 'sp';

import { PlannerService } from './PlannerService';
import { PlannerIntegrationLevel, IPlannerIntegrationSettings } from './PlannerIntegrationLevel';
import { PlannerLinkService } from './PlannerLinkService';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { PlannerBucket, PlannerPlan, PlannerTask, PlannerTaskDetails } from '@microsoft/microsoft-graph-types';
import { makeGuidString } from 'shared/guid';
import { PlannerNotifications } from './PlannerNotifications';
import { PlannerDataService } from './PlannerDataService';
import { trackClient } from 'shared/clientTelemetry';
import { getErrorMessage, getObjectValues, isConsentError } from 'shared/parse';
import strings from 'VistoWebPartStrings';
import { LicenseService } from 'services/LicenseService';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { IUndoUnit } from 'services/IUndoUnit';

export interface IPlannerOperationOptions {
  integrationLevel?: PlannerIntegrationLevel;
  enableLabelSync?: boolean;
  selectedKeys?: string[];
}

interface IPlannerLinkedItems {
  tasks: IVistoListItemWithProgress[];
  buckets: IVistoListItemWithProgress[];
  plans: IVistoListItemWithProgress[];
}

const makeCategoryId = (i: number) => `category${i + 1}`;

const makePlannerAssignments = (val: IFieldValueUser[]) => {
  if (!val) {
    return null;
  }

  const converted = {};
  for (const u of val) {
    converted[u.guid] = {
      '@odata.type': '#microsoft.graph.plannerAssignment',
      'orderHint': ' !'
    };
  }
  return converted;
};

const clearPlannerAssignments = (assignments: any) => {
  if (!assignments) {
    return null;
  }
  const converted = {};
  for (const guid in assignments) {
    converted[guid] = {
      '@odata.type': '#microsoft.graph.plannerAssignment',
      'orderHint': ' !'
    };
  }
  return converted;
};

export interface IPlannerChangesResult {
  updated: IItemChanges<IVistoListItemWithProgress>[];
  created: IVistoListItemWithProgress[];
  deleted: IVistoListItemWithProgress[];
  actions: IExecutableAction[];
}

const defaultSpUpdateOptions: IOperationOptions = {
  excludeExternals: true,
  notificationGroup: 'Planner_Sync'
};

export class PlannerConfigurationService {

  public static tid: string; // tenant id
  public static groupId: string; // office 365 group id

  private static cachedPlan: IVistoPlan;
  private static cachedFocuses: VistoFocusItem[] = [];
  static getFocuses = (p: IVistoPlan) => {
    if (p != this.cachedPlan) {
      this.cachedPlan = p;
      this.cachedFocuses = PlanDataService.getItems<VistoFocusItem>(p.items, VistoKind.Focus).sort((a, b) => TextService.compareStrings(a.guid, b.guid));
    }
    return this.cachedFocuses;
  };

  static resetCache() {
    this.cachedPlan = null;
    this.cachedFocuses = [];
  }
  
  static normalizeTaskAppliedCategories = (taskAppliedCategories: any) => {
    const appliedCategories: any = {};
    for (let i = 0; i < 24; i++) {
      appliedCategories[makeCategoryId(i)] = taskAppliedCategories?.[makeCategoryId(i)] ?? false;
    }
    return appliedCategories;
  }

  static getAppliedCategories = (p: IVistoPlan, focusGuid: string) => {
    const focuses = this.getFocuses(p);
    const appliedCategories: any = {};
    for (let i = 0; i < focuses.length && i < 24; i++) {
      appliedCategories[makeCategoryId(i)] = focuses[i].guid === focusGuid;
    }
    if (focuses.length < 24) {
      appliedCategories[makeCategoryId(focuses.length)] = false;
    }
    return appliedCategories;
  };
  
  public static async synchronizeStructureWithPlanner(p: IVistoPlan, items: IVistoListItem[], notify: INotify, operation: Operation, options: IPlannerOperationOptions) {

    const oldSettings = PlanSettingsService.getPlanSettings(p);

    clearInfoBar(notify, 'Planner_DateConflict');
    clearInfoBar(notify, 'Planner_Sync');

    const callback = async () => {

      const result = await PlannerConfigurationService.getPlannerChanges(p, items, notify, operation, options);

      for (const action of result.actions) {
        try {
          p = await action.execute(p);
          notifyInfoBar(notify, {
            type: NotificationType.success,
            group: 'Planner_Sync',
            message: action.title
          });
        } catch (error) {
          const errorTitle = TextService.format(strings.PlannerError_ConfigurationActionError, { title: action.title });
          trackClient.error(errorTitle);
          getErrorMessage(error).then(message => {
            notifyInfoBar(notify, {
              type: NotificationType.error,
              group: 'Planner_Sync',
              message: errorTitle,
              error: message
            });
          });
        }
      }

      if (result.updated.length) {
        const actions = result.updated.map(x => x.item).filter(x => x.kind === VistoKind.Action);
        await PlannerDataService.resolveUsers(p, actions, notify);
        p = await StorageService.get(p.siteUrl).updateItems(p, result.updated, notify, defaultSpUpdateOptions);
      }

      if (result.created.length) {
        const actions = result.created.filter(x => x.kind === VistoKind.Action);
        await PlannerDataService.resolveUsers(p, actions, notify);
        p = await StorageService.get(p.siteUrl).createItems(p, result.created, notify, defaultSpUpdateOptions);
      }

      if (result.deleted.length) {
        const actions = result.deleted.filter(x => x.kind === VistoKind.Action);
        await PlannerDataService.resolveUsers(p, actions, notify);
        p = await StorageService.get(p.siteUrl).deleteItems(p, result.deleted, notify, defaultSpUpdateOptions);
      }

      this.validateAmbitionParents(p, notify);
    };

    try {

      await callback();

      const newSettings = { ...oldSettings, integrations: { ...oldSettings?.integrations, planner: { ...oldSettings?.integrations?.planner, syncDate: new Date() } } };
      p = PlanSettingsService.setPlanSettings(p, newSettings);

    } catch (error) {

      if (isConsentError(error)) {
        AuthService.resetAuth(TokenKind.planner);
        PlannerNotifications.makeConsentNotification(callback, notify);
      } else {
        const errorTitle = TextService.format(strings.PlannerError_UpdatingData);
        trackClient.error(errorTitle);
        getErrorMessage(error).then(message => {
          notifyInfoBar(notify, {
            type: NotificationType.error,
            group: 'Planner_Sync',
            message: errorTitle,
            error: message
          });
        });
      }
    }

    return p;
  }

  public static getPlannerLinkedItems(items: IVistoListItemWithProgress[]): IPlannerLinkedItems {

    const result = {
      tasks: items.filter(x => PlannerLinkService.isPlannerTaskLink(x.sourceItemUrl)),
      buckets: items.filter(x => PlannerLinkService.isPlannerBucketLink(x.sourceItemUrl)),
      plans: items.filter(x => PlannerLinkService.isPlannerPlanLink(x.sourceItemUrl))
    };

    return (result.tasks.length || result.buckets.length || result.plans.length) ? result : undefined;
  }

  public static getRemovalList(data: IPlannerLinkedItems): string {
    const formatList = (list: any[], type: string) => list?.length ? [`${list.length} ${type}`] : [];

    const listItems = [
      ...formatList(data?.plans, TextService.format(strings.PlannerMessage_CounterPlans)),
      ...formatList(data?.buckets, TextService.format(strings.PlannerMessage_CounterBuckets)),
      ...formatList(data?.tasks, TextService.format(strings.PlannerMessage_CounterTasks))
    ];

    return TextService.formatList(listItems);
  }

  public static getRemovalWarning(data: IPlannerLinkedItems): string {

    const removalList = this.getRemovalList(data);
    return removalList && TextService.format(strings.PlannerMessage_DeleteCheckbox, { removalList });
  }

  public static async deletePlannerData(data: IPlannerLinkedItems) {

    const planIds = data.plans.map(x => PlannerLinkService.parsePlannerPlanLink(x.sourceItemUrl).planId);

    const markedTasks = new Set<string>();
    const markedBuckets = new Set<string>();

    for (const planId of planIds) {
      try {
        const tasks = await PlannerService.getTasks(planId);
        for (const task of tasks) {
          markedTasks.add(task.id);
        }
      } catch (err) {
        trackClient.warn(`Unable to get plan tasks for plan ${planId}`, err);
      }
      try {
        const buckets = await PlannerService.getBuckets(planId);
        for (const bucket of buckets) {
          markedBuckets.add(bucket.id);
        }
      }
      catch (err) {
        trackClient.warn(`Unable to get plan buckets for plan ${planId}`, err);
      }
    }

    const taskIds = data.tasks.map(x => PlannerLinkService.parsePlannerTaskLink(x.sourceItemUrl).taskId).filter(x => !markedTasks.has(x));
    const bucketIds = data.buckets.map(x => PlannerLinkService.parsePlannerBucketLink(x.sourceItemUrl).bucketId).filter(x => !markedBuckets.has(x));

    if (data) {
      if (taskIds.length) {
        await PlannerService.deleteTasks(taskIds);
      }
      if (bucketIds.length) {
        await PlannerService.deleteBuckets(bucketIds);
      }
      if (planIds.length) {
        await PlannerService.deletePlans(planIds);
      }
    }
  }

  public static configure(tid: string, groupId: string) {
    PlannerConfigurationService.tid = tid;
    PlannerConfigurationService.groupId = groupId;

    IntegrationService.hooks['planner'] = {

      removalWarning: items => this.getRemovalWarning(this.getPlannerLinkedItems(items)),
      removalAction: items => this.deletePlannerData(this.getPlannerLinkedItems(items)),

      isRecognizedLink: url => {
        return PlannerLinkService.isPlannerLink(url);
      },
      getLinkName: async (url) => {
        const taskLink = PlannerLinkService.parsePlannerTaskLink(url);
        if (taskLink) {
          const task = await PlannerService.getTask(taskLink.taskId);
          return TextService.format(strings.PlannerMessage_LinkToTask, { title: task.title });
        }
        const bucketLink = PlannerLinkService.parsePlannerBucketLink(url);
        if (bucketLink) {
          const bucket = await PlannerService.getBucket(bucketLink.bucketId);
          return TextService.format(strings.PlannerMessage_LinkToBucket, { title: bucket.name });
        }
        const planLink = PlannerLinkService.parsePlannerPlanLink(url);
        if (planLink) {
          const plan = await PlannerService.getPlan(planLink.planId);
          return TextService.format(strings.PlannerMessage_LinkToPlan, { title: plan.title });
        }
      },
      allowRecalculation: (name: keyof IProgressData) => {
        return true;
      },
      allowEdit: (name: keyof IProgressData) => {
        return name !== 'percentComplete' && name !== 'plannedPercentComplete';
      },
      getBrowserLink: (url) => {
        return url;
      },

      getSyncDate: (settings: IVistoPlanSettings): Date => {
        return settings?.integrations?.planner?.syncDate;
      },

      getIconInfo: async (url: string): Promise<IIconInfo> => {
        return {
          iconUrl: require('static/assets/links/planner.svg'),
          tooltipText: TextService.format(strings.LinkIconTitle_Planner)
        };
      },

      getDashbordRef: async (url: string) => {
        const parsed = PlannerLinkService.parsePlannerPlanLink(url) || PlannerLinkService.parsePlannerBucketLink(url);
        return parsed && `planner:${PlannerConfigurationService.groupId}##${parsed.planId}`;
      },

      getCheckList: async (url: string, plan: IVistoPlan) => {
        const taskLink = PlannerLinkService.parsePlannerTaskLink(url);
        if (taskLink.taskId) {
          const details = await PlannerService.getTaskDetails(taskLink.taskId);
          const detailsList = details?.checklist ? Object.keys(details.checklist).map(id => {
            const item = details.checklist[id];
            const title = item.title;
            const checked = item.isChecked;
            const orderHint = item.orderHint;
            return { id, title, checked, url, orderHint };
          }) : [];
          const sortedList = detailsList.sort((a, b) => TextService.compareStrings(a.orderHint, b.orderHint));
          return sortedList;
        }
      },

      synchronize: async (p: IVistoPlan, items: IVistoListItem[], notify: INotify, operation: Operation, options: IOperationOptions): Promise<IVistoPlan> => {

        const oldSettings = PlanSettingsService.getPlanSettings(p);
        const plannerSettings = oldSettings?.integrations?.planner;
        if (!plannerSettings?.level || !LicenseService.license?.plannerEnabled) {
          return p;
        }

        this.resetCache();

        if (this.getPlannerLinkedItems([...getObjectValues(p.items), ...items])) {

          if (options?.enableSimpleUpdate) {
            switch (operation) {
              case Operation.create:
                p = await this.createSinglePlannerItem(p, items[0], plannerSettings, notify);
                break;
              default:
                p = await this.updateSinglePlannerItem(p, items[0], plannerSettings, notify);
                break;
            }
          } else {
            p = await this.synchronizeStructureWithPlanner(p, items, notify, operation, options as IPlannerOperationOptions ?? {});
          }
        }

        this.resetCache();
        return p;
      }
    };

  }

  private static validateAmbitionParents(p: IVistoPlan, notify: INotify) {
    clearInfoBar(notify, 'Planner_validateAmbitionParents');
    const dps = PlanDataService.getItems<VistoDpItem>(p.items, VistoKind.DP);
    for (const dp of dps) {
      const lop = PlanDataService.getItemByGuid<VistoLopItem>(p.items, dp.lopGuid);
      if (PlannerLinkService.parsePlannerBucketLink(dp.sourceItemUrl) && lop && !lop.sourceItemUrl) {
        PlannerNotifications.makeInvalidAmbitionNotification(p, dp, lop, notify, 'Planner_validateAmbitionParents');
      }
    }
  }

  private static async updateFromTask (
    result: IPlannerChangesResult,
    vistoPlan: IVistoPlan,
    action: VistoActionItem, 
    dp: VistoDpItem,
    task: PlannerTask,
    actionUpdate: IVistoListItemWithProgress,
    notify: INotify
  ) {

    const plannerSettings = PlanSettingsService.getPlanSettings(vistoPlan)?.integrations?.planner;
    if (plannerSettings?.enableLabelSync) {

      const oldAppliedCategories = this.normalizeTaskAppliedCategories(task.appliedCategories);
      const newAppliedCategories = this.getAppliedCategories(vistoPlan, action.focusGuid);
      const labelChanges = ChangesService.getChanges<PlannerTask>(oldAppliedCategories, newAppliedCategories);
        if (labelChanges.detected) {
        result.actions.push({
          title: TextService.format(strings.PlannerMessage_UpdateLabels, { title: actionUpdate.name }),
          execute: async (p) => {
            await PlannerService.updateTask(task.id, { appliedCategories: labelChanges.newValues });
            return p;
          }
        });
      }
    }

    let changes = ChangesService.getChanges(action, {
      ...actionUpdate,
      kind: action.kind,
      dpGuid: dp ? dp.guid : null,
      lopGuid: dp ? dp.lopGuid : null,
    }, undefined, { roundDates: true });

    if (changes.detected) {

      // if we detect conflicting changes
      if (action.useFocusDates) {
        if (changes.properties.indexOf('startDate') >= 0 || changes.properties.indexOf('endDate') >= 0) {

          const dateChanges = ChangesService.getChanges(changes.oldValues, { 
            startDate: changes.newValues.startDate, 
            endDate: changes.newValues.endDate,
            useFocusDates: false
          }, ['startDate', 'endDate', 'useFocusDates']);

          changes = ChangesService.getChanges(changes.oldValues, changes.newValues, changes.properties.filter(p => p !== 'startDate' && p !== 'endDate') as any);

          PlannerNotifications.makeDateConflictNotification(vistoPlan, action, dateChanges, notify);
        }
      }

      if (changes.detected) {
        result.updated.push({ item: action, changes });
      }
    }
  }
  
  private static async updateDisconnectedTasks(p: IVistoPlan, result: IPlannerChangesResult, notify: INotify, processedItems: Set<string>) {
    const actions = PlanDataService.getItems<VistoActionItem>(p.items, VistoKind.Action);
    for (const action of actions) {
      const dp = PlanDataService.getItemByGuid<VistoDpItem>(p.items, action.dpGuid);
      const parsed = PlannerLinkService.parsePlannerTaskLink(action.sourceItemUrl);
      if (parsed && dp && !processedItems.has(action.guid)) {
        try {
          const task = await PlannerService.getTask(parsed.taskId);
          if (task) {
            const actionUpdate = PlannerDataService.parsePlannerTaskProgress(task);
            await PlannerDataService.resolveUsers(p, [actionUpdate], notify);
            await PlannerConfigurationService.updateFromTask(result, p, action,  dp, task, actionUpdate, notify);
          } else {
            PlannerNotifications.makeBrokenTaskLinkNotification(p, action, notify);
          }
        } catch (error) {
          notifyInfoBar(notify, {
            type: NotificationType.warn,
            message: TextService.format(strings.PlannerNotification_BrokenTaskLink, {
              title: TextService.formatTitle(action, p.items)
            }),
            error: error,
            kind: dp.kind,
            guid: dp.guid,
            group: 'Planner_BrokenTaskLink',
          });
        }
      }
    }
  }

  public static async createPlannerTask(planId: string, bucketId: string, p: IVistoPlan, action: VistoActionItem, plannerSettings: IPlannerIntegrationSettings) {

    return await PlannerService.createTask({
      planId: planId,
      bucketId: bucketId,
      title: action.name,
      startDateTime: PlannerDataService.unparseTaskDate(action.startDate),
      dueDateTime: PlannerDataService.unparseTaskDate(action.endDate),
      ...(plannerSettings?.enableLabelSync ? { appliedCategories: this.getAppliedCategories(p, action.focusGuid) } : {}),
      assignments: makePlannerAssignments(action.assignedTo),
      details: {
        description: TextService.htmlToLines(action.description ?? null, ['p'])
      }
    });
  }

  private static reportError = (error, message: string, val: any) => {
    trackClient.error(message, error);
    if (isConsentError(error)) {
      throw error;
    } if (error.status === 404) {
      return val;
    } else {
      throw error;
    }
  };

  private static getPlan = async (planId: string): Promise<PlannerPlan> => {
    try {
      return await PlannerService.getPlan(planId);
    } catch (error) {
      return PlannerConfigurationService.reportError(error, TextService.format(strings.PlannerError_UnableToGetPlan, { planId }), null);
    }
  };

  private static getBuckets = async (planId: string): Promise<PlannerBucket[]> => {
    try {
      return await PlannerService.getBuckets(planId);
    } catch (error) {
      return PlannerConfigurationService.reportError(error, TextService.format(strings.PlannerError_UnableToGetBucket, { planId }), null);
    }
  };

  private static getBucket = async (planId: string, bucketId: string): Promise<PlannerBucket> => {
    try {
      const planBuckets = planId && await PlannerConfigurationService.getBuckets(planId);
      const found = planBuckets?.find(x => x.id === bucketId);
      if (found) {
        return found;
      }
      return await PlannerService.getBucket(bucketId);
    } catch (error) {
      return PlannerConfigurationService.reportError(error, TextService.format(strings.PlannerError_UnableToGetBucket, { bucketId, planId }), null);
    }
  };

  private static getTasks = async (planId: string): Promise<PlannerTask[]> => {
    try {
      return await PlannerService.getTasks(planId);
    } catch (error) {
      return PlannerConfigurationService.reportError(error, TextService.format(strings.PlannerError_UnableToGetBuckets, { planId }), {});
    }
  };

  private static getTask = async (planId: string, taskId: string): Promise<PlannerTask> => {
    try {
      const planTasks = planId && await PlannerConfigurationService.getTasks(planId);
      const found = planTasks?.find(x => x.id === taskId);
      if (found) {
        return found;
      }
      return await PlannerService.getTask(taskId);
    } catch (error) {
      return PlannerConfigurationService.reportError(error, TextService.format(strings.PlannerError_UnableToGetTask, { taskId }), null);
    }
  };
  
  public static async getPlannerChanges(
    vistoPlan: IVistoPlan,
    items: IVistoListItemWithProgress[],
    notify: INotify,
    operation: Operation,
    options: IPlannerOperationOptions): Promise<IPlannerChangesResult> {

    const processedItems = new Set<string>();

    const oldSettings = PlanSettingsService.getPlanSettings(vistoPlan);
    const oldPlannerSettings = oldSettings?.integrations?.planner;

    if (!options.integrationLevel)
      options.integrationLevel = oldPlannerSettings?.level;

    if (typeof (options.enableLabelSync) === 'undefined')
      options.enableLabelSync = oldPlannerSettings?.enableLabelSync ? true : false;

    if (!options.selectedKeys)
      options.selectedKeys = PlannerLinkService.getLinkedPlannerKeys(vistoPlan);

    const newPlannerSettings = {
      ...oldPlannerSettings,
      level: options.integrationLevel,
      enableLabelSync: options.enableLabelSync
    };

    const selectedKeySet = new Set(options.selectedKeys);

    const result: IPlannerChangesResult = {
      actions: [],
      created: [],
      updated: [],
      deleted: [],
    };

    if (operation === Operation.delete) {
      const plannerData = this.getPlannerLinkedItems(items);
      if (plannerData) {
        const removalList = this.getRemovalList(plannerData);
        result.actions.push({
          title: TextService.format(strings.PlannerMessage_DeleteList, { removalList }),
          execute: async (p) => {
            await this.deletePlannerData(plannerData);
            return p;
          }
        });
      }
    }


    const ensureBucketChildren = async (plan: PlannerPlan, bucket: PlannerBucket, dp: VistoDpItem) => {

      const actions = PlanDataService.getDpActions(vistoPlan, dp.guid);
      await PlannerDataService.resolveUsers(vistoPlan, actions, notify);

      // reverse because in planner the tasks are listed in reverse order by default
      for (const action of actions.reverse()) {
        const parsedTaskLink = PlannerLinkService.parsePlannerTaskLink(action.sourceItemUrl);

        const scheduleCreateTask = () => {
          result.actions.push({
            title: TextService.format(strings.PlannerMessage_CreateTask, { title: action.name }),
            execute: async (p) => {
              const newTask = await this.createPlannerTask(plan.id, bucket.id, vistoPlan, action, newPlannerSettings);
              const changes = ChangesService.getChanges(action, { sourceItemUrl: PlannerLinkService.makePlannerTaskLink(PlannerConfigurationService.tid, newTask.id) });
              processedItems.add(action.guid);
              result.updated.push({ item: action, changes });
              return p;
            }
          });
        };

        if (!parsedTaskLink) {
          if (!action.sourceItemUrl) {
            scheduleCreateTask();
          }
        } else {
          const task = await PlannerConfigurationService.getTask(plan.id, parsedTaskLink.taskId);
          const initiated = !!items.find(a => a.guid === action.guid);

          // task was deleted in planner: recycle in VisPlan (TODO: suggest to delete)
          if (!task) {
            if (initiated) {
              scheduleCreateTask();
            } else {
              processedItems.add(action.guid);
              result.deleted.push(action, ...PlanDataService.getDependencis(vistoPlan.items, action));
            }
            continue;
          }

          if (initiated) {
            const taskUpdate = this.getPlannerTaskUpdate(vistoPlan, action, task, bucket.id, plan.id, newPlannerSettings);
            if (taskUpdate) {
              result.actions.push({
                title: TextService.format(strings.PlannerMessage_UpdateTask, { title: action.name }),
                execute: p => taskUpdate.do(p)
              });
            }
            
          } else {

            // if planner checkboxes are set but percent complete is missing
            if (!task.percentComplete && task.activeChecklistItemCount) {
              result.actions.push({
                title: TextService.format(strings.PlannerMessage_UpdateTask, { title: action.name }),
                execute: async (p) => {
                  await PlannerService.updateTask(task.id, { percentComplete: 50 });
                  return p;
                }
              });
            }

          }
        }
      }

      const planTasks = await PlannerConfigurationService.getTasks(plan.id);
      const bucketTasks = planTasks.filter(t => t.bucketId === bucket.id);
      for (const bucketTask of bucketTasks) {
        const task = await PlannerConfigurationService.getTask(plan.id, bucketTask.id);
        const taskUrl = PlannerLinkService.makePlannerTaskLink(PlannerConfigurationService.tid, task.id);

        const initiated = !!items.find(a => a.sourceItemUrl === taskUrl);
        if (!initiated) {

          const actionUpdate = PlannerDataService.parsePlannerTaskProgress(task);

          await PlannerDataService.resolveUsers(vistoPlan, [actionUpdate], notify);
          const existing = PlanDataService.getItems<VistoActionItem>(vistoPlan.items, VistoKind.Action).find(a => a.sourceItemUrl === taskUrl);
          if (existing) {
            const task = await PlannerConfigurationService.getTask(plan.id, bucketTask.id);
            const bucketLink = PlannerLinkService.makePlannerBucketLink(PlannerConfigurationService.tid, plan.id, task.bucketId);
            const existingDps = PlanDataService.getItems<VistoDpItem>(vistoPlan.items, VistoKind.DP);
            const existingDp = existingDps.find(d => d.sourceItemUrl === bucketLink)
            processedItems.add(existing.guid);
            await PlannerConfigurationService.updateFromTask(result, vistoPlan, existing, existingDp, task, actionUpdate, notify);
          } else {
            const created: VistoActionItem = {
              ...actionUpdate,
              kind: VistoKind.Action,
              guid: makeGuidString(),
              dpGuid: dp.guid,
              lopGuid: dp.lopGuid,
              sourceItemUrl: PlannerLinkService.makePlannerTaskLink(PlannerConfigurationService.tid, task.id),
              focusGuid: null,
              useFocusDates: false
            };
            processedItems.add(created.guid);
            result.created.push(created);
          }
        }
      }
    };

    const ensureBucket = async (plan: PlannerPlan, item: IVistoListItemWithProgress) => {

      const parsedBucketLink = plan && PlannerLinkService.parsePlannerBucketLink(item.sourceItemUrl);
      const initiated = !!items.find(x => x.guid === item.guid);

      const scheduleCreateBucket = () => {
        result.actions.push({
          title: TextService.format(strings.PlannerMessage_UpdateBucket, { title: item.name }),
          execute: async (p) => {
            const newBucket = await PlannerService.createBucket({ planId: plan.id, name: item.name, id: item.guid });
            const changes = ChangesService.getChanges(item, { sourceItemUrl: PlannerLinkService.makePlannerBucketLink(PlannerConfigurationService.tid, plan.id, newBucket.id) });
            processedItems.add(item.guid);
            result.updated.push({ item, changes });
            await ensureBucketChildren(plan, newBucket, item);
            return p;
          }
        });
      };

      if (!parsedBucketLink) {
        if (!item.sourceItemUrl) {
          scheduleCreateBucket();
        }
      } else {
        const bucket = await PlannerConfigurationService.getBucket(plan.id, parsedBucketLink.bucketId);

        // bucket has been deleted in planner: recycle in VisPlan (TODO: suggest to delete)
        if (!bucket) {
          if (initiated) {
            scheduleCreateBucket();
          } else {
            PlannerNotifications.makeBrokenBucketLinkNotification(vistoPlan, item, notify);
          }
          return;
        }

        const nameChanges = ChangesService.getChanges<PlannerBucket>({ name: bucket.name }, { name: item.name });
        if (nameChanges.detected) {
          if (initiated) {
            result.actions.push({
              title: TextService.format(strings.PlannerMessage_RenameBucket, { title: item.name }),
              execute: async (p) => {
                await PlannerService.updateBucket(bucket.id, nameChanges.newValues);
                return p;
              }
            });
          } else {
            PlannerNotifications.makeBucketNameConflictNotification(vistoPlan, item, bucket, notify);
          }
        }
        const planChanges = ChangesService.getChanges<PlannerBucket>({ planId: bucket.planId }, { planId: plan.id });
        if (planChanges.detected) {
          if (initiated) {
            result.actions.push({
              title: TextService.format(strings.PlannerMessage_MoveBucket, { title: item.name }),
              execute: async (p) => {
                const bucketId = await PlannerService.moveBucket(bucket.id, plan.id);
                const changes = ChangesService.getChanges(item, { sourceItemUrl: PlannerLinkService.makePlannerBucketLink(PlannerConfigurationService.tid, plan.id, bucketId) });
                processedItems.add(item.guid);
                result.updated.push({ item, changes });
                return p;
              }
            });
          } else {
            PlannerNotifications.makeBucketPlanConflictNotification(vistoPlan, item, bucket, notify);
          }
        }
        await ensureBucketChildren(plan, bucket, item);
      }
    };

    const ensurePlan = async (parent: IVistoListItemWithProgress, children: IVistoListItemWithProgress[]) => {

      const ensurePlanChildren = async (targetPlan: PlannerPlan) => {

        if (options.enableLabelSync) {
          const labelUpdate = this.getPlannerLabelsUpdate(vistoPlan, targetPlan);
          if (labelUpdate) {
            result.actions.push({
              title: TextService.format(strings.PlannerMessage_UpdateLabels, { title: vistoPlan.name }),
              execute: p => labelUpdate(p)
            });
          }
        }

        for (const child of children) {
          await ensureBucket(targetPlan, child);
        }
      };

      const parsedPlanLink = PlannerLinkService.parsePlannerPlanLink(parent.sourceItemUrl);

      const scheduleCreatePlan = () => {
        result.actions.push({
          title: TextService.format(strings.PlannerMessage_CreatePlan, { title: parent.name }),
          execute: async (p) => {
            const newPlan = await PlannerService.createPlan({ title: parent.name, owner: PlannerConfigurationService.groupId });
            if (options.integrationLevel === PlannerIntegrationLevel.Plan) {
              newPlannerSettings.url = PlannerLinkService.makePlannerPlanLink(PlannerConfigurationService.tid, newPlan.id);
            } else {
              const changes = ChangesService.getChanges(parent, { sourceItemUrl: PlannerLinkService.makePlannerPlanLink(PlannerConfigurationService.tid, newPlan.id) });
              processedItems.add(parent.guid);
              result.updated.push({ item: parent, changes });
            }
            await ensurePlanChildren(newPlan);
            return p;
          }
        });
      };

      if (!parsedPlanLink) {
        if (!parent.sourceItemUrl) {
          scheduleCreatePlan();
        }
      } else {

        const initiated = !!items.find(x => x.guid === parent.guid);
        const plan = await PlannerConfigurationService.getPlan(parsedPlanLink.planId);

        // bucket has been deleted in planner: recycle in VisPlan (TODO: suggest to delete)
        if (!plan) {
          if (initiated) {
            scheduleCreatePlan();
          } else {
            PlannerNotifications.makeBrokenPlanLinkNotification(vistoPlan, parent, notify);
          }
          return;
        }

        const changes = ChangesService.getChanges({ title: plan.title }, { title: parent.name });
        if (changes.detected) {
          if (initiated) {
            result.actions.push({
              title: TextService.format(strings.PlannerMessage_RenamePlan, { title: parent.name }),
              execute: async (p) => {
                await PlannerService.updatePlan(plan.id, changes.newValues);
                return p;
              }
            });
          } else {
            PlannerNotifications.makePlanNameConflictNotification(vistoPlan, parent, plan, notify);
          }
        }
        await ensurePlanChildren(plan);
      }
    };

    const generateActionsIntegrationLevelPlanToPlan = async () => {

      const planItem = {
        kind: VistoKind.Plan,
        name: vistoPlan.name,
        sourceItemUrl: newPlannerSettings.url
      };

      const dps = PlanDataService.getItems<VistoDpItem>(vistoPlan.items, VistoKind.DP).filter(dp => selectedKeySet.has(dp.guid));
      await ensurePlan(planItem, dps);
    };

    const generateActionsIntegrationLevelCapabilityToPlan = async () => {

      const lops = PlanDataService.getLops(vistoPlan).filter(lop => selectedKeySet.has(lop.guid));
      await Promise.all(
        lops.reverse().map(async (lop) => {
          const dps = PlanDataService.getLopDps(vistoPlan, lop.guid).filter(dp => selectedKeySet.has(dp.guid));
          await ensurePlan(lop, dps);
        })
      );
    };

    const generateActionsIntegrationLevelAmbitionToPlan = async () => {

      const dps = PlanDataService.getItems<VistoDpItem>(vistoPlan.items, VistoKind.DP).filter(dp => selectedKeySet.has(dp.guid));
      for (const dp of dps.reverse()) {
        const dpActions = PlanDataService.getDpActions(vistoPlan, dp.guid);
        await ensurePlan(dp, dpActions);
      }
    };

    switch (options.integrationLevel) {

      case PlannerIntegrationLevel.Plan:
        await generateActionsIntegrationLevelPlanToPlan();
        break;

      case PlannerIntegrationLevel.LOP:
        await generateActionsIntegrationLevelCapabilityToPlan();
        break;

      case PlannerIntegrationLevel.DP:
        await generateActionsIntegrationLevelAmbitionToPlan();
        break;
    }

    if (JSON.stringify(newPlannerSettings) !== JSON.stringify(oldPlannerSettings)) {
      result.actions.push({
        title: TextService.format(strings.PlannerMessage_UpdateSettings),
        execute: async (p) => {
          const newSettings = { ...oldSettings, integrations: { ...oldSettings?.integrations, planner: newPlannerSettings } };
          p = PlanSettingsService.setPlanSettings(p, newSettings);
          await StorageService.get(p.siteUrl).updatePlanItem(p, { settingsJson: p.settingsJson }, notify);
          return p;
        }
      });
    }

    await this.updateDisconnectedTasks(vistoPlan, result, notify, processedItems);

    return result;
  }

  private static updateSinglePlannerItem(vistoPlan: IVistoPlan, oldItem: IVistoListItem, options: IPlannerIntegrationSettings, notify: INotify) {
    const item = PlanDataService.getItemByGuid(vistoPlan.items, oldItem.guid);
    switch (item.kind) {
      case VistoKind.Action:
        return this.updateSinglePlannerTask(vistoPlan, item as VistoActionItem, options, notify);
      case VistoKind.DP:
        return this.updateSinglePlannerBucket(vistoPlan, item as VistoDpItem, notify);
      default:
        return vistoPlan;
    }
  }

  public static async updateSinglePlannerBucket(vistoPlan: IVistoPlan, dp: VistoDpItem, notify: INotify) {

    const bucketLink = PlannerLinkService.parsePlannerBucketLink(dp.sourceItemUrl);
    if (bucketLink) {
      await PlannerDataService.resolveUsers(vistoPlan, [dp], notify);
      
      const bucket = await PlannerService.getBucket(bucketLink.bucketId);
      const nameUpdate = this.getPlannerBucketNameUpdate(vistoPlan, dp, bucket);
      if (nameUpdate) {
        vistoPlan = await nameUpdate.do(vistoPlan);
      }
    }

    return vistoPlan;
  }

  public static async updateSinglePlannerTask(vistoPlan: IVistoPlan, action: VistoActionItem, plannerSettings: IPlannerIntegrationSettings, notify: INotify) {
    
    const dp = PlanDataService.getItemByGuid<VistoDpItem>(vistoPlan.items, action.dpGuid);
    const bucketLink = PlannerLinkService.parsePlannerBucketLink(dp.sourceItemUrl);
  
    const taskLink = PlannerLinkService.parsePlannerTaskLink(action.sourceItemUrl);
    if (taskLink) {
      await PlannerDataService.resolveUsers(vistoPlan, [action], notify);

      if (bucketLink && plannerSettings?.enableLabelSync) {
        const plannerPlan = await PlannerService.getPlan(bucketLink.planId);
        const labelUpdate = this.getPlannerLabelsUpdate(vistoPlan, plannerPlan);
        if (labelUpdate) {
          vistoPlan = await labelUpdate(vistoPlan);
        }
      }
  
      const task = await PlannerService.getTask(taskLink.taskId);
      const bucketId = bucketLink?.planId === task.planId ? bucketLink.bucketId : null;
      const taskUpdate = this.getPlannerTaskUpdate(vistoPlan, action, task, bucketId, task.planId, plannerSettings);
      if (taskUpdate) {
        vistoPlan = await taskUpdate.do(vistoPlan);
      }
    }

    return vistoPlan;
  }

  private static createSinglePlannerItem(vistoPlan: IVistoPlan, item: IVistoListItem, options: IPlannerIntegrationSettings, notify: INotify) {
    switch (item.kind) {
      case VistoKind.Action:
        return this.createSinglePlannerTask(vistoPlan, item as VistoActionItem, options, notify);
      case VistoKind.DP:
        return this.createSinglePlannerBucket(vistoPlan, item as VistoDpItem, notify);
      default:
        return vistoPlan;
    }
  }

  public static async createSinglePlannerTask(vistoPlan: IVistoPlan, action: VistoActionItem, plannerSettings: IPlannerIntegrationSettings, notify: INotify) {
    
    const dp = PlanDataService.getItemByGuid<VistoDpItem>(vistoPlan.items, action.dpGuid);
    if (dp) {
      const bucketLink = PlannerLinkService.parsePlannerBucketLink(dp.sourceItemUrl);
      if (bucketLink) {
        await PlannerDataService.resolveUsers(vistoPlan, [action], notify);
        const newTask = await this.createPlannerTask(bucketLink.planId, bucketLink.bucketId, vistoPlan, action, plannerSettings);
        const changes = ChangesService.getChanges(action, { sourceItemUrl: PlannerLinkService.makePlannerTaskLink(PlannerConfigurationService.tid, newTask.id) });
  
        vistoPlan = await StorageService.get(vistoPlan.siteUrl).updateItems(vistoPlan, [{ item: action, changes }], notify, defaultSpUpdateOptions);
      }
    }

    return vistoPlan;
  }

  public static async createSinglePlannerBucket(vistoPlan: IVistoPlan, dp: VistoDpItem, notify: INotify) {

    const lop = PlanDataService.getItemByGuid<VistoLopItem>(vistoPlan.items, dp.lopGuid);
    if (lop) {
      const planLink = PlannerLinkService.parsePlannerPlanLink(lop.sourceItemUrl);
      if (planLink) {
        await PlannerDataService.resolveUsers(vistoPlan, [dp], notify);
        const newBucket = await PlannerService.createBucket({ planId: planLink.planId, name: dp.name, id: dp.guid });
        const changes = ChangesService.getChanges(dp, { sourceItemUrl: PlannerLinkService.makePlannerBucketLink(PlannerConfigurationService.tid, planLink.planId, newBucket.id) });
  
        vistoPlan = await StorageService.get(vistoPlan.siteUrl).updateItems(vistoPlan, [{ item: dp, changes }], notify, defaultSpUpdateOptions);
      }
    }
    return vistoPlan;
  }

  private static getPlannerBucketNameUpdate(vistoPlan: IVistoPlan, dp: VistoDpItem, bucket: PlannerBucket): IUndoUnit {
    const nameChanges = ChangesService.getChanges<PlannerBucket>({ name: bucket.name }, { name: dp.name });
    if (nameChanges.detected) {
      return {
        do: async (p) => {
          await PlannerService.updateBucket(bucket.id, nameChanges.newValues);
          return p;
        },
        undo: async (p) => {
          await PlannerService.updateBucket(bucket.id, nameChanges.newValues);
          return p;
        }
      }
    }
  }

  private static getPlannerTaskUpdate(vistoPlan: IVistoPlan, action: VistoActionItem, task: PlannerTask, bucketId: string, planId: string,
    plannerSettings: IPlannerIntegrationSettings): IUndoUnit {

    const changes = ChangesService.getChanges<PlannerTask>({
      title: task.title,
      startDateTime: task.startDateTime ?? null,
      dueDateTime: task.dueDateTime ?? null,
      bucketId: bucketId ? task.bucketId : null,
      planId: task.planId,
      assignments: clearPlannerAssignments(task.assignments)
    }, {
      title: action.name,
      startDateTime: PlannerDataService.unparseTaskDate(action.startDate),
      dueDateTime: PlannerDataService.unparseTaskDate(action.endDate),
      bucketId: bucketId ? bucketId : null,
      planId: planId,
      assignments: makePlannerAssignments(action.assignedTo)
    });
    
    if (plannerSettings?.enableLabelSync) {
      const oldAppliedCategories = this.normalizeTaskAppliedCategories(task.appliedCategories);
      const newAppliedCategories = this.getAppliedCategories(vistoPlan, action.focusGuid);
      const labelChanges = ChangesService.getChanges<PlannerTask>(oldAppliedCategories, newAppliedCategories);
      if (labelChanges.detected) {
        changes.detected = true;
        changes.properties.push('appliedCategories');
        changes.newValues.appliedCategories = labelChanges.newValues;
        changes.oldValues.appliedCategories = labelChanges.oldValues;
      }
    }
    
    const detailsChanges = ChangesService.getChanges<PlannerTaskDetails>({
      description: task.details?.description ?? null
    }, {
      description: TextService.htmlToLines(action.description ?? null, ['p'])
    });

    if (changes.detected || detailsChanges.detected) {

      // overwrite to enforce old assignments removal
      if (changes.properties.indexOf('assignments') >= 0) {
        for (const key in changes.oldValues.assignments) {
          if (Object.keys(changes.newValues.assignments).indexOf(key) < 0) {
            changes.newValues.assignments[key] = null;
          }
        }
      }

      return {
        do: async (p) => {
          if (changes.detected) {
            await PlannerService.updateTask(task.id, changes.newValues);
          }
          if (detailsChanges.detected) {
            await PlannerService.updateTaskDetails(task.id, detailsChanges.newValues);
          }
          return p;
        },
        undo: async (p) => {
          if (changes.detected) {
            await PlannerService.updateTask(task.id, changes.oldValues);
          }
          if (detailsChanges.detected) {
            await PlannerService.updateTaskDetails(task.id, detailsChanges.oldValues);
          }
          return p;
        }
      };
    }
  }

  private static getPlannerLabelsUpdate = (vistoPlan: IVistoPlan, plan: PlannerPlan) => {
    const categoryDescriptions = plan.details?.categoryDescriptions ?? {};
    const focuses = this.getFocuses(vistoPlan);
    const labels: any = {};
    for (let i = 0; i < focuses.length && i < 24; ++i) {
      labels[makeCategoryId(i)] = focuses[i].name.trim();
    }
    if (focuses.length < 24) {
      labels[makeCategoryId(focuses.length)] = TextService.format(strings.CategoryName_Unused);
    }
    const detailChanges = ChangesService.getChanges(categoryDescriptions ?? {}, labels);
    if (detailChanges.detected) {
      return async (p) => {
          await PlannerService.updatePlanDetails(plan.id, { categoryDescriptions: detailChanges.newValues });
          return p;
      };
    }
  };

  public static isConnectedToPlanner(vistoPlan: IVistoPlan): boolean {
    const oldSettings = PlanSettingsService.getPlanSettings(vistoPlan);
    const plannerSettings = oldSettings?.integrations?.planner;
    if (!plannerSettings?.level || !LicenseService.license?.plannerEnabled || !PlannerConfigurationService.groupId) {
      return false;
    }

    for (const item of PlanDataService.getItemsHaving<IVistoListItemWithProgress>(vistoPlan.items, x => x.kind === VistoKind.Action || x.kind === VistoKind.DP || x.kind === VistoKind.LOP)) {
      if (PlannerLinkService.isPlannerLink(item.sourceItemUrl)) {
        return true;
      }
    }
    
    return false;
  }

  public static validatePlannerConnection = async (notify: INotify, forceEdit: () => void) => {

    const callback = async () => {
      await PlannerService.getPlans(PlannerConfigurationService.groupId);
    }

    try {
      await callback();
      return true;
    } catch (error) {
      if (isConsentError(error)) {
        AuthService.resetAuth(TokenKind.planner);
        PlannerNotifications.makeConsentNotification(callback, notify, TextService.format(strings.PlannerNotification_TestAuthorizationRequired), forceEdit);
      } else {
        const errorTitle = TextService.format(strings.PlannerNotification_TestFailed);
        trackClient.error(errorTitle);
        getErrorMessage(error).then(message => {
          PlannerNotifications.makeTestFailedNotification(notify, message, error, forceEdit);
        });
      }
      return false;
    }
  }
}
