import { PlannerBucket, PlannerPlan } from '@microsoft/microsoft-graph-types';
import { ICommand } from 'services/ICommand';
import { AuthService } from 'services/AuthService';
import { ChangesService, IChanges } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { CommandsMx } from 'services/CommandMx';
import { clearInfoBar, IBasicNotify, INotificationAction, INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { TokenKind } from 'shared/TokenKind';
import { Operation } from 'shared/Operation';
import { CommandName } from 'shared/CommandName';
import { IVistoListItem, IVistoListItemWithProgress, IVistoPlan, VistoActionItem, VistoDpItem, VistoKind, VistoLopItem } from 'sp';
import strings from 'VistoWebPartStrings';
import { PlannerConfigurationService } from './PlannerConfigurationService';
import { PlannerLinkService } from './PlannerLinkService';
import { PlannerService } from './PlannerService';
import { IPlannerIntegrationSettings } from './PlannerIntegrationLevel';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { PlannerDataService } from './PlannerDataService';

export class PlannerNotifications {

  public static makeRenameBucketCommand(bucketId: string, oldName: string, newName: string): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (plan) => {
            await PlannerService.updateBucket(bucketId, { name: newName });
            return plan;
          },
          undo: async (plan) => {
            await PlannerService.updateBucket(bucketId, { name: oldName });
            return plan;
          }
        };
      },
      message: TextService.format(strings.PlannerMessage_RenameBucket, { title: newName }),
      name: CommandName.MicrosoftPlanner_RenameBucket
    };
  }

  public static makeRenamePlanCommand(planId: string, oldName: string, newName: string): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (plan) => {
            await PlannerService.updatePlan(planId, { title: newName });
            return plan;
          },
          undo: async (plan) => {
            await PlannerService.updatePlan(planId, { title: oldName });
            return plan;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_RenamePlanMessage, { newName }),
      name: CommandName.MicrosoftPlanner_RenamePlan
    };
  }

  public static makeBucketNameConflictNotification(vistoPlan: IVistoPlan, item: IVistoListItem, bucket: PlannerBucket, notify: INotify) {

    const changes = ChangesService.getChanges(item, { name: bucket.name }, ['name']);

    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.PlannerNotification_BucketNameDiffers),
      error: TextService.format(strings.PlannerNotification_BucketNameDiffersDescription, {
        title: TextService.formatTitle(item, vistoPlan.items),
        bucketName: bucket.name
      }),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      actions: [
        {
          title: TextService.format(strings.PlannerNotification_ButtonVisPlan),
          command: PlannerNotifications.makeRenameBucketCommand(bucket.id, changes.newValues.name, changes.oldValues.name)
        },
        {
          title: TextService.format(strings.PlannerNotification_ButtonPlanner),
          command: Commands.makeRenameItemCommand(vistoPlan, item, changes, notify)
        },
      ]
    });
  }

  public static makeBucketPlanConflictNotification(vistoPlan: IVistoPlan, item: IVistoListItem, bucket: PlannerBucket, notify: INotify) {

    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.PlannerNotification_MessagePlanMismatch),
      error: TextService.format(strings.PlannerNotification_MessagePlanMismatchDetails, {
        title: TextService.formatTitle(item, vistoPlan.items),
        bucketName: bucket.name
      }),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      actions: []
    });
  }

  public static makePlanNameConflictNotification(vistoPlan: IVistoPlan, item: IVistoListItem, plan: PlannerPlan, notify: INotify) {

    const changes = ChangesService.getChanges(item, { name: plan.title }, ['name']);

    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.PlannerNotification_PlanNameDifferent),
      error: TextService.format(strings.PlannerNotification_PlanNameDifferentDetails, {
        title: TextService.formatTitle(item, vistoPlan.items),
        planName: plan.title
      }),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      actions: [
        {
          title: TextService.format(strings.PlannerNotification_ButtonVisPlan),
          command: PlannerNotifications.makeRenamePlanCommand(plan.id, changes.newValues.name, changes.oldValues.name)
        },
        {
          title: TextService.format(strings.PlannerNotification_ButtonPlanner),
          command: Commands.makeRenameItemCommand(vistoPlan, item, changes, notify)
        },
      ]
    });
  }

  private static makeLinkedPlannerPlannNotificationAction(vistoPlan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.PlannerNotification_CreateNewLinkedPlan),
      command: this.makeNewPlannerPlan(item, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.PlannerNotification_ButtonCreate),
        buttonOkBusyText: TextService.format(strings.PlannerNotification_ButtonCreating),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_CreateNewLinkedPlanTitle),
        content: TextService.format(strings.PlannerNotification_CreateNewLinkedPlanMessage, {
          title: TextService.formatTitle(item, vistoPlan.items)
        }),
      }
    };
  }

  private static makeLinkedPlannerBucketNotificationAction(vistoPlan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.PlannerNotification_CreateNewLinkedBucket),
      command: this.makeNewPlannerBucket(vistoPlan, item, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.PlannerNotification_ButtonCreate),
        buttonOkBusyText: TextService.format(strings.PlannerNotification_ButtonCreating),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_CreateNewLinkedBucketTitle),
        content: TextService.format(strings.PlannerNotification_CreateNewLinkedBucketDescription, {
          title: TextService.formatTitle(item, vistoPlan.items)
        }),
      }
    };
  }

  private static makeLinkedPlannerTaskNotificationAction(vistoPlan: IVistoPlan, item: VistoActionItem, notify: INotify) {
    const planSettings = PlanSettingsService.getPlanSettings(vistoPlan);
    const plannerSettings = planSettings?.integrations?.planner;
    return {
      title: TextService.format(strings.PlannerNotification_CreateNewLinkedTask),
      command: this.makeNewPlannerTask(item, notify, plannerSettings),
      confirmation: {
        buttonOkText: TextService.format(strings.PlannerNotification_ButtonCreate),
        buttonOkBusyText: TextService.format(strings.PlannerNotification_ButtonCreating),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_CreateNewLinkedTaskTitle),
        content: TextService.format(strings.PlannerNotification_CreateNewLinkedTaskDescription, {
          title: TextService.formatTitle(item, vistoPlan.items)
        }),
      }
    };
  }

  public static makeBreakPlannerLinkNotificationAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.PlannerNotification_BreakLinkTitle),
      command: Commands.makeBreakLinkAction(plan, item, true, CommandName.BreakLinkPlanner, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonBreak),
        buttonOkBusyText: TextService.format(strings.ButtonBreaking),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_BreakPlannerLink),
        content: TextService.format(strings.PlannerNotification_BreakPlannerLinkDescription, { title: TextService.formatTitle(item, plan.items) }),
      }
    };
  }

  public static makeBrokenBucketLinkNotification(vistoPlan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    clearInfoBar(notify, 'Planner_BrokenTaskLink');
    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.PlannerNotification_BrokenBucketLink, {
        title: TextService.formatTitle(item, vistoPlan.items)
      }),
      error: TextService.format(strings.PlannerNotification_BrokenBucketLinkDetails),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      group: 'Planner_BrokenBucketLink',
      actions: [
        this.makeBreakPlannerLinkNotificationAction(vistoPlan, item, notify),
        this.makeLinkedPlannerBucketNotificationAction(vistoPlan, item, notify)
      ]
    });
  }

  public static makeBrokenTaskLinkNotification(vistoPlan: IVistoPlan, item: VistoActionItem, notify: INotify) {
    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.PlannerNotification_BrokenTaskLink, {
        title: TextService.formatTitle(item, vistoPlan.items)
      }),
      error: TextService.format(strings.PlannerNotification_BrokenTaskLinkDetails),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      group: 'Planner_BrokenTaskLink',
      actions: [
        CommandsMx.makeDeleteItemNotificationAction(vistoPlan, item, notify),
        this.makeBreakPlannerLinkNotificationAction(vistoPlan, item, notify),
        this.makeLinkedPlannerTaskNotificationAction(vistoPlan, item, notify)
      ]
    });
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify,
    message?: string, allowEdit?: () => void) {

    notifyInfoBar(notify, {
      message: message || TextService.format(strings.PlannerNotification_AuthorizationRequired),
      group: 'Planner_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.PlannerNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.PlannerNotification_ButtonAuthorize),
          action: async () => {
            try {
              await AuthService.getConsent(TokenKind.planner, '', callback);
              clearInfoBar(notify, 'Planner_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.PlannerNotification_ConsentGrant), group: 'Planner_Consent' });
            } catch (error) {
              const message = TextService.format(strings.PlannerNotification_ConsentRequiredDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Planner_Consent' });
            }
            if (allowEdit) {
              allowEdit();
            }
        }
        }
      ]
    });
  }

  public static makeTestFailedNotification(notify: IBasicNotify, message: string, error: any, allowEdit: () => void) {

    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message,
      group: 'Planner_Sync',
      error,
      actions: [
        {
          title: TextService.format(strings.PlannerNotification_ButtonAllowEdit),
          action: async () => {
            allowEdit();
          }
        }
      ]
    });
  }

  private static isLinkedToPlannerOrStandAlone(item: IVistoListItemWithProgress) {
    return item && (!item.sourceItemUrl || PlannerLinkService.isPlannerLink(item.sourceItemUrl));
  }

  private static makeNewPlannerPlan(lop: VistoLopItem, notify: INotify): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (p) => {

            const dps = PlanDataService
              .getLopDps(p, lop.guid)
              .filter(this.isLinkedToPlannerOrStandAlone);

            const items = [
              lop,
              ...dps,
              ...dps.reduce((r, dp) => [...r, ...PlanDataService.getDpActions(p, dp.guid)], [])
            ];
            p = await PlannerConfigurationService.synchronizeStructureWithPlanner(p, items, notify, Operation.create, {
              selectedKeys: [...PlannerLinkService.getLinkedPlannerKeys(p), ...items.map(item => item.guid)]
            });
            return p;
          },
          undo: async (p) => {
            return p;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_CreateMissingPlan),
      name: CommandName.MicrosoftPlanner_CreateMissingPlan
    };
  }

  private static makeNewPlannerBucket(plan: IVistoPlan, dp: VistoDpItem, notify: INotify): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (p) => {

            const lop = PlanDataService.getItemByGuid<VistoLopItem>(plan.items, dp.lopGuid);

            const items = [
              ...(this.isLinkedToPlannerOrStandAlone(lop) ? [lop] : []),
              dp,
              ...PlanDataService.getDpActions(p, dp.guid)
            ];
            p = await PlannerConfigurationService.synchronizeStructureWithPlanner(p, items, notify, Operation.create, {
              selectedKeys: [...PlannerLinkService.getLinkedPlannerKeys(p), ...items.map(item => item.guid)]
            });
            return p;
          },
          undo: async (p) => {
            return p;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_CreateMissingBucket),
      name: CommandName.MicrosoftPlanner_CreateMissingBucket
    };
  }

  private static makeNewPlannerTask(action: VistoActionItem, notify: INotify, plannerSettings: IPlannerIntegrationSettings): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (p) => {
            const lop = action.lopGuid && PlanDataService.getItemByGuid<VistoLopItem>(p.items, action.lopGuid);
            const dp = action.dpGuid && PlanDataService.getItemByGuid<VistoDpItem>(p.items, action.dpGuid);
            const planId = lop && PlannerLinkService.parsePlannerPlanLink(lop.sourceItemUrl)?.planId;
            const bucketId = dp && PlannerLinkService.parsePlannerBucketLink(dp.sourceItemUrl)?.planId;
            const newTask = await PlannerConfigurationService.createPlannerTask(planId, bucketId, p, action, plannerSettings);

            const changes = ChangesService.getChanges(action, { sourceItemUrl: PlannerLinkService.makePlannerTaskLink(PlannerConfigurationService.tid, newTask.id) });
            p = await StorageService.get(p.siteUrl).updateItems(p, [{ item: action, changes }], notify, { excludeGroupByKind: true, enableSimpleUpdate: true });
            return p;
          },
          undo: async (p) => {
            return p;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_CreateMissingTask),
      name: CommandName.MicrosoftPlanner_CreateMissingTask
    };
  }

  private static makeDeletePlannerTaskCommand(vistoPlan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify): ICommand {
    return {
      prepare: async () => {
        const parsed = PlannerLinkService.parsePlannerTaskLink(item.sourceItemUrl);
        const task = await PlannerService.getTask(parsed.taskId);
        const changes = ChangesService.getChanges(item, { sourceItemUrl: null });
        return {
          do: async (p) => {
            await PlannerService.deleteTask(parsed.taskId);
            p = await StorageService.get(p.siteUrl).updateItems(p, [{ item, changes }], notify, { excludeExternals: true });
            return p;
          },
          undo: async (p) => {
            const newTask = await PlannerService.createTask(task);
            p = await StorageService.get(p.siteUrl).updateItems(p, [{ item, changes }], notify, { excludeExternals: true, reverse: true });
            return p;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_DeleteTask),
      name: CommandName.MicrosoftPlanner_DeleteTask
    };
  }

  private static makeDeletePlannerBucketCommand(vistoPlan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify): ICommand {
    return {
      prepare: async () => {
        const parsed = PlannerLinkService.parsePlannerBucketLink(item.sourceItemUrl);
        const bucket = await PlannerService.getBucket(parsed.bucketId);
        const tasks = await PlannerService.getBucketTasks(parsed.bucketId);
        const actions = PlanDataService.getDpActions(vistoPlan, item.guid);
        return {
          do: async (p) => {
            const bucketChanges = ChangesService.getChanges(item, { sourceItemUrl: null });
            const updates = [{ item: item, changes: bucketChanges }];
            for (const task of tasks) {
              await PlannerService.deleteTask(task.id);
              const action = actions.find(a => a.sourceItemUrl === PlannerLinkService.makePlannerTaskLink(parsed.tid, task.id));
              if (action) {
                task['vistoGuid'] = action.guid;
                const actionChanges = ChangesService.getChanges(action, { sourceItemUrl: null });
                updates.push({ item: action, changes: actionChanges });
              }
            }
            await PlannerService.deleteBucket(parsed.bucketId);
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { excludeExternals: true, excludeGroupByKind: true });
            return p;
          },
          undo: async (p) => {
            const restoredBucket = { ...bucket };
            const newBucket = await PlannerService.createBucket(restoredBucket);
            const bucketChanges = ChangesService.getChanges(item, { sourceItemUrl: PlannerLinkService.makePlannerTaskLink(parsed.tid, newBucket.id) });
            const updates = [{ item, changes: bucketChanges }];
            for (const task of tasks) {
              const restoredTask = { ...task, bucketId: newBucket.id };
              const newTask = await PlannerService.createTask(restoredTask);
              const action = PlanDataService.getItemByGuid<VistoActionItem>(p.items, task['vistoGuid']);
              if (action) {
                const actionChanges = ChangesService.getChanges(action, { sourceItemUrl: PlannerLinkService.makePlannerTaskLink(parsed.tid, newTask.id) });
                updates.push({ item: action, changes: actionChanges });
              }
            }
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { excludeExternals: true, excludeGroupByKind: true });
            return p;
          }
        };
      },
      message: TextService.format(strings.PlannerNotification_DeleteBucket),
      name: CommandName.MicrosoftPlanner_DeleteBucket
    };
  }

  private static makeDeleteBucketNotificationAction(vistoPlan: IVistoPlan, dp: VistoLopItem, notify: INotify): INotificationAction {
    return {
      title: TextService.format(strings.PlannerNotification_InvalidAmbitionDeleteBucket),
      command: this.makeDeletePlannerBucketCommand(vistoPlan, dp, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonDelete),
        buttonOkBusyText: TextService.format(strings.ButtonDeleting),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_InvalidAmbitionDeleteBucketTitle),
        content: TextService.format(strings.PlannerNotification_InvalidAmbitionDeleteBucketDetails, {
          title: TextService.formatTitle(dp, vistoPlan.items)
        })
      }
    };
  }

  public static makeInvalidAmbitionNotification(vistoPlan: IVistoPlan, dp: VistoDpItem, lop: VistoLopItem, notify: INotify, group: string) {

    const message = lop
      ? TextService.format(strings.PlannerNotification_ConnectedAmbitionOnDisconnectedLine)
      : TextService.format(strings.PlannerNotification_ConnectedAmbitionNotOnLine);

    const error = lop
      ? TextService.format(strings.PlannerNotification_ConnectedAmbitionOnDisconnectedLineDetails,
        {
          dpName: TextService.formatTitle(dp, vistoPlan.items),
          lopName: TextService.formatTitle(lop, vistoPlan.items)
        })
      : TextService.format(strings.PlannerNotification_ConnectedAmbitionNotOnLineDetails,
        {
          dpName: TextService.formatTitle(dp, vistoPlan.items)
        });

    notifyInfoBar(notify, {
      message,
      type: NotificationType.warn,
      kind: lop ? VistoKind.LOP : VistoKind.DP,
      guid: lop ? lop.guid : dp.guid,
      error,
      group,
      actions: [
        this.makeDeleteBucketNotificationAction(vistoPlan, dp, notify),
        ...(lop ? [this.makeLinkedPlannerPlannNotificationAction(vistoPlan, lop, notify)] : [])
      ]
    });
  }

  public static makeBrokenPlanLinkNotification(vistoPlan: IVistoPlan, lop: VistoLopItem, notify: INotify) {

    clearInfoBar(notify, 'Planner_BrokenBucketLink');
    clearInfoBar(notify, 'Planner_BrokenTaskLink');
    notifyInfoBar(notify, {
      message: TextService.format(strings.PlannerNotification_BrokenPlanLinkMessage),
      type: NotificationType.warn,
      kind: VistoKind.LOP,
      guid: lop.guid,
      error: TextService.format(strings.PlannerNotification_BrokenPlanLinkDetails, {
        title: TextService.formatTitle(lop, vistoPlan.items)
      }),
      group: 'Planner_BrokenPlanLink',
      actions: [
        this.makeBreakPlannerLinkNotificationAction(vistoPlan, lop, notify),
        this.makeLinkedPlannerPlannNotificationAction(vistoPlan, lop, notify)
      ]
    });
  }

  public static makeDeletePlannerTaskNotificationAction(vistoPlan: IVistoPlan, action: VistoActionItem, notify: INotify) {
    return {
      title: TextService.format(strings.PlannerNotification_CommandDeleteTask),
      command: this.makeDeletePlannerTaskCommand(vistoPlan, action, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonDelete),
        buttonOkBusyText: TextService.format(strings.ButtonDeleting),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.PlannerNotification_CommandDeleteTaskTitle),
        content: TextService.format(strings.PlannerNotification_CommandDeleteTaskDetails, {
          title: TextService.formatTitle(action, vistoPlan.items)
        })
      }
    };
  }

  public static makeDateConflictNotification(plan: IVistoPlan, item: VistoActionItem, changes: IChanges<VistoActionItem>, notify: INotify) {
    notifyInfoBar(notify, {
      type: NotificationType.warn,
      message: TextService.format(strings.Planner_DateConflict_Message, { title: TextService.formatTitle(item, plan.items) }),
      error: TextService.format(strings.Planner_DateConflict_Details, {
        title: TextService.formatTitle(item, plan.items),
        properties: TextService.formatChanges(item.kind, changes)
      }),
      kind: item.kind,
      guid: Commands.getClickableGuid(item),
      group: 'Planner_DateConflict',
      actions: [
        this.makeUsePlannerDatesAction(item, changes, notify),
        this.makeFixPlannerDatesAction(item, changes, notify),
      ]
    });
  }

  public static makeUsePlannerDatesAction(item: VistoActionItem, changes: IChanges<VistoActionItem>, notify: INotify) {
    return {
      title: TextService.format(strings.Planner_DateConflict_UsePlannerTitle),
      command: {
        prepare: async () => {
          return {
            do: async (p: IVistoPlan) => {
              return await StorageService.get(p.siteUrl).updateItems(p, [{ item, changes }], notify)
            },
            undo: async (p: IVistoPlan) => {
              return await StorageService.get(p.siteUrl).updateItems(p, [{ item, changes }], notify, { reverse: true })
            },
          };
        },
        message: TextService.format(strings.Planner_DateConflict_UsePlannerMessage),
        name: CommandName.CreateItem
      }
    };
  }

  public static makeFixPlannerDatesAction(item: VistoActionItem, changes: IChanges<VistoActionItem>, notify: INotify) {
    return {
      title: TextService.format(strings.Planner_DateConflict_OverwritePlannerTitle),
      command: {
        prepare: async () => {
          const parsed = PlannerLinkService.parsePlannerTaskLink(item.sourceItemUrl);
          const task = await PlannerService.getTask(parsed.taskId);
          const update: any = {};
          if (changes.properties.includes('startDate')) {
            update.startDateTime = PlannerDataService.unparseTaskDate(changes.oldValues.startDate);
          }
          if (changes.properties.includes('endDate')) {
            update.dueDateTime = PlannerDataService.unparseTaskDate(changes.oldValues.endDate);
          }
          const plannerChanges = ChangesService.getChanges(task, update);
          return {
            do: async (p: IVistoPlan) => {
              if (plannerChanges.detected) {
                await PlannerService.updateTask(parsed.taskId, plannerChanges.newValues);
              }
              return p;
            },
            undo: async (p: IVistoPlan) => {
              if (plannerChanges.detected) {
                await PlannerService.updateTask(parsed.taskId, plannerChanges.oldValues);
              }
              return p;
            },
          };
        },
        message: TextService.format(strings.Planner_DateConflict_OverwritePlannerMessage),
        name: CommandName.CreateItem
      }
    };
  }

}
