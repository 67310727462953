import * as React from 'react';
import { Stack, TextField, DatePicker, FontSizes, MessageBar, MessageBarType, Link, Toggle, Checkbox } from '@fluentui/react';

import { IVistoListItem, IVistoPlan, VistoActionItem, VistoFocusItem } from 'sp';
import { BasicDialog, ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';

import { AppContext } from 'services/AppContext';
import { ProgressService } from 'services/ProgressService';
import { IntegrationService } from 'services/IntegrationService';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { StorageService } from 'services/StorageService';
import { FocusPicker } from './common/FocusPicker';
import { LopPicker } from './common/LopPicker';
import { DpPicker } from './common/DpPicker';
import { PlanDataService } from 'services/PlanDataService';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';

export function EditMultipleActionsDialog(props: {
  plan: IVistoPlan;
  items: VistoActionItem[];
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('EditMultipleActionsDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldActions = props.items;
  const [newActions, setNewActions] = React.useState<VistoActionItem[]>(oldActions.map(a => ({ ...a })));

  const useFocusDates =
    newActions.every(a => a.useFocusDates === true) ? true
      : newActions.every(a => a.useFocusDates === false) ? false
        : null;

  const onToggleUseFocusDates = (_, val) => {
    const newActions = [];
    for (const oldAction of oldActions) {
      if (val) {
        const focus = PlanDataService.getItemByGuid(props.plan.items, oldAction.focusGuid) as VistoFocusItem;
        newActions.push({
          ...oldAction,
          startDate: focus?.startDate ?? null,
          endDate: focus?.endDate ?? null,
          useFocusDates: true
        });
      } else {
        newActions.push({
          ...oldAction,
          startDate: oldAction.startDate ?? null,
          endDate: oldAction.endDate ?? null,
          useFocusDates: false
        });
      }
    }
    setNewActions(newActions);
  };

  const onSave = async () => {
    const updates = [];
    for (let i = 0; i < props.items.length; i++) {
      const changes = ChangesService.getChanges(oldActions[i], newActions[i]);
      if (changes.detected) {
        updates.push({ item: props.items[i], changes });
      }
    }
    if (updates.length > 0) {
      await dispatchCommand(Commands.makeUpdateCommand(updates, notify, { enableSimpleUpdate: true }), { wrap: false });
    }
  }

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={onSave}
      buttonOkText={TextService.format(strings.ButtonSave)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonDiscard)}
      title={TextService.format(strings.EditMultipleActionsDialog_Title)}
    >
      <Stack tokens={{ childrenGap: 's1' }}>
        <TitleBlock
          edit={true}
          fontSize={FontSizes.xLarge}
          name={TextService.format(strings.EditMultipleActionsDialog_Details, { count: props.items.length })}
          description={null}
          nameDisabled={true}
          descriptionDisabled={true}
          assignedTo={[]}
          assignedToDisabled={true}
        />
        {/* {isLinked &&
            <MessageBar messageBarType={MessageBarType.info} >
              {TextService.format(strings.EditActionDialog_LinkedItemLabel)} <Link target={newAction.guid} href={IntegrationService.getBrowserLink(newAction.sourceItemUrl)}>{TextService.format(strings.EditActionDialog_LinkedItemLabelLink)}</Link>
            </MessageBar>
          } */}
        {/* <FocusPicker
            plan={props.plan}
            disabled={!isPlanEditEnabled}
            label={TextService.format(strings.EditActionDialog_FocusLabel)}
            focusGuid={newAction.focusGuid}
            setFocusGuid={onSetFocusGuid}
          /> */}
        {/* <Stack horizontal tokens={{ childrenGap: 'm' }}>
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newAction.startDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              label={TextService.format(strings.EditActionDialog_StartDateLabel)}
              disabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'startDate') || newAction.useFocusDates}
              textField={{ errorMessage: startDateErrorMessage }}
              value={newAction.startDate} allowTextInput={true}
              onSelectDate={val => setNewAction({ ...newAction, startDate: val })}
            />
            <DatePicker
              today={props.plan.statusDate}
              initialPickerDate={newAction.endDate || props.plan.statusDate}
              strings={TextService.datePickerStrings}
              firstDayOfWeek={TextService.firstDayOfWeek}
              formatDate={d => TextService.formatDate(d)}
              disabled={isEditDisabled || !ProgressService.allowEdit(newAction, 'endDate') || newAction.useFocusDates}
              textField={{ errorMessage: endDateErrorMessage }}
              label={TextService.format(strings.EditActionDialog_EndDateLabel)}
              value={newAction.endDate}
              allowTextInput={true}
              onSelectDate={val => setNewAction({ ...newAction, endDate: val })}
            />
            <TextField
              disabled={isLinked || isEditDisabled || !ProgressService.allowEdit(newAction, 'percentComplete')}
              type='number'
              min={0}
              max={100}
              label={TextService.format(strings.EditActionDialog_PercentCompleteLabel)}
              value={TextService.formatPercents(newAction.percentComplete)}
              onChange={(_, val) => setNewAction({ ...newAction, percentComplete: val !== '' && TextService.isValidNumber(+val) ? +val : null })}
              errorMessage={percentCompleteErrorMessage}
            />
          </Stack> */}

        <Checkbox
          label={TextService.format(strings.EditActionDialog_UseFocusDatesLabel)}
          indeterminate={useFocusDates === null}
          checked={useFocusDates === true}
          disabled={!isPlanEditEnabled}
          onChange={onToggleUseFocusDates}
        />

        {/* <Stack horizontal tokens={{ childrenGap: 'm' }}>
            <Stack.Item style={{ flex: 1 }}>
              <LopPicker
                plan={props.plan}
                disabled={!isPlanEditEnabled}
                label={TextService.format(strings.__Capability)}
                lopGuid={newAction.lopGuid}
                setLopGuid={(lopGuid) => setNewAction({ ...newAction, lopGuid, dpGuid: null })}
              />
            </Stack.Item>
            <Stack.Item style={{ flex: 1 }}>
              <DpPicker
                plan={props.plan}
                disabled={!isPlanEditEnabled}
                label={TextService.format(strings.__Ambition)}
                lopGuid={newAction.lopGuid}
                dpGuid={newAction.dpGuid}
                setDpGuid={dpGuid => setNewAction({ ...newAction, dpGuid })}
              />
            </Stack.Item>
          </Stack> */}
      </Stack>
    </BasicDialog>);
}
