import { 
  IConnectParentChildRequest, IConnectParentChildResponse,
  IDashboardPlansRequest, IDashboardPlansResponse,
  IDisconnectParentChildRequest, IDisconnectParentChildResponse,
  IMarkPlanDeletedRequest, IMarkPlanDeletedResponse,
  IMarkPlanPinnedRequest, IMarkPlanPinnedResponse,
  ISetPlanChildrenRequest, ISetPlanChildrenResponse
} from 'shared/api/dashboard';

import { StorageCacheService } from '../StorageCacheService';
import { ApiService } from './ApiService';
import { UserInfoService } from 'services/UserInfoService';
import { LicenseService } from 'services/LicenseService';

export class ApiDashboardService {

  public static resetCache() {
    StorageCacheService.resetCache('https://dashboard');
  }

  public static async getRelatedPlans(tid: string, userObjectId: string) {

    const cacheLicenseKey = LicenseService.getTenantLicenseCacheKey(tid);
    
    if (!LicenseService.license) {
      const cachedLicense = LicenseService.getCachedLicense(cacheLicenseKey);
      if (cachedLicense) {
        LicenseService.setLicense(cachedLicense);
      }
    }

    const info = await UserInfoService.getUserMembershipInfo(userObjectId);
    const groupIds = info.groups;
    const channelIds = info.teams.flatMap(t => t.channels.map(c => c.id));
    const organization = await UserInfoService.getOrganizationInfo(userObjectId, tid);
    const domain = organization?.verifiedDomains.find(d => d.isDefault);
    const url = domain?.name || '';

    const req: IDashboardPlansRequest = { groupIds, channelIds, organization: { tid, url } };
    const cacheKey = `https://dashboard.${tid}.${userObjectId}`;
    const result = await ApiService.fetch<IDashboardPlansResponse>(`/plan/related`, 'post', req, cacheKey);

    if (result.license) {
      LicenseService.setCachedLicense(cacheLicenseKey, result.license);
      LicenseService.setLicense(result.license);
    }

    return result.plans;
  }

  public static async markPlanDeleted(planRef: string, channelRef: string) {
    const req: IMarkPlanDeletedRequest = { planRef, channelRef };
    const result = await ApiService.fetch<IMarkPlanDeletedResponse>(`/plan/markDeleted`, 'post', req);
    this.resetCache();
    return result.deleted;
  }

  public static async markPlanPinned(planRef: string, channelRef: string, pinned: boolean) {
    const req: IMarkPlanPinnedRequest = { planRef, channelRef, pinned };
    const result = await ApiService.fetch<IMarkPlanPinnedResponse>(`/plan/markPinned`, 'post', req);
    this.resetCache();
    return result.pinned;
  }

  public static async setPlanChildren(parentPlanSiteUrl: string, parentPlanId: string, childRefs: string[]) {
    const req: ISetPlanChildrenRequest = { parentPlanSiteUrl, parentPlanId, childRefs };
    const result = await ApiService.fetch<ISetPlanChildrenResponse>(`/plan/setPlanChildren`, 'post', req);
    this.resetCache();
    return result.succeeded;
  }

  public static async connectParentChild(parentPlanSiteUrl: string, parentPlanId: string, childRef: string) {
    const req: IConnectParentChildRequest = { parentPlanSiteUrl, parentPlanId, childRef };
    const result = await ApiService.fetch<IConnectParentChildResponse>(`/plan/connectParentChild`, 'post', req);
    this.resetCache();
    return result.succeeded;
  }

  public static async disconnectParentChild(parentPlanSiteUrl: string, parentPlanId: string, childRef: string) {
    const req: IDisconnectParentChildRequest = { parentPlanSiteUrl, parentPlanId, childRef };
    const result = await ApiService.fetch<IDisconnectParentChildResponse>(`/plan/disconnectParentChild`, 'post', req);
    this.resetCache();
    return result.succeeded;
  }
}
