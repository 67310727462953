import * as React from 'react';
import { MessageBar, MessageBarType, Stack, Text, TextField, Toggle } from '@fluentui/react';

import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { VistoAssocItem, IVistoPlan, VistoActionItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { KrPicker } from './common/KrPicker';
import { ActionPicker } from './common/ActionPicker';
import { SoPicker } from './common/SoPicker';
import { ConfidencePicker } from './common/ConfidencePicker';

import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { LicenseService } from 'services/LicenseService';
import { PlanSettingsService } from 'services/PlanSettingsService';

export function EditAssocDialog(props: {
  plan: IVistoPlan,
  assoc: VistoAssocItem,
  disableSo?: boolean;
  disableAction?: boolean;
  onDismiss: (changed: boolean) => void
}) {

  React.useEffect(() => trackClient.page('EditAssocDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldAssoc = props.assoc;

  const action = PlanDataService.getItemByGuid<VistoActionItem>(props.plan.items, oldAssoc.actionGuid);

  const dpGuid = action?.dpGuid;

  const [newAssoc, setNewAssoc] = React.useState<VistoAssocItem>({ ...oldAssoc });

  const errorMessage = PlanDataService.isDuplicateAssoc(props.plan.items, newAssoc)
    && TextService.format(strings.Sidebar_AssocDuplicate);

  const validationIssues =
    !!errorMessage || !newAssoc.soGuid || !newAssoc.confidence || !newAssoc.actionGuid;

  const setActionGuid = (actionGuid: string) => {
    setNewAssoc({ ...newAssoc, actionGuid });
  }

  const setKrGuid = (val: string) => {
    setNewAssoc({ ...newAssoc, krGuid: val });
  }

  const setSoGuid = (soGuid: string) => {
    setNewAssoc({ ...newAssoc, soGuid, krGuid: null });
  }

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);
  const indicatorsHidden = !planSettings?.showKpiIndicators;

  return <ItemEditDialog
    onDismiss={props.onDismiss}
    onLoaded={loaded => setNewAssoc({ ...newAssoc, ...loaded })}
    plan={props.plan}
    oldItem={oldAssoc}
    newItem={newAssoc}
    onCommit={() => dispatchCommand(Commands.makeSaveCommand(oldAssoc, newAssoc, notify), { wrap: false })}
    validationIssues={validationIssues}
  >
    <Stack>
      {errorMessage && <MessageBar messageBarType={MessageBarType.warning} >{errorMessage}</MessageBar>}

      <ConfidencePicker
        disabled={!isPlanEditEnabled}
        label={TextService.format(strings.EditAssocDialog_ConfidenceSelectorLabel)}
        confidence={newAssoc.confidence}
        setConfidence={confidence => setNewAssoc({ ...newAssoc, confidence })}
      />

      <TextField
        autoFocus
        disabled={!isPlanEditEnabled}
        autoComplete='off'
        label={TextService.format(strings.EditAssocDialog_WhyLabel)}
        multiline
        rows={5}
        value={newAssoc.description}
        onChange={(evt, val) => setNewAssoc({ ...newAssoc, description: val })}
      />

      <SoPicker
        plan={props.plan}
        disabled={!isPlanEditEnabled || props.disableSo}
        label={TextService.format(strings.EditAssocDialog_StrategicObjectiveSelectorLabel)}
        soGuid={newAssoc.soGuid}
        setSoGuid={setSoGuid}
      />

      {LicenseService.license?.okrEnabled &&
        <KrPicker
          plan={props.plan}
          disabled={!isPlanEditEnabled || props.disableSo}
          label={TextService.format(strings.EditAssocDialog_KeyResultSelectorLabel)}
          soGuid={newAssoc.soGuid}
          krGuid={newAssoc.krGuid}
          setKrGuid={setKrGuid}
        />}

      <ActionPicker
        plan={props.plan}
        disabled={!isPlanEditEnabled || props.disableAction}
        label={TextService.format(strings.EditAssocDialog_ActionSelectorLabel)}
        dpGuid={dpGuid}
        actionGuid={newAssoc.actionGuid}
        setActionGuid={setActionGuid}
      />

      <Toggle
        inlineLabel
        disabled={!isPlanEditEnabled}
        label={TextService.format(strings.EditAssocDialog_ShowOnDiagram_Label)}
        checked={newAssoc.showOnDiagram}
        onChange={(_, val) => setNewAssoc({ ...newAssoc, showOnDiagram: val })}
        onText={TextService.format(strings.Toggle_On)}
        offText={TextService.format(strings.Toggle_Off)}
      />
      {indicatorsHidden && <Text variant='small' >{TextService.format(strings.Message_Warning_IndicatorsHidden)}</Text>}
    </Stack>
  </ItemEditDialog>;

}
