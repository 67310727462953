import { IPlanOpenedRequest, IPlanOpenedResponse } from 'shared/api/plan';

import { trackClient } from 'shared/clientTelemetry';
import { IEnvContext } from '../EnvContext';
import { ApiService } from './ApiService';

export class ApiPlanService {

  public static async openPlan(envContext: IEnvContext, planId: string) {

    const req: IPlanOpenedRequest = {
      plan_id: planId,
      group_id: envContext.groupId,
      site_url: envContext.siteUrl,
      channel_id: envContext.channelId,
    };

    const result = await ApiService.fetch<IPlanOpenedResponse>(`/plan/open`, 'post', req);
    return result;
  }

}
