import { trackClient } from 'shared/clientTelemetry';
import { TextService } from './TextService';
import { TokenKind } from 'shared/TokenKind';
import { clearInfoBar, IBasicNotify, NotificationType, notifyInfoBar } from './Notify';
import { isConsentError } from 'shared/parse';
import strings from 'VistoWebPartStrings';
import '@pnp/graph/users';
import '@pnp/graph/groups';
import '@pnp/graph/photos';
import '@pnp/graph/teams';
import { AuthService } from './AuthService';
import { CachingType } from './CachingType';
import { User } from '@microsoft/microsoft-graph-types';
import { IFieldValueUser } from 'sp';
import { CacheKey } from '@pnp/queryable';
import { graphGet, GraphQueryable } from '@pnp/graph';
import { CopyFrom } from '@pnp/core';

export interface IMembershipInfo {
  groups: string[];
  teams: {
    id: string;
    displayName: string;
    channels: { id: string; displayName: string }[];
  }[];
}

interface IGraphOrganizationInfo {
  id: string;
  displayName: string;
  verifiedDomains: {
    isDefault: boolean;
    name: string;
  }[];
}

export class UserInfoService {

  public static getClient(oid: string, caching?: CachingType) {
    return AuthService.getGraphClient(TokenKind.dashboard, oid, caching);
  }

  public static async configure(tid: string, notify?: IBasicNotify) {

    const callback = async () => {
      await AuthService.getAuthToken(TokenKind.dashboard, tid);
    };

    try {

      await callback();

    } catch (err) {

      if (isConsentError(err)) {
        this.makeConsentNotification(callback, notify);
      } else {
        trackClient.warn('Unable to configure user info service', err);
      }
    }
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify) {

    AuthService.resetAuth(TokenKind.dashboard);

    notifyInfoBar(notify, {
      message: TextService.format(strings.GraphNotification_AuthorizationRequired),
      group: 'Dashboard_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.GraphNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.GraphNotification_AuthorizeButton),
          action: async () => {
            try {
              await AuthService.getConsent(TokenKind.dashboard, '', callback);
              clearInfoBar(notify, 'Dashboard_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.GraphNotification_ConsentGrant), group: 'Dashboard_Consent' });
            } catch (error) {
              const message = TextService.format(strings.GraphNotification_AuthorizationRequiredErrorDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Dashboard_Consent' });
            }
          }
        }
      ]
    });
  }

  public static async getUserMembershipInfo(userObjectId: string): Promise<IMembershipInfo> {
    const graph = this.getClient(userObjectId, 'long');
    const groups = await graph.me.memberOf.using(CacheKey(`memberOf.${userObjectId}`)).select('id')();

    const teamsList = await graph.me.joinedTeams.using(CacheKey(`joinedTeams.${userObjectId}`)).select('id', 'displayName')();
    const teams = await Promise.all(teamsList.map(async (team) => {
      const channels = await graph.teams.getById(team.id).channels.using(CacheKey(`channels.${team.id}`)).select('id', 'displayName')();
      return {
        id: team.id,
        displayName: team.displayName,
        channels: channels.map((c: any) => ({ id: c.id, displayName: c.displayName }))
      }
    }));

    return {
      groups: groups.map(g => g.id),
      teams: teams
    }
  }

  public static encodeUserObjectId(rawUserObjectId: string) {
    return rawUserObjectId.replace(/#/g, '%23');
  }

  public static async getUserInfo(rawUserObjectId: string): Promise<User> {
    if (rawUserObjectId) {
      const userObjectId = this.encodeUserObjectId(rawUserObjectId);
      const graph = this.getClient(userObjectId, 'long');
      const userInfo = await graph.users.getById(userObjectId)();
      return userInfo;
    }
  }

  public static async getOrganizationInfo(userObjectId: string, tid: string): Promise<IGraphOrganizationInfo> {
    const graph = this.getClient(userObjectId, 'long');
    const queryable = GraphQueryable(graph.me.toUrl().replace('/me', ''), '/organization')
      .using(CopyFrom(graph.me))
      .using(CacheKey(`organization.${tid}`));
    const organizations = await graphGet(queryable) as IGraphOrganizationInfo[];
    return organizations.find(o => o.id === tid);
  }
}
