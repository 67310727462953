import { StorageService } from './StorageService';
import { ITheme } from '@fluentui/react';
import { ApiDashboardService } from './api/ApiDashboardService';
import { trackClient } from 'shared/clientTelemetry';
import { TextService } from 'services/TextService';
import { ImageCacheService } from './ImageCacheService';
import { getObjectValues, isConsentError, stringifyError } from 'shared/parse';
import { IMembershipInfo, UserInfoService } from './UserInfoService';
import strings from 'VistoWebPartStrings';
import { LocalStorageService } from './LocalStorageService';
import { ICardInfo, ICardInfoUser } from './ICardInfo';
import { UrlService } from 'shared/urlService';
import { IDashboardPlanItem } from 'shared/api/dashboard';
import { PlannerConfigurationService, PlannerService } from 'integrations/planner';
import { DevOpsDataService, DevOpsService } from 'integrations/devops';
import { IBasicNotify } from './Notify';
import { PlannerNotifications } from 'integrations/planner/services/PlannerNotifications';
import { ProjectDataService } from 'integrations/project';
import { UserInfoPhotoService } from './UserInfoPhotoService';

const PREVIEW_EXPIRATION_MINUTES = 60;

interface IPlanNameInfo {
  name: string;
  missing: boolean;
  error: any;
}

export class DashboardDataService {

  private static makePlanCardUrl(p: IDashboardPlanItem, isTeams: boolean) {
    if (isTeams) {
      return p.ChannelRef ? UrlService.makePlanDeepLink(p.PlanRef, p.ChannelRef, null) : null;
    } else if (UrlService.isLocalUrl(p.SiteUrl)) {
      return `/local/${p.PlanRef}`;
    } else {
      return `/teams/${p.PlanRef}?siteUrl=${encodeURIComponent(p.SiteUrl)}`;
    }
  };

  private static makeLocationUrl(p: IDashboardPlanItem, isTeams: boolean) {
    if (isTeams) {
      return p.ChannelRef ? UrlService.makeChannelDeepLink(p.ChannelRef) : p.SiteUrl;
    } else {
      return UrlService.isLocalUrl(p.SiteUrl) ? '#' : p.SiteUrl;
    }
  }

  private static makeLocationName(p: IDashboardPlanItem, info: IMembershipInfo) {

    if (p.ChannelRef) {
      for (const team of info.teams) {
        const channel = team.channels.find(c => c.id === p.ChannelRef);
        if (channel) {
          return `${team.displayName}/${channel.displayName}`;
        }
      }
      return p.ChannelRef;
    } else if (p.GroupId) {
      for (const team of info.teams) {
        if (team.id === p.GroupId) {
          return `${team.displayName}/No Channel`;
        }
      }
      return `${p.GroupId}/No Channel`;
    } else {
      return UrlService.getPathName(p.SiteUrl);
    }
  }

  static async getCardsFromPlanItems(userObjectId: string, loadedPlans: IDashboardPlanItem[], isTeams: boolean) {

    const membershipInfo = userObjectId && await UserInfoService.getUserMembershipInfo(userObjectId);

    const result = loadedPlans.map(p => {

      const ci: ICardInfo = {
        key: TextService.getPlanDashboardKey(p.SiteUrl, p.PlanRef),
        planId: p.PlanRef,
        channelId: p.ChannelRef,
        siteUrl: p.SiteUrl,
        location: membershipInfo ? this.makeLocationName(p, membershipInfo) : UrlService.getPathName(p.SiteUrl),
        locationUrl: this.makeLocationUrl(p, isTeams),
        created: p.CreatedDate,
        lastAccessed: p.LastAccessedDate,
        lastAccessedByMe: p.LastAccessedByMeDate,
        lastModified: p.LastModifiedDate,
        lastModifiedByMe: p.LastModifiedByMeDate,
        pinned: p.Pinned,
        name: null,
        imageUrl: null,
        missing: null,
        error: null,
        url: this.makePlanCardUrl(p, isTeams),
        users: p.Users.map(u => ({
          userObjectId: u.UserObjectId,
          lastAccessed: u.LastAccessedDate,
          lastModified: u.LastModifiedDate,
          siteUrl: p.SiteUrl,
        })) || [],
        children: p.Children?.map(c => ({
          childRef: c.ChildRef,
          fixed: c.Fixed,
        })) || [],
      };
      return ci;
    });

    const plannerResults = DashboardDataService.loadPlansPlanner(result);
    const devopsResults = DashboardDataService.loadPlansDevOps(result);
    const projectResults = DashboardDataService.loadPlansProject(result);

    result.push(...plannerResults, ...devopsResults, ...projectResults);
    return result;
  }

  private static groupCardsBySite(newCardInfos: ICardInfo[], prefix: string) {
    return newCardInfos
      .filter(c => c.key.startsWith(prefix))
      .reduce((r, v) => {
        if (r[v.siteUrl]) {
          r[v.siteUrl][v.planId] = v;
        } else {
          r[v.siteUrl] = { [v.planId]: v };
        }
        return r;
      }, {});
  };

  public static async loadPlanUserPhotos(newCardInfos: ICardInfo[], notify: IBasicNotify) {
    const userObjectIds: { [key: string]: ICardInfoUser[] } = {};
    for (let i = 0; i < newCardInfos.length; ++i) {
      const ci = newCardInfos[i];
      for (const u of ci.users) {
        if (userObjectIds[u.userObjectId]) {
          userObjectIds[u.userObjectId].push(u);
        } else {
          userObjectIds[u.userObjectId] = [u];
        }
      }
    }

    for (const userObjectId in userObjectIds) {
      const callback = async () => {
        const [userInfo, url] = await Promise.all([
          UserInfoService.getUserInfo(userObjectId),
          UserInfoPhotoService.getUserPhotoUrl(userObjectId)
        ]);
        for (const u of userObjectIds[userObjectId]) {
          u.name = userInfo.displayName;
          u.email = userInfo.userPrincipalName;
          u.profileImageSrc = url
        }
      }
      try {
        await callback();
      } catch (e) {
        if (isConsentError(e)) {
          UserInfoService.makeConsentNotification(callback, notify);
        }
        trackClient.warn(`Failed to load user photo for ${userObjectId}`, e);
      }
    }
  }

  private static async loadPlanNames(newCardInfos: ICardInfo[], theme: ITheme, notify: IBasicNotify) {

    const siteGroups = this.groupCardsBySite(newCardInfos, 'visplan:');

    await Promise.all(Object.keys(siteGroups).map(async siteUrl => {
      const siteCards = siteGroups[siteUrl];

      try {
        await DashboardDataService.getPlanNames(siteUrl, siteCards, notify);
      } catch (e) {
        trackClient.warn(`Error loading plan names for site ${siteUrl}`, e);
      }

      try {
        await DashboardDataService.getPlanPreviewInfo(siteUrl, siteCards, theme);
      } catch (e) {
        trackClient.warn(`Error loading plan preview info for site ${siteUrl}`, e);
      }
    }));
  }

  private static async loadPlanNamesPlanner(newCardInfos: ICardInfo[], notify: IBasicNotify) {

    const siteGroups = this.groupCardsBySite(newCardInfos, 'planner:');
    await Promise.all(Object.keys(siteGroups).map(async groupId => {
      const callback = async () => {
        const siteCards = siteGroups[groupId];
        const plans = await PlannerService.getPlans(groupId);
        for (const plan of plans) {
          const found = siteCards[plan.id];
          if (found) {
            found.name = plan.title;
            found.url = `https://tasks.office.com/${PlannerConfigurationService.tid}/Home/PlanViews/${plan.id}`
          }
        }
      };
      try {
        await callback();
      } catch (e) {
        trackClient.warn(`Error loading plans for group ${groupId}`, e);
        if (isConsentError(e)) {
          PlannerNotifications.makeConsentNotification(callback, notify);
        }
      }
    }));
  }

  private static async loadPlanNamesDevOps(newCardInfos: ICardInfo[], notify: IBasicNotify) {
    const siteGroups = this.groupCardsBySite(newCardInfos, 'devops:');
    await Promise.all(Object.keys(siteGroups).map(async devopsUrl => {
      const siteCards = siteGroups[devopsUrl];
      const callback = async () => {
        const projects = await DevOpsService.getProjects(devopsUrl);
        for (const project of projects) {
          const found = siteCards[project.id];
          if (found) {
            found.name = project.name;
            found.url = `${devopsUrl}/${project.name}`;
          }
        }
      }
      try {
        await callback();
      } catch (e) {
        if (isConsentError(e)) {
          DevOpsDataService.makeConsentNotification(callback, notify);
        }
        trackClient.warn(`Error loading projects for ${devopsUrl}`, e);
      }
    }));
  }

  private static async loadPlanNamesProject(newCardInfos: ICardInfo[], notify: IBasicNotify) {
    const siteGroups = this.groupCardsBySite(newCardInfos, 'project:');
    await Promise.all(Object.keys(siteGroups).map(async pwaUrl => {
      const siteCards = siteGroups[pwaUrl];
      const callback = async () => {
        const projects = await ProjectDataService.getProjects(pwaUrl);
        for (const project of projects) {
          const found = siteCards[project.Id];
          if (found) {
            found.name = project.Name;
            found.url = ProjectDataService.getBrowserLink(pwaUrl, project.Id);
          }
        }
      }
      try {
        await callback();
      } catch (e) {
        if (isConsentError(e)) {
          ProjectDataService.makeConsentNotification(pwaUrl, callback, notify);
        }
        trackClient.warn(`Error loading projects for ${pwaUrl}`, e);
      }
    }));
  }

  public static async loadAllCardDetails(newCardInfos: ICardInfo[], theme: ITheme, notify: IBasicNotify) {
    await Promise.all([
      this.loadPlanNames(newCardInfos, theme, notify),
      this.loadPlanNamesPlanner(newCardInfos, notify),
      this.loadPlanNamesDevOps(newCardInfos, notify),
      this.loadPlanNamesProject(newCardInfos, notify),
    ]);
  }

  public static async getPlanNames(siteUrl: string, results: { [key: string]: IPlanNameInfo }, notify: IBasicNotify) {
    
    const planIds = Object.keys(results);

    const callback = async () => {

      const planItems = await StorageService.get(siteUrl).getPlanItems(siteUrl, ['name', 'planId'], 'short', planIds);
      
      for (const planId of planIds) {
        const found = planItems.find(p => p.planId === planId);
        const result = results[planId];
        if (found) {
          result.name = found.name;
        } else {
          result.name = TextService.format(strings.Dashboard_MissingPlanTitle);
          result.missing = true;
        }
      }
    }
    try {
      if (planIds.length) {
        await callback();
      }
    } catch (error) {
      if (isConsentError(error)) {
        StorageService.get(siteUrl).makeConsentNotification(callback, siteUrl, notify);
      } else {
        trackClient.warn(`Unable to list plans for site ${siteUrl}`, error);
        for (const planId of planIds) {
          const result = results[planId];
          result.name = TextService.format(strings.Dashboard_UnaccessiblePlanTitle);
          result.error = error;
          result.missing = true;
        }
      }
    }
  }

  public static async dataUrlToPng(dataURI: string): Promise<Blob> {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // create a view into the buffer
    var ia = new Uint8Array(byteString.length);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ia], {type: mimeString});
    return blob;
  }

  public static async pngToDataUrl(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  public static async getPlanCardPreview(drawingXml: string, styleJson: string, theme: ITheme): Promise<Blob> {
    const imagePng = await ImageCacheService.getPreviewPng({
      embedImages: true,
      drawingXml: drawingXml,
      styleJson: styleJson,
      theme: theme,
      width: 250,
      height: 150
    });
    return new Blob([imagePng], { type: 'image/png' });
  }

  public static async updatePlanPreview(planItem: { siteUrl: string, planId: string }, png: Blob) {
    const dataUrl = await this.pngToDataUrl(png);
    const result = await StorageService.get(planItem.siteUrl).updatePlanItem(planItem, { previewPng: dataUrl }, null);

    const previewUrl = this.makePreviewUrl(planItem.siteUrl, planItem.planId);
    await ImageCacheService.deleteCachedImage(previewUrl);
    
    return result;
  }

  public static makePreviewUrl = (siteUrl: string, planId: string) =>
    `visplan://image-dashboard-thumbnail/${siteUrl}##${planId}`;

  public static async getPlanPreviewInfo(siteUrl: string, results: { [key: string]: ICardInfo }, theme: ITheme) {
    const planIds = Object.keys(results);
    await Promise.all(planIds.map(async (planId) => {
      results[planId].imageUrl = await ImageCacheService.getCachedImageUrlAsync(this.makePreviewUrl(siteUrl, planId));
    }));

    const planIdsWithoutPreviews = planIds.filter(planId => !results[planId].imageUrl);
    if (planIdsWithoutPreviews.length) {
      const planItemsWithData = await StorageService.get(siteUrl).getPlanItems(siteUrl, ['planId', 'previewPng', 'drawingXml', 'styleJson'], undefined, planIdsWithoutPreviews);
      for (const planItem of planItemsWithData) {
        const result = results[planItem.planId];
        if (result) {
          result.imageUrl = await ImageCacheService.getImageAsync(
            this.makePreviewUrl(siteUrl, planItem.planId),
            async () => {
              if (planItem.previewPng) {
                const b = await this.dataUrlToPng(planItem.previewPng);
                return b;
              } else {
                const b = await this.getPlanCardPreview(planItem.drawingXml, planItem.styleJson, theme);
                const previewUrl = this.makePreviewUrl(planItem.siteUrl, planItem.planId);
                await ImageCacheService.deleteCachedImage(previewUrl);
                this.updatePlanPreview(planItem, b);
                return b;
              }
            },
            PREVIEW_EXPIRATION_MINUTES);
        }
      }
    }
  }

  private static async getLocalDashboardItems(userObjectId: string): Promise<IDashboardPlanItem[]> {
    const localPlans = await LocalStorageService.listPlans();
    return localPlans.map(p => ({
      TeamRef: '',
      TeamName: TextService.format(strings.DashboardService_DefaultTeam),
      ChannelRef: '',
      ChannelName: TextService.format(strings.DashboardService_DefaultChannel),
      SiteUrl: UrlService.LOCAL_URL,
      PlanRef: p.planId,
      CreatedDate: p.createdDate,
      LastAccessedDate: p.modifiedDate,
      LastAccessedByMeDate: p.modifiedDate,
      LastModifiedDate: p.modifiedDate,
      LastModifiedByMeDate: p.modifiedDate,
      Pinned: false,
      GroupId: null,
      Users: [{
        UserObjectId: userObjectId,
        LastModifiedDate: p.modifiedDate,
        LastAccessedDate: p.modifiedDate
      }],
      Children: [],
    }));
  }

  public static async getRelatedPlans(tid: string, userObjectId: string) {
    const result: IDashboardPlanItem[] = [];

    if (userObjectId) {
      const dbItems = await ApiDashboardService.getRelatedPlans(tid, userObjectId);
      result.push(...dbItems);
    }

    const localItems = await this.getLocalDashboardItems(userObjectId);
    result.push(...localItems);

    return result;
  }

  public static loadPlansPlanner(loadedCardInfos: ICardInfo[]): ICardInfo[] {
    const plannerPlans: { [key: string]: ICardInfo } = {}
    for (const loadedCardInfo of loadedCardInfos) {
      for (const child of loadedCardInfo.children) {
        const re = /planner:(.+)##(.+)/;
        const match = child.childRef.match(re);
        if (match) {
          const groupId = match[1];
          const planId = match[2];
          plannerPlans[child.childRef] = {
            key: child.childRef,
            name: TextService.format(strings.Dashboard_UnaccessiblePlanTitle),
            location: 'planner',
            locationUrl: `https://tasks.office.com/`,
            siteUrl: groupId,
            planId: planId,
            channelId: null,
            created: null,
            lastAccessed: null,
            lastAccessedByMe: null,
            lastModified: null,
            lastModifiedByMe: null,
            pinned: false,
            imageUrl: require('static/assets/links/planner.svg'),
            missing: null,
            error: null,
            url: null,
            users: [],
            children: [],
          };
        }
      }
    }
    return getObjectValues(plannerPlans);
  }

  public static loadPlansDevOps(loadedCardInfos: ICardInfo[]): ICardInfo[] {
    const plannerPlans: { [key: string]: ICardInfo } = {}
    for (const loadedCardInfo of loadedCardInfos) {
      for (const child of loadedCardInfo.children) {
        const re = /devops:(.+)##(.+)/;
        const match = child.childRef.match(re);
        if (match) {
          const devopsUrl = match[1];
          const projectId = match[2];
          plannerPlans[child.childRef] = {
            key: child.childRef,
            name: TextService.format(strings.Dashboard_UnaccessiblePlanTitle),
            location: 'devops',
            locationUrl: `https://dev.azure.com/`,
            siteUrl: devopsUrl,
            planId: projectId,
            channelId: null,
            created: null,
            lastAccessed: null,
            lastAccessedByMe: null,
            lastModified: null,
            lastModifiedByMe: null,
            pinned: false,
            imageUrl: require('static/assets/links/devops.svg'),
            missing: null,
            error: null,
            url: null,
            users: [],
            children: [],
          };
        }
      }
    }
    return getObjectValues(plannerPlans);
  }

  public static loadPlansProject(loadedCardInfos: ICardInfo[]): ICardInfo[] {
    const projectPlans: { [key: string]: ICardInfo } = {}
    for (const loadedCardInfo of loadedCardInfos) {
      for (const child of loadedCardInfo.children) {
        const re = /project:(.+)##(.+)/;
        const match = child.childRef.match(re);
        if (match) {
          const pwaUrl = match[1];
          const projectId = match[2];
          projectPlans[child.childRef] = {
            key: child.childRef,
            name: TextService.format(strings.Dashboard_UnaccessiblePlanTitle),
            location: 'project',
            locationUrl: `https://project.microsoft.com/`,
            siteUrl: pwaUrl,
            planId: projectId,
            channelId: null,
            created: null,
            lastAccessed: null,
            lastAccessedByMe: null,
            lastModified: null,
            lastModifiedByMe: null,
            pinned: false,
            imageUrl: require('static/assets/links/project.svg'),
            missing: null,
            error: null,
            url: null,
            users: [],
            children: [],
          };
        }
      }
    }
    return getObjectValues(projectPlans);
  }
}
