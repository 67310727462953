import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { ActivityItem } from '@fluentui/react/lib/ActivityItem';
import { Text } from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import { IComment, ICommentCard } from '../IComment';
import { CommentText } from './CommentText';
import { isEmpty } from 'lodash';
import { Link, useTheme } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { UrlService } from 'shared/urlService';
import { PlanDataService } from 'services/PlanDataService';
import { AppContext } from 'services/AppContext';
import { CellKind } from 'shared/CellKind';
import { VistoKind } from 'sp';

export const CommentItem = (props: {
  card: ICommentCard;
}) => {
  if (isEmpty(props.card)) return null;

  const { planRef, setSelection } = React.useContext(AppContext);
  const { comment } = props.card;
  const { author, createdDate, text, mentions } = comment;

  const theme = useTheme();

  const activityDescription = useMemo((): ReactNode => {
    const _activity: JSX.Element[] = [];
    _activity.push(
      <Text variant={'smallPlus'} styles={{ root: { fontWeight: 700, color: theme.palette.neutralTertiary } }}>
        {author.name}
      </Text>
    );
    _activity.push(<CommentText text={text} mentions={mentions} />);
    return _activity;
  }, [mentions, text]);

  const [imageUrl, setImageUrl] = React.useState<string>(null);
  React.useEffect(() => {
    UserInfoPhotoService.getUserPhotoUrl(author.preferred_username).then(url => {
      if (url) {
        setImageUrl(url)
      }
    })
  }, [author.preferred_username]);

  const parsed = UrlService.parseSubEntityId(comment.subEntityId);
  const item = parsed?.guid && PlanDataService.getItemByGuid(planRef.current.items, parsed.guid);

  const onLinkClick = () => {
    setSelection(item
      ? parsed
      : {
        cellKind: CellKind.TITLE,
        cellId: CellKind[CellKind.TITLE],
        guid: null,
        kind: null,
      });
  };

  return (
    <>
      <Stack>
        <ActivityItem
          activityPersonas={[{ imageUrl }]}
          activityDescription={activityDescription}
          timeStamp={TextService.formatDateTime(createdDate)}
        />
        <Link onClick={onLinkClick} style={{ fontWeight: 700, fontSize: theme.fonts.smallPlus.fontSize }}>{item ? TextService.formatTitle(item, planRef.current.items) : TextService.getVistoKindName(VistoKind.Plan)}</Link>
      </Stack>
    </>
  );
};
