import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { CommentItem } from './CommentItem';
import { DocumentCard, DocumentCardDetails } from '@fluentui/react/lib/DocumentCard';
import { Stack } from '@fluentui/react/lib/Stack';
import { useCommentsStyles } from './useCommentsStyles';
import { IDialogContentStyles } from '@fluentui/react/lib/Dialog';
import strings from 'ControlStrings';
import { ICommentCard } from '../IComment';
import { TextService } from 'services/TextService';
import { ButtonCancel, ButtonOK } from 'VistoWebPartStrings';

export const ConfirmDelete = (props: {
  selectedComment: ICommentCard;
  onDismiss: (deleteComment: boolean) => void;
}) => {
  const { documentCardStyles, itemContainerStyles, } = useCommentsStyles();
  const { onDismiss, selectedComment: selectedCard } = props;
  const stylesSubText: Partial<IDialogContentStyles> = {
    subText: { fontWeight: 600 },
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };
  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: strings.CommentsDialogDeleteTitle,
    styles: stylesSubText,
    subText: strings.CommentDIalogDeleteSubText,
  };
  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={() => onDismiss(false)}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        {' '}
        <DocumentCard styles={documentCardStyles}>
          <DocumentCardDetails styles={{ root: { paddingTop: 15 } }}>
            <Stack
              horizontal
              horizontalAlign='start'
              verticalAlign='center'
              tokens={{ childrenGap: 12 }}
              styles={itemContainerStyles}
            >
              <CommentItem card={selectedCard} />
            </Stack>
          </DocumentCardDetails>
        </DocumentCard>
        <DialogFooter>
          <PrimaryButton onClick={() => onDismiss(true)} text={TextService.format(ButtonOK)} />
          <DefaultButton onClick={() => onDismiss(false)} text={TextService.format(ButtonCancel)} />
        </DialogFooter>
      </Dialog>
    </>
  );
};
