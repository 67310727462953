import { Dialog, DialogFooter, DialogType, PrimaryButton, Stack, Theme, useTheme } from '@fluentui/react';
import * as React from 'react';
import { EnvContext } from 'services/EnvContext';
import { LicenseService } from 'services/LicenseService';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { IVistoPlan } from 'sp';
import * as strings from 'VistoWebPartStrings';
import { defaultModalProps } from './common';

const version = require('root/version.json');

const defaultLogo = require('static/assets/logo-default.png');
const darkLogo = require('static/assets/logo-dark.png');

function isDarkColor(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq < 128;
}

export function LicenseDialog(props: {
  plan: IVistoPlan;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('LicenseDialog'), []);

  const { palette } = useTheme();
  const { hostKind } = React.useContext(EnvContext);

  const srcIcon = isDarkColor(palette.white) ? darkLogo : defaultLogo;

  const statusColor = LicenseService.license?.isLocked ? palette.red : palette.green;

  const status = LicenseService.license?.isLocked
    ? TextService.format(strings.LicenseStatus_Invalid)
    : TextService.format(strings.LicenseStatus_Valid);

  return (
    <Dialog minWidth={500} hidden={false} onDismiss={props.onDismiss} dialogContentProps={{ title: TextService.format(strings.AboutPage_LicenseTitle), type: DialogType.normal }} modalProps={defaultModalProps}>
      <Stack tokens={{ childrenGap: 'm' }}>
        <img style={{ width: 300, height: 69 }} src={srcIcon} />
        {(hostKind !== 'WebMobile' && hostKind !== 'TeamsMobile') &&
          <Stack tokens={{ childrenGap: 's2' }}>
            <Stack.Item>{TextService.format(strings.AboutPage_LicenseInfo)} <span dangerouslySetInnerHTML={{ __html: LicenseService.license?.infoText }} /></Stack.Item>
            <Stack.Item>{TextService.format(strings.AboutPage_LicenseExpires)} <span>{TextService.formatDate(LicenseService.license?.expireDate)}</span></Stack.Item>
            <Stack.Item>{TextService.format(strings.AboutPage_LicenseStatus)} <span style={{ color: statusColor }}>{status}</span></Stack.Item>
          </Stack>
        }
        <Stack tokens={{ childrenGap: 'm' }}>
          <Stack tokens={{ childrenGap: 's2' }}>
            <Stack.Item>{TextService.format(strings.AboutPage_AppVersion, { VERSION: version.VERSION, COMMIT: version.COMMIT })}</Stack.Item>
            <Stack.Item>{TextService.format(strings.AboutPage_AppBuild, { BUILD_DATE: TextService.formatDate(new Date(version.BUILD_DATE)) })}</Stack.Item>
          </Stack>
          {props.plan && <Stack tokens={{ childrenGap: 's2' }}>
            <Stack.Item>{TextService.format(strings.AboutPage_AppDataVersion, { DATA_VERSION: props.plan.planVersion })}</Stack.Item>
            <Stack.Item>{TextService.format(strings.AboutPage_AppDataId, { DATA_ID: props.plan.planId })}</Stack.Item>
          </Stack>}
          <Stack.Item>{TextService.format(strings.AboutPage_Copyright)}</Stack.Item>
          <Stack.Item>{TextService.format(strings.AboutPage_Trademark)}<br /><a href={`https://${TextService.format(strings.AboutPage_Website)}`} target='_blank'>{TextService.format(strings.AboutPage_Website)}</a></Stack.Item>
        </Stack>
      </Stack>
      <DialogFooter>
        <PrimaryButton autoFocus onClick={props.onDismiss}>{TextService.format(strings.ButtonOK)}</PrimaryButton>
      </DialogFooter>
    </Dialog>
  );
}
