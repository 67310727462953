import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogType, Stack, Spinner, PrimaryButton } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { defaultModalProps } from '../common';
import { IVistoPlan } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { EditorUi } from 'frames/TopFrame/drawing/common';
import { InfoBar, useErrorInfo } from 'components';
import { ExportLinkPage } from './ExportLinkPage';
import { ExportPlanFileNamePage } from './ExportPlanFileNamePage';

export function ExportPlanTemplateDialog(props: {
  plan: IVistoPlan;
  editorUi: EditorUi;
  onDismiss?: () => void;
}) {

  React.useEffect(() => trackClient.page('ExportPlanTemplateDialog'), []);

  const { siteUrl, defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);
  const [planUrl, setPlanUrl] = React.useState('');

  const defaultFileName = TextService.makeExportFileName(props.plan.name, '.visplan.template.json');
  const [fileName, setFileName] = React.useState(defaultFileName);
  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [uploading, setUploading] = React.useState(false);

  const [page, setPage] = React.useState<'name' | 'filter' | 'done'>('name');

  const upload = () => {
    setPlanUrl('');
    const filteredPlan = PlanDataService.filterPlan(props.plan, { 
      removeAssignees: true, 
      removeLinks: true, 
      removeDates: true, 
      removeRuntimeInfo: true,
      removeComments: true,
    });

    const templatePlan = {...filteredPlan, name: '{{{planName}}}' };
    
    const filteredPlanJson = JSON.stringify(templatePlan, (k, v) => { if (v !== null) return v; }, 2);

    const guids = {};
    const templateJson = PlanDataService.removeGuids(filteredPlanJson, guids);

    var file = new File([templateJson], fileName, { type: 'application/json' });
    setUploading(true);
    StorageService.get(siteUrl).putFile(siteUrl, selectedFolder, fileName, file).then(addedFile => {
      setPlanUrl(addedFile.fileAbsoluteUrl);
      setPage('done');
      setUploading(false);
    }, (error) => {
      setUploading(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.ExportPlanJsonDialog_UploadError), error });
    });
  };

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.ExportPlanDialog_Title), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss()} >

      <Stack style={{ minHeight: 300 }} tokens={{ childrenGap: 'm' }}>
        <InfoBar {...errorInfo} />

        {page === 'name' && <ExportPlanFileNamePage suffix='.visplan.template.json' fileName={fileName} setFileName={setFileName} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />}
        
        {page === 'done' && <ExportLinkPage link={planUrl} fileName={fileName} />}

      </Stack>

      <Stack horizontal horizontalAlign='end' tokens={{ padding: 'm 0 0 0', childrenGap: 'm' }} grow>
        {page === 'name' && <PrimaryButton disabled={uploading} onClick={() => upload()}>{uploading ? <Spinner style={{ marginRight: 8 }} /> : null}{uploading ? TextService.format(strings.Button_Exporting) : TextService.format(strings.Button_Export)}</PrimaryButton>}
        <DefaultButton onClick={() => props.onDismiss()}>{page === 'done' ? TextService.format(strings.ButtonClose) : TextService.format(strings.ButtonCancel)}</DefaultButton>

      </Stack>

    </Dialog>
  );
}
