
type TemplateParamName = 
  'APPLICATION_ID' | 
  'APPINSIGHTS_INSTRUMENTATIONKEY' |
  'APPINSIGHTS_DETAILS';

export class env {

  public static getTemplateParamValue = (id: TemplateParamName) => {
    const node = document.getElementById(id);
    return node ? node.getAttribute('value') as string : '<NOT CONFIGURED>';
  }

  public static getParamFromUrl = (name: string, defaultValue?: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name) || defaultValue;
  }
}
