import { Stack } from '@fluentui/react/lib/Stack';
import React from 'react';
import strings from 'VistoWebPartStrings';
import { BasicDialog } from './common';
import { TextField } from '@fluentui/react';
import { TextService } from 'services/TextService';

export const EditCustomStringsDialog = (props: {
  customStrings: any;
  onSave: (customStrings: any) => Promise<any>;
  onDismiss: () => void;
}) => {

  const defaultUiStrings = TextService.getDefaultUiStrings();

  const defaultCustomStrings = Object.keys(strings)
    .filter(key => TextService.hasCustomStringPrefix(key))
    .reduce((r, key) => ({ ...r, [key]: props.customStrings?.[key] ?? '' }), {});

  const [customStrings, setCustomStrings] = React.useState<any>(defaultCustomStrings);

  const isPlural = (a: string, b: string) => {
    if (a.endsWith('s')) {
      return a + 'es' === b;
    } else if (a.endsWith('y')) {
      return a.slice(0, a.length - 1) + 'ies' === b;
    } else {
      return a + 's' === b;
    }
  };

  const keys = Object.keys(customStrings);
  const groupped = keys
    .reduce((r, str) => {
      const singular = keys.find(x => isPlural(x, str));
      const plural = keys.find(x => isPlural(str, x));
      if (plural) {
        r.paired.push({ singular: str, plural });
      } else if (singular) {
      } else {
        r.discrete.push(str);
      }
      return r;
    }, { paired: [], discrete: [] });

  const sortedPaired = groupped.paired.sort((a, b) => TextService.compareStrings(b.singular, a.singular));
  const sortedDiscrete = groupped.discrete.sort((a, b) => TextService.compareStrings(b, a));

  const onSave = async () => {
    props.onSave(TextService.clearCustomStrings(customStrings));
  };

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={onSave}
      title={strings.EditCustomStringsDialog_Title}
      buttonOkText={strings.ButtonOK}
      buttonOkBusyText={strings.ButtonOK}
      buttonCancelText={strings.ButtonCancel}
      >
        <Stack tokens={{ childrenGap: 's1' }} style={{ maxHeight: '75vh', overflow: 'auto' }} data-is-scrollable='true'>
          {sortedPaired.map(k => {
            return (
              <Stack key={k.singular} horizontal horizontalAlign='start' tokens={{ childrenGap: 'l2' }}>
                <TextField key={k.singular} label={defaultUiStrings[k.singular]} placeholder={defaultUiStrings[k.singular]}
                  errorMessage={customStrings[k.plural] && !customStrings[k.singular] && TextService.format(strings.ErrorMessage_SingularMissing)}
                  required={!!customStrings[k.plural]}
                  value={customStrings[k.singular]}
                  onChange={(_, val) => setCustomStrings({ ...customStrings, [k.singular]: val })} />
                <TextField key={k.plural} label={defaultUiStrings[k.plural]} placeholder={defaultUiStrings[k.plural]}
                  errorMessage={customStrings[k.singular] && !customStrings[k.plural] && TextService.format(strings.ErrorMessage_PluralMissing)}
                  required={!!customStrings[k.singular]}
                  value={customStrings[k.plural]}
                  onChange={(_, val) => setCustomStrings({ ...customStrings, [k.plural]: val })} />
              </Stack>
            );
          })
          }
          {sortedDiscrete.map(k => (
            <Stack key={k} horizontal horizontalAlign='start'>
              <TextField key={k} label={defaultUiStrings[k]} placeholder={defaultUiStrings[k]}
                value={customStrings[k]}
                onChange={(_, val) => setCustomStrings({ ...customStrings, [k]: val })} />
            </Stack>))
          }
        </Stack>
    </BasicDialog>
  );

};
