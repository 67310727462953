import React from 'react';
import { Stack, ActionButton, IButtonStyles, mergeStyles, useTheme, IStyle } from '@fluentui/react';
import { IVistoListItem, IVistoPlan, KeyResultType, VistoActionItem, VistoAssocItem, VistoDpItem, VistoKeyResultItem, VistoKind } from 'sp';
import { MenuItems } from '../MenuItems';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { ConfirmDeleteDialog, EditAssocDialog } from 'dialogs';
import { EditKeyResultValuesDialog } from 'dialogs/EditKeyResultValuesDialog';
import { HistoryDialog } from './common/HistoryDialog';
import { ExpandButton, RichTextTooltipHost } from 'components';
import { ProgressBarBlock } from './common/ProgressBarBlock';
import { IconButtonMore } from './common';
import { PlanKeyresultsService } from 'services/PlanKeyresultsService';
import { AssigneeBlock } from './common/AssigneeBlock';
import { makeGuidString } from 'shared/guid';
import { ProgressCircle } from './common/ProgressCircle';
import { IItemChanges } from 'services/Interfaces';
import { ChangesService } from 'services/ChangesService';
import { LicenseService } from 'services/LicenseService';
import { KeyResultService } from 'services/KeyResultService';
import { EditKeyResultDialog } from 'dialogs/EditKeyResultDialog';
import { CommandsKeyResult, IKeyResultChanges } from 'services/CommandsKeyResult';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { clearInfoBar, NotificationType, notifyInfoBar } from 'services/Notify';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { useSidebarShareDialog } from './SidebarShareHook';

export const SidebarKeyResult = (props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  dp: VistoDpItem;
  expandable?: boolean;
  children?: React.ReactNode;
}) => {

  const { dispatchCommand, notify, isPlanEditEnabled, isPlanLive, isPlanLocal, isPopupOpen, setIsPopupOpen, propertyBag, selectionRef, setSelection } = React.useContext(AppContext);

  const [isEditValueDialogVisible, setIsEditValueDialogVisible] = React.useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [newAssocDialogVisible, setNewAssocDialogVisible] = React.useState(false);
  const [newKeyResultDialogVisible, setNewKeyResultDialogVisible] = React.useState(false);

  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;

  React.useEffect(() => {
    const isOpen = deleteDialogVisible || isEditValueDialogVisible || newAssocDialogVisible || newKeyResultDialogVisible;
    setIsPopupOpen(isOpen);
    if (isOpen) {
      setSelection({ ...selectionRef.current, kind: VistoKind.KeyResult, guid: props.kr.guid });
    }
  }, [isEditValueDialogVisible, deleteDialogVisible, newAssocDialogVisible, newKeyResultDialogVisible]);

  const getAssocs = () => PlanDataService.getItemsHaving<VistoAssocItem>(props.plan.items, x =>
    x.kind === VistoKind.Assoc && x.krGuid === props.kr.guid
    && PlanDataService.getItemByGuid<VistoActionItem>(props.plan.items, x.actionGuid)?.dpGuid === props.dp.guid);

  const showOnDiagram = props.dp ? getAssocs().some(x => x.showOnDiagram) : props.kr.showOnDiagram;

  const newKeyResult: VistoKeyResultItem = {
    kind: VistoKind.KeyResult,
    soGuid: props.kr.soGuid,
    parentKrGuid: props.kr.guid,
    guid: makeGuidString(),
    units: '%',
    keyResultType: KeyResultType.Numeric
  };
  
  const onSaveKeyResult = (changes: IKeyResultChanges) => {
    const targetsChanges = CommandsKeyResult.getKeyResultListChanges(changes.oldTargets, changes.newTargets);
    return dispatchCommand(CommandsKeyResult.makeKeyResultUpdateCommand(changes.oldKr, changes.newKr, targetsChanges, undefined, notify), { wrap: false });
  };

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const menuItems = [
    MenuItems.getShowKeyResultMenuItem(isEditDisabled, showOnDiagram, () => setShowOnDiagram(!showOnDiagram)),
    MenuItems.getEditResultValueMenuItem(isPopupOpen, isPlanEditEnabled, () => setIsEditValueDialogVisible(true)),
    ...(LicenseService.license?.okrEnabled ? [MenuItems.getNewKeyResultMenuItem(isEditDisabled, () => setNewKeyResultDialogVisible(true))] : []),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, props.kr)),
    MenuItems.getDeleteKeyResultMenuItem(isEditDisabled, () => setDeleteDialogVisible(true)),
  ];

  const deps = PlanDataService.getDependencis(props.plan.items, props.kr);

  const setShowOnDiagram = (show: boolean) => {
    const updates: IItemChanges<VistoAssocItem>[] = props.dp
      ? getAssocs().map(x => ({ item: x, changes: ChangesService.getChanges(x, { showOnDiagram: show }) }))
      : [{ item: props.kr, changes: ChangesService.getChanges(props.kr, { showOnDiagram: show }) }];

    const planSettings = PlanSettingsService.getPlanSettings(props.plan);
    const indicatorsHidden = !planSettings?.showKpiIndicators;

    if (indicatorsHidden) {
      notifyInfoBar(notify, {
        type: NotificationType.warn,
        group: 'IndicatorsHidden',
        message: TextService.format(strings.Message_Warning_IndicatorsHidden),
        actions: [
          {
            title: TextService.format(strings.Button_TurnOn),
            action: async () => {
              await dispatchCommand(PlanSettingsService.makeSetSettingCommand(props.plan, { showKpiIndicators: true }, notify), { wrap: true });
              clearInfoBar(notify, 'IndicatorsHidden');
            }
          }
        ]
      });
    }

    return dispatchCommand(Commands.makeUpdateCommand(updates, notify), { wrap: true });
  };

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const linkStyles: IButtonStyles = {
    root: {
      textAlign: 'left',
      fontWeight: showOnDiagram ? 'bold' : 'normal',
    },
    textContainer: {
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };

  const { targets, actuals } = PlanKeyresultsService.getKeyResultValues(props.plan, props.kr.guid);

  const isExpanded = propertyBag?.expanded?.[props.kr.guid];

  const newAssoc: VistoAssocItem = {
    kind: VistoKind.Assoc,
    guid: makeGuidString(),
    krGuid: null,
    soGuid: null,
    actionGuid: null,
    confidence: 9
  };

  const theme = useTheme();

  const isActive = deleteDialogVisible || isEditValueDialogVisible || newAssocDialogVisible || newKeyResultDialogVisible;
  const isSelected = selectionRef.current?.guid === props.kr.guid;

  const cardStyle = {
    borderRadius: 4,
    borderColor: isSelected ? theme.palette.themePrimary : null,
    borderWidth: isSelected ? 1 : null,
    borderStyle: isSelected ? 'solid' : null,
    backgroundColor: isActive ? theme.palette.themeLight : null,
  };

  const onItemClick = (e: React.MouseEvent) => {
    setSelection({ ...selectionRef.current, kind: VistoKind.SO, guid: props.kr.guid });
    e.stopPropagation();
  }

  return (
    <Stack style={cardStyle} onClick={onItemClick}>
      <Stack horizontal verticalAlign='start'>
        <ExpandButton expandKey={props.kr.guid} disabled={!props.expandable} />
        <Stack grow horizontal verticalAlign='center' tokens={{ childrenGap: 's2' }}>
          <Stack grow horizontal verticalAlign='center' tokens={{ childrenGap: 's2' }}>
            <RichTextTooltipHost tooltip={props.kr.description} >
              <ActionButton styles={linkStyles} disabled={isPopupOpen} onClick={() => setIsEditValueDialogVisible(true)} text={props.kr.name} iconProps={{ iconName: 'Chart' }} />
            </RichTextTooltipHost>
            <AssigneeBlock assignedTo={props.kr.assignedTo} hideDetails />
          </Stack>
          <Stack horizontal verticalAlign='center' horizontalAlign='end' tokens={{ childrenGap: 's2' }}>
            <ProgressBarBlock plan={props.plan} kr={props.kr} targets={targets} actuals={actuals}
              viewStartDate={propertyBag.viewStartDate} viewEndDate={propertyBag.viewEndDate}
              onClick={() => setIsEditValueDialogVisible(true)} />
            <ProgressCircle size={20} plan={props.plan} targets={targets} actuals={actuals} kr={props.kr} viewStartDate={propertyBag.viewStartDate} viewEndDate={propertyBag.viewEndDate} />
            <IconButtonMore menuItems={menuItems} />
          </Stack>
        </Stack>
      </Stack>
      {isExpanded && props.children && <Stack tokens={{ padding: '0 0 0 s1' }}>{props.children}</Stack>}
      {isEditValueDialogVisible && <EditKeyResultValuesDialog plan={props.plan} kr={props.kr} onDismiss={() => setIsEditValueDialogVisible(false)} />}
      {deleteDialogVisible && <ConfirmDeleteDialog planItems={props.plan.items} items={[props.kr, ...deps]} onDelete={deleteConfirmed} onDismiss={() => setDeleteDialogVisible(false)} />}
      {historyDialogVisible && <HistoryDialog item={props.kr} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
      {newAssocDialogVisible && <EditAssocDialog disableAction onDismiss={() => setNewAssocDialogVisible(false)} plan={props.plan} assoc={newAssoc} />}
      {newKeyResultDialogVisible && <EditKeyResultDialog plan={props.plan} kr={newKeyResult} oldTargets={[]} newTargets={KeyResultService.makeDeafultResultTargets(props.plan, newKeyResult)} onDismiss={() => setNewKeyResultDialogVisible(false)} onCommit={onSaveKeyResult} isNew={true} />}
      {ShareLinkDialog}
    </Stack>
  );
};
