import { TokenKind } from 'shared/TokenKind';
import { AuthService } from './AuthService';
import { GraphFI, graphGet, GraphQueryable } from '@pnp/graph';


export class ExcelWorkbookService {

  public static async hasTable(siteUrl: string, excelWorkbook: string, excelTable: string) {
    const drive = await this.getDrive(siteUrl);
    const file = await graphGet(GraphQueryable(drive, `/root:/${excelWorkbook}`));
    const tables = await graphGet(GraphQueryable(drive, `/items/${file.id}/workbook/tables`));
    return tables.some((x: any) => x.name === excelTable);
  }

  private static async getDriveInfo(graph: GraphFI, siteUrl: string) {
    const url = new URL(siteUrl);
    const host = url.host;
    const pathname = url.pathname;
    const driveInfo = await graph.sites.getByUrl(host, pathname).drive();
    return driveInfo;
  }

  public static async getDrive(siteUrl: string) {
    const graph = AuthService.getGraphClient(TokenKind.excel, 'default', 'short');
    const driveInfo = await ExcelWorkbookService.getDriveInfo(graph, siteUrl);
    return graph.drives.getById(driveInfo.id);
  }

  public static async getRootUrl(siteUrl: string) {
    const graph = AuthService.getGraphClient(TokenKind.excel, 'default', 'long');
    const driveInfo = await ExcelWorkbookService.getDriveInfo(graph, siteUrl);
    return decodeURI(driveInfo.webUrl);
  }
}
