import * as React from 'react';
import { DashboardFrame } from 'frames/DashboardFrame/DashboardFrame';
import { PlanWizard } from 'wizard';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { useNavigate } from 'react-router-dom';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { IEnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';

export const WebDashboard = (props: {
}) => {

  const planId = React.useRef('');
  const planName = React.useRef(TextService.format(strings.PlanConfiguration_NewPlanName));
  const siteUrl = UrlService.LOCAL_URL;

  const [isWizardOpen, setIsWizardOpen] = React.useState(false);

  const navigate = useNavigate();

  const onAddNew = () => {
    setIsWizardOpen(true);
  };

  const onWizardFinished = (changed: boolean, newPlanId?: string, newPlanName?: string) => {
    setIsWizardOpen(false);
    if (changed) {
      planId.current = newPlanId;
      planName.current = newPlanName;
      navigate(`/local/${planId.current}`);
    }
  };

  const onLoad = async (ctx: IEnvContext) => {
  };

  return (
    <WebAuthScope name='Web_Dashboard' kind={TokenKind.api} onLoad={onLoad}>
      <DashboardFrame onAddNew={onAddNew} />
      {isWizardOpen && <PlanWizard
        isPopup={true}
        planId={planId.current}
        planName={planName.current}
        siteUrl={siteUrl}
        onDismiss={onWizardFinished}
        trigger='TeamsConfigTab'
      />}
    </WebAuthScope>
  );
};
