import { TooltipHost, useTheme } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { ColorService } from 'services/colorService';
import { TextService } from 'services/TextService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { IWSPlanStatus, IWSUserInfo } from 'shared/ws';
import strings from 'VistoWebPartStrings';

interface IPersonaBarItemInfo {
  oid: string;
  name?: string;
  email?: string;
  color?: string;
  count: number;
  text: string;
  imageUrl?: string;
  imageInitials?: string;
  editor: boolean;
}

const MAX_VISIBLE_USERS = 5;

export const PersonaBar = (props: {
  status: IWSPlanStatus
}) => {

  const { wsContext } = React.useContext(AppContext);
  const theme = useTheme();

  // merge duplicates (when connected using multiple devices for example)
  const combinedUsers: IPersonaBarItemInfo[] = (props.status?.users ?? [])
    .reduce((r: IPersonaBarItemInfo[], u: IWSUserInfo) => {
      const found = r.find(x => x.oid === u.oid);
      const editor = u.connection_id === props.status?.editor;
      if (!found) {
        r.push({
          oid: u.oid,
          name: u.name,
          editor: editor,
          email: u.preferred_username,
          imageUrl: UserInfoPhotoService.getCachedUserPhotoUrl(u.oid),
          count: 1,
          text: u.name,
          color: editor ? 'red' : ColorService.getUserColor(u.preferred_username || u.oid)
        });
      } else {
        found.editor = found.editor || editor;
        found.color = found.editor ? 'red' : ColorService.getUserColor(u.preferred_username || u.oid);
        found.count = found.count + 1;
        found.text = TextService.format(strings.PersonaBar_TitleWithCount, { title: u.name, count: found.count });
      }
      return r;
    }, []);

  const visibleUsers = combinedUsers.slice(0, MAX_VISIBLE_USERS);
  const overflowUsers = combinedUsers.slice(MAX_VISIBLE_USERS);

  if (overflowUsers.length) {
    visibleUsers.push({
      oid: '',
      color: theme.palette.neutralTertiary,
      imageInitials: `+${overflowUsers.length}`,
      count: 1,
      editor: false,
      text: TextService.formatList(overflowUsers.map(u => u.name)),
      email: TextService.formatList(overflowUsers.map(u => u.email)),
    });
  }

  const [users, setUsers] = React.useState(visibleUsers);

  React.useEffect(() => {
    setUsers(visibleUsers);
    if (props.status) {
      Promise.all(visibleUsers.map(a => UserInfoPhotoService.getUserPhotoUrl(a.oid))).then(results => {
        const updated = visibleUsers.map((v, i) => ({
          ...v,
          imageUrl: results[i]
        }));
        setUsers(updated);
      });
    }
  }, [props.status]);

  return (
    users.length > 0 &&
    <Stack horizontal verticalAlign='center' tokens={{ padding: '0 m 0 m', childrenGap: 's1' }}>
      {users.map(u =>
        <TooltipHost
          key={u.oid}
          tooltipProps={{
            onRenderContent: () => {
              return (<Persona
                text={u.text}
                secondaryText={u.email}
                initialsColor={u.color}
                imageUrl={u.imageUrl}
                imageInitials={u.imageInitials}
                imageShouldFadeIn={false}
                size={PersonaSize.size48}
              />);
            }
          }}>
          <Persona
            coinProps={{
              styles: {
                image: {
                  opacity: wsContext?.connectionId ? 1 : 0.3,
                  boxShadow: `0 0 3px 3px ${u.color}`
                }
              }
            }}
            text={u.text}
            initialsColor={u.color}
            imageUrl={u.imageUrl}
            size={PersonaSize.size32}
            imageInitials={u.imageInitials}
            imageShouldFadeIn={false}
            hidePersonaDetails
          />
        </TooltipHost>
      )}
    </Stack>
  );
};
