import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, IVistoListItem } from 'sp';
import { BasicDialog } from 'dialogs/common';
import { trackClient } from 'shared/clientTelemetry';
import { AppContext } from 'services/AppContext';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';

export function ItemEditDialog(props: {
  onLoaded?: (loaded: IVistoListItem) => void;
  onDismiss: (changed: boolean) => void;
  onCommit: () => Promise<any>;
  onValidate?: () => boolean;
  plan: IVistoPlan;
  oldItem: IVistoListItem;
  newItem: IVistoListItem;
  children?: React.ReactNode;
  validationIssues?: boolean;
  isModal?: boolean;
  zIndex?: number
  statusBarItem?: JSX.Element;
  minWidth?: string | number;
  maxWidth?: string | number;
}) {

  React.useEffect(() => trackClient.page('ItemEditDialog'), []);

  const { notify, isPlanEditEnabled } = React.useContext(AppContext);

  const vistoKindName = TextService.getVistoKindName(props.newItem.kind);
  const isExistingItem = !!props.newItem.itemId;
  
  React.useEffect(() => {
    if (props.oldItem?.itemId && isPlanEditEnabled && props.onLoaded) {
      StorageService.get(props.plan.siteUrl).loadItem(props.plan, props.oldItem.kind, props.oldItem.guid, notify).then(loaded => {
        props.onLoaded(loaded);
      }, err => {
        trackClient.error(`Unable to refresh item in plan ${props.plan.planId}: ${props.oldItem.kind} ${props.oldItem.guid}`, err);
      });
    }
  }, [props.plan, props.oldItem]);

  const title = (!isPlanEditEnabled)
    ? TextService.format(strings.ItemEditDialog_TitleView, { vistoKindName })
    : isExistingItem
      ? TextService.format(strings.ItemEditDialog_TitleUpdate, { vistoKindName })
      : TextService.format(strings.ItemEditDialog_TitleCreate, { vistoKindName });

  return (<BasicDialog
    minWidth={props.minWidth}
    maxWidth={props.maxWidth}
    autoFocus={false}
    isModeless={!props.isModal}
    onDismiss={props.onDismiss}
    buttonOkAction={props.onCommit}
    onValidate={props.onValidate}
    isButtonOkDisabled={props.validationIssues || !isPlanEditEnabled}
    title={title}
    buttonOkText={isExistingItem ? TextService.format(strings.ItemEditDialog_ButtonOkUpdate) : TextService.format(strings.ItemEditDialog_ButtonOkCreate)}
    buttonOkBusyText={isExistingItem ? TextService.format(strings.ItemEditDialog_ButtonOkUpdateBusy) : TextService.format(strings.ItemEditDialog_ButtonOkCreateBusy)}
    buttonCancelText={TextService.format(strings.ButtonCancel)}
    zIndex={props.zIndex}
    statusBarItem={props.statusBarItem}
    >
      {props.children}
    </BasicDialog>
  );
}
