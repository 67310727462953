import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { EnvContext } from 'services/EnvContext';
import { trackClient } from 'shared/clientTelemetry';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { UrlService } from 'shared/urlService';
import { CopyLinkDialogBase } from './CopyLinkDialogBase';

export function CopyShareLinkDialog(props: {
  selection: ISelectedCellInfo;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('CopyLinkDialog'), []);

  const { channelId, entityId } = React.useContext(EnvContext);

  const subEntityId = UrlService.makeSubEntityId(props.selection);
  const deepLink = UrlService.makePlanDeepLink(entityId, channelId, subEntityId);

  return (
    <CopyLinkDialogBase
      content={TextService.format(strings.CopyPlanLinkDialog_Content)}
      linkLabel={TextService.format(strings.CopyPlanLinkDialog_LinkLabel)}
      link={deepLink}
      onDismiss={props.onDismiss}
    />
  );
}
