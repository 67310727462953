import { CopyShareLinkDialog } from 'dialogs/CopyShareLinkDialog';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { mx } from '../drawing/common/mx';
import { IVistoListItem } from 'sp';

export const useSidebarShareDialog = (): [JSX.Element, (visible: boolean, item?: IVistoListItem) => void] => {

  const { editorUiRef } = React.useContext(AppContext);

  const selectedCellInfoRef = React.useRef<ISelectedCellInfo>(null);
  const [isCopyPlanLinkDialogVisible, _setIsCopyPlanLinkDialogVisible] = React.useState(false);
  
  const setIsCopyPlanLinkDialogVisible = (visible: boolean, item?: IVistoListItem) => {
    const selection = visible ? mx.getSelection(editorUiRef.current?.editor?.graph) : null;
    if (item) {
      selection.guid = item.guid;
      selection.kind = item.kind;
    }
    selectedCellInfoRef.current = selection;
    _setIsCopyPlanLinkDialogVisible(visible);
  };

  return [
    isCopyPlanLinkDialogVisible && <CopyShareLinkDialog selection={selectedCellInfoRef.current} onDismiss={() => setIsCopyPlanLinkDialogVisible(false)} />,
    setIsCopyPlanLinkDialogVisible
  ];
};
