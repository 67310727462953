import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { IVistoListItem, IVistoPlan, VistoActionItem, VistoKind } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { CopyLinkDialogBase } from './CopyLinkDialogBase';
import { UrlService } from 'shared/urlService';

export function CopyProgressLinkDialog(props: {
  plan: IVistoPlan;
  item: IVistoListItem;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('CopyLinkDialog'), []);

  const { siteUrl, entityId, channelId } = React.useContext(EnvContext);

  const cellKind = UrlService.VistoKindToCellKind(props.item.kind);
  const cellId = (props.item.kind === VistoKind.Action) ? (props.item as VistoActionItem).dpGuid : props.item.guid;
  const selection: ISelectedCellInfo = { cellKind, cellId, kind: props.item.kind, guid: props.item.guid };

  const subEntityId = UrlService.makeSubEntityId(selection);
  const deepLink = UrlService.makePlanDeepLink(entityId, channelId, subEntityId);

  const listUrl = UrlService.getListRelativeUrl(props.plan, props.item.kind);
  const link = `${UrlService.getOrigin(siteUrl)}${listUrl}/DispForm.aspx?ID=${props.item.itemId}&deepLink=${encodeURIComponent(deepLink)}`;

  return (
    <CopyLinkDialogBase
      content={TextService.format(strings.CopyLinkDialog_Content)}
      linkLabel={TextService.format(strings.CopyLinkDialog_ItemLink)}
      link={link}
      onDismiss={props.onDismiss}
    />
  );
}
