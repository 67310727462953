import * as React from 'react';
import { Stack, Text, Checkbox } from '@fluentui/react';
import * as microsoftTeams from '@microsoft/teams-js';
import * as strings from 'VistoWebPartStrings';
import { env } from 'shared/clientEnv';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { trackClient } from 'shared/clientTelemetry';
import { TokenKind } from 'shared/TokenKind';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { IVistoPlan } from 'sp';
import { getObjectValues } from 'shared/parse';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { ApiDashboardService } from 'services/api/ApiDashboardService';
import { setIsLoading } from 'services/LoadingIndicator';
import { IntegrationService } from 'services/IntegrationService';
import { IEnvContext } from 'services/EnvContext';

interface IHookAction {
  key: string;
  label: string;
  checked: boolean;
}

export type HookActionSet = { [key: string]: IHookAction };

/**
 * Implementation of VistoTeams Tab configuration page
 */
export const VistoTeamsTabRemove = () => {

  const [isDeleting, setIsDeleting] = React.useState(false);

  const [deletePlanData, _setDeletePlanData] = React.useState(false);
  const deletePlanDataRef = React.useRef(false); // expose to javascript callback from the teams library
  const setDeletePlanData = (val) => {
    deletePlanDataRef.current = val;
    _setDeletePlanData(val);
  };

  const [hooks, _setHooks] = React.useState<HookActionSet>({});
  const hooksRef = React.useRef<HookActionSet>({});
  const setHooks = (val: HookActionSet) => {
    hooksRef.current = val;
    _setHooks(val);
  };

  const planRef = React.useRef<IVistoPlan>(null);
  const planId = env.getParamFromUrl('planId');
  const isConnectedToUrl = planId.startsWith('https://');

  const [removalMessage, setRemovalMessage] = React.useState('');

  if (isConnectedToUrl) {
    microsoftTeams.pages.config.setValidityState(true);
    setIsLoading('');
  }

  const onLoad = async (ctx: IEnvContext) => {

    const siteUrl = ctx.siteUrl;

    try {
      const planInfo = { siteUrl, planId };
      planRef.current = await StorageService.get(siteUrl).loadPlanItem(planInfo);

      if (!planRef.current) {
        setRemovalMessage(TextService.format(strings.RemovalMessage_PlanMissing));
        microsoftTeams.pages.config.setValidityState(true);
        setIsLoading('');
        return;
      }

      const planSettings = PlanSettingsService.getPlanSettings(planRef.current);
      TextService.setUiLanguage(planSettings.language, planSettings.customStrings);
      planRef.current = await StorageService.get(planRef.current.siteUrl).loadPlanData(planRef.current, null);

      const items = getObjectValues(planRef.current.items);

      const defaultHooks = Object
        .keys(IntegrationService.hooks)
        .map(key => ({
          key: key,
          checked: false,
          label: IntegrationService.hooks[key].removalWarning(items).replace(/<[^>]+>/g, '')
        }))
        .filter(h => h.label)
        .reduce((r, h) => r = { ...r, [h.key]: h }, {});

      setHooks(defaultHooks);

      const onDeleteHandler = (ev: microsoftTeams.pages.config.RemoveEvent) => {

        const deletePlan = async () => {
          await ApiDashboardService.markPlanDeleted(planId, ctx.channelId);
          if (deletePlanDataRef.current) {
            await StorageService.get(siteUrl).deletePlanItem({ siteUrl, planId }, false);

            if (planRef.current) {
              for (const hookKey in hooksRef.current) {
                const hook = hooksRef.current[hookKey];
                if (hook.checked) {
                  try {
                    await IntegrationService.hooks[hook.key].removalAction(items);
                  } catch (error) {
                    trackClient.error(`Failed to clean up the data for the ${hook.key}.`, error);
                  }
                }
              }
            }
          }
        };

        setIsDeleting(true);
        deletePlan().then(() => {
          ev.notifySuccess();
        }, (error: Error) => {
          trackClient.error(`Failed to delete the plan ${planId} from ${siteUrl}.`, error);
          ev.notifyFailure();
        }).finally(() => {
          setIsDeleting(false);
        });
      };

      microsoftTeams.pages.config.registerOnRemoveHandler(onDeleteHandler);
      microsoftTeams.pages.config.setValidityState(true);
      setIsLoading('');
    } catch (error) {
      trackClient.error(`Unable to read the plan ${planId} from ${siteUrl}. Some data cleanup may be skipped.`, error);
      setRemovalMessage(TextService.format(strings.RemovalMessage_Error));
      microsoftTeams.pages.config.setValidityState(true);
      setIsLoading('');
    }
  };

  const MainTitle = ({ title, details }) => (
    <Stack>
      <Text variant='large'>{title}</Text>
      <Text variant='medium'>{details}</Text>
    </Stack>
  );

  return isConnectedToUrl
    ? <MainTitle title={TextService.format(strings.TabRemove_RemovaPlanTitle)} details={TextService.format(strings.TabRemove_RemovaPlanDetails)} />
    : removalMessage
      ? <MainTitle title={TextService.format(strings.TabRemove_RemovaPlanTitle)} details={removalMessage} />
      : <TeamsAuthScope name='Teams_Remove' kind={TokenKind.sharepoint} onLoad={onLoad}>
        <Stack horizontalAlign='start' tokens={{ childrenGap: 'm' }}>
          <MainTitle title={TextService.format(strings.TabRemove_Title)} details={TextService.format(strings.TabRemove_Text)} />
          <Checkbox label={TextService.format(strings.TabRemove_DeleteDataCheckbox)} disabled={isDeleting} checked={deletePlanData} onChange={(ev, checked) => setDeletePlanData(!!checked)} />
          {getObjectValues(hooks).map(h => (
            <Checkbox
              disabled={isDeleting}
              label={h.label}
              checked={h.checked}
              onChange={(ev, checked) => setHooks({ ...hooks, [h.key]: { ...hooks[h.key], checked } })}
            />)
          )}
          {isDeleting && <Text>{TextService.format(strings.TabRemove_Deleting)}</Text>}
        </Stack>
      </TeamsAuthScope>
    ;
};
