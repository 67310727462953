import * as React from 'react';
import { useRef, useState } from 'react';
import { ICommentServices } from '../ICommentServices';
import { MentionsInput, Mention, SuggestionDataItem, MentionItem } from 'react-mentions';
import { useCallback } from 'react';
import { useAddCommentStyles } from './useAddCommentStyles';
import { ActionButton, Text, Stack, Persona, useTheme } from '@fluentui/react';
import { IAddCommentPayload } from '../IComment';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { AppContext } from 'services/AppContext';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { VistoKind } from 'sp';

export const SuggestionCard = (props: {
  suggestion: SuggestionDataItem
}) => {
  const { suggestion } = props;

  const [imageUrl, setImageUrl] = React.useState<string>();
  React.useEffect(() => {
    UserInfoPhotoService.getUserPhotoUrl(suggestion.id as string).then(url => {
      if (url) {
        setImageUrl(url)
      }
    })
  }, [suggestion.id]);

  return (
    <Persona
      text={suggestion.display}
      secondaryText={suggestion.id as string}
      coinSize={40}
      imageUrl={imageUrl}
    />)
}

export const AddComment = (props: {
  onAddComment: (comment: IAddCommentPayload) => Promise<void>;
  services: ICommentServices;
}) => {

  const [commentText, setCommentText] = useState<string>('');
  const { services, onAddComment } = props;
  const { reactMentionStyles, mentionsClasses, componentClasses } = useAddCommentStyles();
  const { selectionRef, planRef } = React.useContext(AppContext);
  const _addCommentText = useRef<IAddCommentPayload>({ mentions: [], text: '' });

  const sugestionsContainer = useRef<HTMLDivElement>();

  const _onChange = useCallback((event, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
    if (newValue) {
      _addCommentText.current.text = newPlainTextValue;
      _addCommentText.current.mentions = [];
      for (let index = 0; index < mentions.length; index++) {
        const mention = mentions[index];
        _addCommentText.current.text = _addCommentText.current.text.replace(mention.display, `@mention{${index}}`);
        _addCommentText.current.mentions.push({
          email: mention.id,
          mentionId: index,
          name: mention.display.replace('@', '')
        });
      }
    }
    setCommentText(newValue);
  }, []);

  const _addComment = async () => {
    await onAddComment(_addCommentText.current);
    setCommentText('');
  };

  const _searchData = (search: string, callback: (users: SuggestionDataItem[]) => void): void => {
    // Try to get sugested users when user type '@'
    if (!search) {
      services.getSuggestions()
        .then((res) => res.map((user) => ({ display: user.name, id: user.preferred_username })))
        .then(callback)
        .catch(() => { /* no-op; */ });
    } else {
      services.getUsers(search)
        .then((res) => res.map((user) => ({ display: user.name, id: user.preferred_username })))
        .then(callback)
        .catch(() => { /* no-op; */ });
    }
  };

  const renderSugestion = useCallback((suggestion: SuggestionDataItem): React.ReactNode => (<SuggestionCard suggestion={suggestion} />), []);

  const selection = selectionRef.current;
  const item = PlanDataService.getItemByGuid(planRef.current.items, selection?.guid);
  const theme = useTheme();

  return (
    <>
      {/** Render Sugestions in the host element */}
      <div
        id='renderSugestions'
        ref={(el) => {
          sugestionsContainer.current = el;
        }}
      />
      <div className={componentClasses.container}>
        <MentionsInput
          value={commentText}
          onChange={_onChange}
          placeholder={TextService.format(strings.Comment_Placeholder)}
          style={reactMentionStyles}
          suggestionsPortalHost={sugestionsContainer.current}
        >
          <Mention
            trigger='@'
            data={_searchData}
            renderSuggestion={renderSugestion}
            displayTransform={(id, display) => `@${display}`}
            className={mentionsClasses.mention}
          />
        </MentionsInput>
        <Stack horizontal tokens={{ padding: 4 }}>
          <Stack grow={1} tokens={{ padding: 4 }}>
            <Text variant='small' style={{ color: theme.palette.neutralSecondary }}>Commenting on:</Text>
            <Text variant='smallPlus' style={{ fontWeight: 700, color: theme.palette.neutralSecondary }}>{item ? TextService.formatTitle(item, planRef.current.items) : TextService.getVistoKindName(VistoKind.Plan)}</Text>
          </Stack>
          <ActionButton
            disabled={!commentText}
            iconProps={{ iconName: 'send' }}
            title={TextService.format(strings.Comment_Save)}
            text={TextService.format(strings.Comments_Send)}
            onClick={_addComment}
          />
        </Stack>
      </div>
    </>
  );
};
