import * as React from 'react';
import { useCommentsStyles } from './useCommentsStyles';
import { IComment, ICommentCard } from '../IComment';
import { DocumentCard, DocumentCardDetails, IconButton, List, Stack, Text } from '@fluentui/react';
import { ConfirmDelete } from './ConfirmDelete';
import { CommentItem } from './CommentItem';
import { ICommentServices } from '../ICommentServices';

export const CommentsList = (props: {
  highlightedCommentId?: string;
  cards: ICommentCard[];
  services: ICommentServices;
  deleteComment: (commentId: string) => Promise<void>;
}) => {

  const { configurationListClasses } = useCommentsStyles();

  const { documentCardStyles, documentCardHighlightedStyles, documentCardDisabledStyles, itemContainerStyles, deleteButtonContainerStyles } = useCommentsStyles();

  const [selectedCard, setSelectedCard] = React.useState<ICommentCard>(null);

  const onRenderCell = React.useCallback((card: ICommentCard, index: number): JSX.Element => {
    const comment = card.comment;
    const styles = (props.highlightedCommentId && comment.guid === props.highlightedCommentId)
      ? documentCardHighlightedStyles
      : card.disabled 
        ? documentCardDisabledStyles
        : documentCardStyles;

    return (
      <DocumentCard styles={styles} key={index}>
        <Stack horizontal horizontalAlign='end' styles={deleteButtonContainerStyles}>
          <IconButton iconProps={{ iconName: 'Delete' }} style={{ fontSize: 10 }} onClick={() => setSelectedCard(card)} />
        </Stack>
        <DocumentCardDetails styles={{ root: { paddingTop: 15 } }}>
          <Stack horizontal horizontalAlign='start' verticalAlign='center' tokens={{ childrenGap: 12 }} styles={itemContainerStyles}>
            <CommentItem card={card} />
          </Stack>
        </DocumentCardDetails>
      </DocumentCard>
    );
  },
    [props.cards]
  );

  const onDeleteConfirmed = async (confirmed: boolean) => {
    if (confirmed) {
      await props.deleteComment(selectedCard.comment.guid);
    }
    setSelectedCard(null);
  }

  return (
    <Stack tokens={{ childrenGap: 10, maxWidth: 335 }}>
      <div className={configurationListClasses.titlesContainer} >
        <Stack>
          <List items={props.cards} onRenderCell={onRenderCell} />
          {selectedCard && <ConfirmDelete selectedComment={selectedCard} onDismiss={onDeleteConfirmed} />}
        </Stack>
      </div>
    </Stack>
  );
};
