import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton, Stack } from '@fluentui/react';
import { defaultModalProps } from 'dialogs/common';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { AuthService } from 'services/AuthService';
import { ChangesService } from 'services/ChangesService';
import { IExecutableAction } from 'services/IExecutableAction';
import { TextService } from 'services/TextService';
import { TokenKind } from 'shared/TokenKind';
import { InfoBar, useErrorInfo } from 'components';
import { stringifyError } from 'shared/parse';
import { IVistoPlan } from 'sp';
import * as strings from 'VistoWebPartStrings';
import { DevOpsService, IDevOpsItemType } from 'integrations/devops';
import { DevOpsWizardProgressPage } from './DevOpsWizardProgressPage';
import { DevOpsWizardStartPage } from './DevOpsWizardStartPage';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { DevOpsWizardConfigPage } from './DevOpsWizardConfigPage';
import { NotificationType } from 'services/Notify';

export const DevOpsWizard = (props: {
  plan: IVistoPlan;
  onDismiss: (changed: boolean, plan?: IVistoPlan) => void;
}) => {

  const [visPlan, setVisPlan] = React.useState<IVistoPlan>(props.plan);

  const { dispatchCommand, notify } = React.useContext(AppContext);
  const planSettings = PlanSettingsService.getPlanSettings(props.plan);
  const devopsSettings = planSettings.integrations?.devops;

  const [enabled, setEnabled] = React.useState(typeof (devopsSettings?.enabled) === 'boolean'
    ? devopsSettings.enabled
    : true);

  const [devopsUrl, setDevOpsUrl] = React.useState(typeof (devopsSettings?.devopsUrl) === 'string'
    ? devopsSettings.devopsUrl
    : `https://dev.azure.com/ENTER-YOUR-ORGANIZATION-HERE`);

  const [itemTypeNames, setItemTypeNames] = React.useState(Array.isArray(devopsSettings?.itemTypeNames)
    ? devopsSettings.itemTypeNames
    : DevOpsService.getDefaultItemTypeNames());

  const [itemTypes, setItemTypes] = React.useState<IDevOpsItemType[]>(DevOpsService.getDefaultItemTypes());

  const [errorInfo, setErrorInfo] = useErrorInfo();

  enum DevOpsWizardPage {
    start,
    config,
    progress,
  }

  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();
  const [actions, setActions] = React.useState<IExecutableAction[]>([]);

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === actions.length || actions.length === 0;

  const [statusText, setStatusText] = React.useState('');

  const pageInfo = {

    [DevOpsWizardPage.start]: {
      subText: TextService.format(strings.DevOpsWizard_StartPageSubTitle),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !(!enabled || enabled && devopsUrl),
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [DevOpsWizardPage.config]: {
      subText: TextService.format(strings.DevOpsWizard_ConfigPageSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !(!enabled || enabled && devopsUrl),
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [DevOpsWizardPage.progress]: {
      subText: TextService.format(strings.DevOpsWizard_ProgressPageSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => false,
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<DevOpsWizardPage>(DevOpsWizardPage.start);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.DevOpsWizard_Title),
    subText: pageInfo[currentPageId].subText
  };

  const onLoad = async () => {
    setErrorInfo(null);
    await DevOpsService.getProjects(devopsUrl);
    const items = await DevOpsService.getWorkItemTypes(devopsUrl);
    setItemTypes(items);
  };

  const onWizardBack = () => {
    switch (currentPageId) {

      case DevOpsWizardPage.progress:
        setCurrentPageId(DevOpsWizardPage.config);
        break;

      case DevOpsWizardPage.config:
        setCurrentPageId(DevOpsWizardPage.start);
        break;
    }
  };

  const prepareActions = () => {
    const oldDevOpsSettings = {
      ...devopsSettings
    };
    const newDevOpsSettings = {
      ...devopsSettings,
      enabled: enabled, 
      devopsUrl: devopsUrl?.replace(/\/$/, ''), // remove trailing slash
      itemTypeNames: itemTypeNames, 
      itemTypes: itemTypes
    };
    const changes = ChangesService.getChanges(oldDevOpsSettings, newDevOpsSettings);
    if (changes.detected) {
      setActions([
        {
          title: TextService.format(strings.MessageDevOps_UpdateSettings),
          execute: p => dispatchCommand(PlanSettingsService.makeSetSettingCommand(p, { integrations: { ...planSettings.integrations, devops: newDevOpsSettings } }, notify), { wrap: false })
        }
      ]);
    } else {
      setActions([]);
    }
  };

  const executeActions = async () => {
    setCurrentProgressActionIndex(undefined);

    const executeAction = async (p: IVistoPlan, i: number) => {
      const action = actions[i];
      setStatusText(action.title);
      setCurrentProgressActionIndex(i);
      try {
        return await action.execute(p);
      } catch (err) {
        action.hasError = true;
        action.errorMessage = stringifyError(err);
        return p;
      }
    };

    let updatedVisPlan = props.plan;
    for (let i = 0; i < actions.length; ++i) {
      updatedVisPlan = await executeAction(updatedVisPlan, i);
    }

    setVisPlan(updatedVisPlan);
    setCurrentProgressActionIndex(actions.length);
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case DevOpsWizardPage.start:
        setCurrentPageId(DevOpsWizardPage.config);
        break;

      case DevOpsWizardPage.config:
        setCurrentPageId(DevOpsWizardPage.progress);
        prepareActions();
        break;

      case DevOpsWizardPage.progress:
        if (executed) {
          props.onDismiss(true, visPlan);
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  return (
    <Dialog
      minWidth={500}
      isBlocking={true}
      dialogContentProps={contentProps} modalProps={defaultModalProps} isOpen={true} onDismiss={() => props.onDismiss(false)}>
      <Stack style={{ minHeight: 200 }}>
        {(currentPageId == DevOpsWizardPage.start)
          ? <DevOpsWizardStartPage enabled={enabled} setEnabled={setEnabled} devopsUrl={devopsUrl} setDevOpsUrl={setDevOpsUrl} />
          : <AuthService.AuthScope name='DevOpsWizard' kind={TokenKind.devops} onLoad={onLoad}>
            <Stack grow tokens={{ childrenGap: 'm' }}>
              <InfoBar {...errorInfo} />
              {(currentPageId == DevOpsWizardPage.config)
                ? <DevOpsWizardConfigPage devopsUrl={devopsUrl} itemTypes={itemTypes} itemTypeNames={itemTypeNames} setItemTypeNames={setItemTypeNames} />
                : (currentPageId == DevOpsWizardPage.progress)
                  ? <DevOpsWizardProgressPage statusText={statusText} actions={actions} currentProgressActionIndex={currentProgressActionIndex} />
                  : null
              }
            </Stack>
          </AuthService.AuthScope>
        }
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={() => props.onDismiss(false)} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
};
