import * as React from 'react';
import { TokenKind } from 'shared/TokenKind';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { IEnvContext } from 'services/EnvContext';
import { DashboardFrame } from 'frames/DashboardFrame/DashboardFrame';
import { UrlService } from 'shared/urlService';
import { useEnvNavigate } from 'services/NavigationService';

export const VistoTeamsTabPersonal = () => {

  const envContextRef = React.useRef<IEnvContext>(null);

  const onLoad = async (ctx: IEnvContext) => {
    envContextRef.current = ctx;
  };

  const navigate = useEnvNavigate(true);

  const onAddNew = () => {
    navigate(UrlService.makeAppDeepLink());
  };

  return (
    <TeamsAuthScope name='Teams_Tab' kind={TokenKind.dashboard} onLoad={onLoad}>
      <DashboardFrame onAddNew={onAddNew} />
    </TeamsAuthScope>
  );
};
