import { trackClient } from 'shared/clientTelemetry';
import { IndexedDbService } from './IndexedDbService';
import { FixedViewType, getGraphSvg, loadGraphBackground, mxglobals } from 'frames/TopFrame/drawing/common';
import { PlanStylesService } from 'services/PlanStylesService';
import { IImageInfo } from 'frames/TopFrame/drawing/images';
import Mustache from 'mustache';
import { IVistoPlan } from 'sp';
import { updateDiagramFromPlanWithDates } from 'frames/TopFrame/drawing/DrawingUpdate';
import { IPropertyBag } from './IPropertyBag';

const DEFAULT_KEEP_MINUTES = 10;

interface IPreviewParams {
  embedImages: boolean;
  drawingXml: string;
  styleJson: string;
  theme: any;
  width: number;
  height: number;
  background?: IImageInfo;

  plan?: IVistoPlan;
  propertyBag?: IPropertyBag,
  includeOverlays?: boolean;
};

export class ImageCacheService {

  private static async getImageBlobAsync(src: string) {
    const data = await IndexedDbService.getItem('images', src);
    return (data && new Date() < new Date(data.ts)) ? data.img as Blob : null;
  }

  private static async setImageBlobAsync(src: string, img: Blob, timeoutMinutes: number) {
    const ts = new Date();
    ts.setMinutes(ts.getMinutes() + timeoutMinutes);
    return await IndexedDbService.setItem('images', src, { img, ts });
  }

  private static async deleteImageBlobAsync(src: string) {
    const data = await IndexedDbService.getItem('images', src);
    if (data) {
      return await IndexedDbService.deleteItem('images', src);
    }
  }

  private static registeredBlobUrls = new Map<string, { url: string, ts: string }>();

  public static async deleteCachedImage(src: string) {
    const existing = this.registeredBlobUrls.has(src);
    if (existing) {
      const data = this.registeredBlobUrls.get(src);
      URL.revokeObjectURL(data.url);
      this.registeredBlobUrls.delete(src);
    }
    await this.deleteImageBlobAsync(src);
  }

  public static getCachedImageUrl(src: string) {
    if (this.registeredBlobUrls.has(src)) {
      const data = this.registeredBlobUrls.get(src);
      return (new Date() < new Date(data.ts)) ? data.url : undefined;
    }
  }

  public static setCachedImageUrl(src: string, url: string, timeoutSeconds: number) {
    const ts = new Date();
    ts.setSeconds(ts.getSeconds() + timeoutSeconds);
    this.registeredBlobUrls.set(src, { ts: ts.toJSON(), url });
  }

  public static setCachedImageBlob(src: string, blob: Blob, timeoutMinutes: number) {
    const existing = this.registeredBlobUrls.has(src);
    if (existing) {
      const data = this.registeredBlobUrls.get(src);
      URL.revokeObjectURL(data.url);
    }
    const url = blob ? URL.createObjectURL(blob) : null;
    this.setCachedImageUrl(src, url, timeoutMinutes * 60);
    return url;
  }

  public static async getCachedImageUrlAsync(src: string, timeoutMinutes = DEFAULT_KEEP_MINUTES) {
    try {
      const registered = this.getCachedImageUrl(src);
      if (typeof registered !== 'undefined') {
        return registered;
      }

      const blob = await this.getImageBlobAsync(src)
      if (blob) {
        return this.setCachedImageBlob(src, blob, timeoutMinutes);
      }

    } catch (err) {
      trackClient.warn(`Unable to resolve cached image`, err);
    }
  }

  public static async getImageAsync(src: string, load: (url: string) => Promise<Blob>, timeoutMinutes = DEFAULT_KEEP_MINUTES): Promise<string> {
    try {
      const cached = await this.getCachedImageUrlAsync(src, timeoutMinutes);
      if (typeof cached !== 'undefined') {
        return cached;
      }

      const loadBlob = async () => {
        try {
          return await load(src);
        } catch (err) {
          trackClient.warn(`Error loading image`, err);
          return null;
        }
      }

      const blob = await loadBlob();
      const success = await this.setImageBlobAsync(src, blob, timeoutMinutes);
      if (success) {
        return this.setCachedImageBlob(src, blob, timeoutMinutes);
      }

    } catch (err) {
      trackClient.warn(`Error caching image`, err);
    }
  }

  public static async getPreviewSvg(params: IPreviewParams): Promise<string> {

    const div = document.createElement('div');
    div.style.height = `${params.height}px`;
    div.style.width = `${params.width}px`;

    const mxStylesheet = PlanStylesService.getMxStylesheet(params.styleJson);
    const mxThemes = {};
    const editorStyles = Mustache.render(mxStylesheet, params.theme);
    const node = new DOMParser().parseFromString(editorStyles, 'text/xml').documentElement;
    mxThemes['default'] = node;

    const graph = new mxglobals.Graph(div, null, null, null, mxThemes);
    const doc = mxglobals.mxUtils.parseXml(params.drawingXml);
    var dec = new mxglobals.mxCodec(doc);
    dec.decode(doc.documentElement, graph.getModel());

    if (params.plan) {
      updateDiagramFromPlanWithDates(graph, params.plan, params.propertyBag, null, null, null);
    }

    loadGraphBackground(graph, doc.documentElement);

    const background = params.background;
    if (background) {
      graph.background = background.backgroundColor;
      graph.backgroundImageAspect = background.resizeType !== FixedViewType.None;
      graph.backgroundImageSlice = background.resizeType === FixedViewType.FillPage;
      graph.backgroundImageOpacity = background.opacity;
      graph.setBackgroundImage(background.src ? new mxglobals.mxImage(background.src, background.width, background.height) : null);
    }

    const svgXml = await getGraphSvg(graph, { usePage: true, embedImages: params.embedImages, width: params.width, height: params.height, includeOverlays: params.includeOverlays });
    return svgXml;
  }

  public static async getPreviewPng(params: IPreviewParams): Promise<ArrayBuffer> {
    const svg = await this.getPreviewSvg(params);

    const img = new Image();
    return new Promise<ArrayBuffer>((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const w = params.width;
        const h = params.height;
        canvas.width = w;
        canvas.height = h;
        canvas.getContext('2d').drawImage(img, 0, 0);
        canvas.toBlob(async blob => {
          try {
            const ab = await blob.arrayBuffer();
            resolve(ab);
          } catch (err) {
            reject(err);
          }
        });
      };
      img.onerror = (err) => {
        reject(err);
      }
      img.src = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);
    });

  }
}
