import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogType, Stack, Spinner, PrimaryButton } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { PlanDataService, IPlanExportFilter } from 'services/PlanDataService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { defaultModalProps } from '../common';
import { IVistoPlan } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { EditorUi } from 'frames/TopFrame/drawing/common';
import { InfoBar, useErrorInfo } from 'components';
import { ExportLinkPage } from './ExportLinkPage';
import { ExportPlanFilterPage } from './ExportPlanFilterPage';
import { ExportPlanFileNamePage } from './ExportPlanFileNamePage';

export function ExportPlanJsonDialog(props: {
  plan: IVistoPlan;
  editorUi: EditorUi;
  onDismiss?: () => void;
}) {

  React.useEffect(() => trackClient.page('ExportPlanJsonDialog'), []);

  const { siteUrl, defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);
  const [planUrl, setPlanUrl] = React.useState('');

  const defaultFileName = TextService.makeExportFileName(props.plan.name, '.json');
  const [fileName, setFileName] = React.useState(defaultFileName);
  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [uploading, setUploading] = React.useState(false);

  const [page, setPage] = React.useState<'name' | 'filter' | 'done'>('name');

  const allKeys = PlanDataService.getLops(props.plan).reduce((r, lop) => 
    [...r, lop.guid, ...PlanDataService.getLopDps(props.plan, lop.guid).reduce((rdp, dp) => [...rdp, dp.guid], [])], []);

  const upload = () => {
    setPlanUrl('');
    const filteredPlan = PlanDataService.filterPlan(props.plan, filter);
    const json =  JSON.stringify(filteredPlan, (k, v) => { if (v !== null) return v; }, 2);
    var file = new File([json], fileName, { type: 'application/json' });
    setUploading(true);
    StorageService.get(siteUrl).putFile(siteUrl, selectedFolder, fileName, file).then(addedFile => {
      setPlanUrl(addedFile.fileAbsoluteUrl);
      setPage('done');
      setUploading(false);
    }, (error) => {
      setUploading(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.ExportPlanJsonDialog_UploadError), error });
    });
  };

  const defaultFilter: IPlanExportFilter = {
    removeLinks: true,
    removeAssignees: false,
    removeComments: false,
    removeDates: false,
    filterItems: false,
    selectedKeys: allKeys
  };

  const [filter, setFilter] = React.useState<IPlanExportFilter>(defaultFilter);

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.ExportPlanDialog_Title), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss()} >

      <Stack style={{ minHeight: 300 }} tokens={{ childrenGap: 'm' }}>
        <InfoBar {...errorInfo} />

        {page === 'name' && <ExportPlanFileNamePage suffix='.json' fileName={fileName} setFileName={setFileName} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />}
        {page === 'filter' && <ExportPlanFilterPage plan={props.plan} filter={filter} setFilter={setFilter} />}
        {page === 'done' && <ExportLinkPage link={planUrl} fileName={fileName} />}
      </Stack>

      <Stack horizontal horizontalAlign='end' tokens={{ padding: 'm 0 0 0', childrenGap: 'm' }} grow>
        {page === 'name' && <PrimaryButton onClick={() => setPage('filter')}>{TextService.format(strings.PlanWizard_Next)}</PrimaryButton>}

        {page === 'filter' && <PrimaryButton onClick={() => setPage('name')}>{TextService.format(strings.PlanWizard_Back)}</PrimaryButton>}
        {page === 'filter' && <PrimaryButton disabled={uploading} onClick={() => upload()}>{uploading ? <Spinner style={{ marginRight: 8 }} /> : null}{uploading ? TextService.format(strings.Button_Exporting) : TextService.format(strings.Button_Export)}</PrimaryButton>}

        <DefaultButton onClick={() => props.onDismiss()}>{page === 'done' ? TextService.format(strings.ButtonClose) : TextService.format(strings.ButtonCancel)}</DefaultButton>

      </Stack>

    </Dialog>
  );
}
