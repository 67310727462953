import * as React from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { TokenKind } from 'shared/TokenKind';
import { ITokenResponse } from 'shared/api/token';
import { UrlService } from 'shared/urlService';
import { GraphFI, graphfi } from '@pnp/graph';
import { DefaultHeaders, DefaultInit, SPFI, spfi, SPFx } from '@pnp/sp';
import { DefaultParse, Queryable, Caching } from '@pnp/queryable';
import { IEnvContext } from './EnvContext';
import { parseResponseError, parseResponseJSON } from 'shared/parse';
import { RateLimitedFetchWithRetry } from './RateLimitedFetchWithRetry';
import { PnPClientStorage } from '@pnp/core';
import { CachingType } from './CachingType';
import { SpDefaultHeaders } from './SpDefaultHeaders';

export type TokenProvider = (kind: TokenKind, host: string) => Promise<string>;

export interface IAuthScopeProps {
  name: string;
  kind: TokenKind;
  siteUrl?: string;
  onLoad: (ctx: IEnvContext) => Promise<any>;
  children: React.ReactNode;
}

export type ScopeProvider = (props: IAuthScopeProps) => JSX.Element;

export type ConsentProvider = (kind: TokenKind, domain: string, callback: () => Promise<any>) => Promise<any>;

export function AuthBehavior(kind: TokenKind, domain: string): (instance: Queryable) => Queryable {

  return (instance: Queryable) => {

    instance.on.auth.replace(async (url: URL, init: RequestInit) => {

      const accessToken = await AuthService.getAuthToken(kind, domain);
      init.headers = { ...init.headers, Authorization: `Bearer ${accessToken}` };

      return [url, init];
    });

    return instance;
  };
}

export enum UserTeamRole {
  Admin = 0,
  User = 1,
  Guest = 2
}

interface IAuthServiceConfig {
  spfxContext?: any;
  defaultBaseUrl?: string;
  tid?: string;
  userObjectId?: string;
  userTeamRole?: UserTeamRole;
};

export class AuthService {

  private static config: IAuthServiceConfig = {};

  public static configure(config: IAuthServiceConfig) {
    AuthService.config = {...AuthService.config, ...config};
  }

  public static getUserTeamRole() {
    return this.config.userTeamRole;
  }

  public static makeBaseUrl(url?: string) {
    return url
      ? url.match(/^(local|http|https):/i)
        ? url
        : url.startsWith('/')
          ? UrlService.combine(UrlService.getOrigin(this.config.defaultBaseUrl), url)
          : UrlService.combine(this.config.defaultBaseUrl, url)
      : this.config.defaultBaseUrl;
  }

  private static spClients: { [key: string]: SPFI } = {};
  private static graphClients: { [key: string]: GraphFI } = {};

  private static addCaching<T extends SPFI | GraphFI>(instance: T, caching?: CachingType) {
    if (caching === 'short') {
      return instance.using(Caching({
        store: 'session',
        keyFactory: url => url?.toLowerCase(),
        expireFunc: url => new Date(new Date().getTime() + 5 * 60 * 1000)
      }));
    }
    if (caching === 'long') {
      return instance.using(Caching({
        store: 'local',
        keyFactory: url => url?.toLowerCase(),
        expireFunc: url => new Date(new Date().getTime() + 2 * 60 * 60 * 1000)
      }));
    }
    return instance;
  }

  public static async fetch(tokenKind: TokenKind, input, init?) {
    const token = await this.getAuthToken(tokenKind, UrlService.getDomain(input));

    const defaultHeaders = {
      'Accept': 'application/json;odata=nometadata',
      'Content-Type': 'application/json',
    };

    const headers = {
      ...(init?.headers ?? defaultHeaders),
      'Authorization': `Bearer ${token}`
    };

    return await fetch(input, {...init, headers });
  }

  public static notifyThrottling: (timeout: number) => void = null;

  public static throttlingNotification (timeout: number) {
    if (this.notifyThrottling) {
      this.notifyThrottling(timeout);
    }
  }

  public static getSpClient(url?: string, caching?: CachingType): SPFI {

    const baseUrl = this.makeBaseUrl(url);
    const key = `${url}|${caching}`;

    if (this.spClients[key]) {
      return this.spClients[key];
    }

    const sp = spfi(baseUrl);

    if (this.config.spfxContext) {
      sp.using(SPFx(this.config.spfxContext));
    } else {
      sp.using(
        SpDefaultHeaders(),
        DefaultInit(),
        RateLimitedFetchWithRetry({ domain: UrlService.getDomain(baseUrl), throttlingNotification : this.throttlingNotification }),
        DefaultParse(),
        AuthBehavior(TokenKind.sharepoint, UrlService.getDomain(baseUrl))
      );
    }

    this.addCaching(sp, caching);

    const storage = new PnPClientStorage();
    storage.session.deleteExpired();
    storage.local.deleteExpired();

    this.spClients[key] = sp;
    return sp;
  }

  public static getGraphClient(kind: TokenKind, oid: string, caching?: CachingType): GraphFI {

    const key = `${kind}|${oid}|${caching}`;

    if (this.graphClients[key]) {
      return this.graphClients[key];
    }

    let graph = graphfi('https://graph.microsoft.com/v1.0');

    if (this.config.spfxContext) {
      graph.using(SPFx(this.config.spfxContext));
    } else {
      graph.using(
        DefaultHeaders(),
        DefaultInit(),
        RateLimitedFetchWithRetry({ domain: 'graph.microsoft.com', rate: 25, throttlingNotification : this.throttlingNotification }),
        DefaultParse(),
        AuthBehavior(kind, `${kind}`)
      );
    }

    this.addCaching(graph, caching);

    this.graphClients[key] = graph;

    return graph;
  }

  public static TokenProvider: TokenProvider;
  public static AuthScope: ScopeProvider;
  public static getConsent: ConsentProvider;

  public static resetAuth(kind?: TokenKind) {
    for (const key in localStorage) {
      const filter = kind ? `auth.${TokenKind[kind]}` : 'auth.';
      if (key.startsWith(filter))
        localStorage.removeItem(key);
    }
  }

  private static requestedTokens = new Map<string, { count: number, promise: Promise<string> }>();

  public static async getAuthToken(kind: TokenKind, host: string) {

    const key = `auth.visplan.${TokenKind[kind]}.${host}.${this.config.tid}.${this.config.userObjectId}`;

    const current = localStorage.getItem(key);
    if (current) {
      const payload = jwtDecode<JwtPayload>(current);
      if (new Date() < new Date(payload.exp * 1000))
        return current;
    }

    if (!this.TokenProvider) {
      throw new Error('Token provider must be initialized before calling getAuthToken');
    }

    try {
      if (this.requestedTokens.has(key)) {
        const val = this.requestedTokens.get(key);
        val.count = val.count + 1;
      } else {
        this.requestedTokens.set(key, {
          count: 1,
          promise: this.TokenProvider(kind, host)
        });
      }

      const token = await this.requestedTokens.get(key).promise;

      if (token) {
        localStorage.setItem(key, token);
        return token;
      }

    } finally {
      const val = this.requestedTokens.get(key);
      val.count = val.count - 1;
      if (!val.count) {
        this.requestedTokens.delete(key);
      }
    }

    throw new Error('Authorization issue, please try reloading the page');
  }

  public static async getServerSideToken(getIdToken: () => Promise<string>, kind: TokenKind, host: string): Promise<string> {

    return new Promise<string>((resolve, reject) => {
      getIdToken().then(token => {
        fetch('/auth/token', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ kind, host }),
          mode: 'cors',
          cache: 'default'
        }).then(response => {
          if (response.ok) {
            parseResponseJSON(response).then((json: ITokenResponse) => {
              resolve(json.token);
            }, err => {
              reject(err);
            });
          } else {
            parseResponseError(response).then(err => reject(err));
          }
        }, err => {
          reject(err);
        });
      }, err => {
        reject(err);
      });
    });
  }

}
