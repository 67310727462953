import * as React from 'react';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { SidebarPlan, ISidebarPlanProps } from 'frames/TopFrame/sidebars/common';
import { SidebarPositionSo } from './SidebarPositionSo';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { VistoKind } from 'sp';
import { EditSoDialog } from 'dialogs';
import { Container, DropResult } from '@edorivai/react-smooth-dnd';
import { makeGuidString } from 'shared/guid';
import { LicenseService } from 'services/LicenseService';

export function SidebarPosition(props: ISidebarPlanProps) {

  const [newSoDialogVisible, setNewSoDialogVisible] = React.useState(false);

  const { notify, isPlanEditEnabled, isPopupOpen, setIsPopupOpen, dispatchCommand, propertyBag, setPropertyBag } = React.useContext(AppContext);
  React.useEffect(() => {
    setIsPopupOpen(newSoDialogVisible);
  }, [newSoDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;

  const menuItems = [
    MenuItems.getNewSoMenuItem(isEditDisabled, () => setNewSoDialogVisible(true)),
  ];

  const farItems = [
    MenuItems.getShowMenu([
      MenuItems.getShowAssigneesMenu(propertyBag, setPropertyBag),
      ...(LicenseService.license?.okrEnabled ? [MenuItems.getShowIndicatorsMenu(propertyBag, setPropertyBag)] : []),
      MenuItems.getDividerMenuItem(4),
      MenuItems.getShowAdvisoryMenu(propertyBag, setPropertyBag)
    ])
  ];

  const newSo = {
    kind: VistoKind.SO,
    guid: makeGuidString(),
  };

  const defaultSos = PlanDataService.getItems(props.plan.items, VistoKind.SO);
  const [sos, setSos] = React.useState(defaultSos);
  React.useEffect(() => setSos(defaultSos), [props.plan.items]);

  const onDrop = (dropResult: DropResult) => {
    const reordered = Commands.getReorderedItems(sos, dropResult.removedIndex, dropResult.addedIndex);
    setSos(reordered);
    const command = Commands.makeChangeSortOrderCommand(reordered, notify);
    dispatchCommand(command, { wrap: true });
  };

  return <SidebarPlan
    isOpen={props.isOpen}
    onDismiss={() => props.onDismiss()}
    plan={props.plan}
    nameProp='esName'
    descriptionProp='esDescription'
    navigationLinkProp='navigationLink'
    menuItems={menuItems}
    farItems={farItems}
    advisoryTopicKey='POSITION'
    content={
      <>
        {/* {propertyBag?.showIndicators && <SidebarTimeFrameBlock plan={props.plan} />} */}
        {sos.length > 0 && <Container getGhostParent={() => document.body} onDrop={onDrop} getChildPayload={i => sos[i]} >
          {sos.map(so => <SidebarPositionSo key={so.guid} plan={props.plan} so={so} />)}
        </Container>}
        {newSoDialogVisible && <EditSoDialog onDismiss={() => setNewSoDialogVisible(false)} plan={props.plan} so={newSo} />}
      </>
    }
  />;
}
