import * as React from 'react';
import { useState } from 'react';
import { IMention } from '../IComment';
import { Text } from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import { isArray, isObject } from 'lodash';
import regexifyString from 'regexify-string'
import { decodeHTML } from 'entities';
import { Persona, PersonaSize, TooltipHost, useTheme } from '@fluentui/react';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';

const MentionItem = (props: { mention: IMention }) => {
  const { mention } = props;

  const [imageUrl, setImageUrl] = React.useState<string>();
  React.useEffect(() => {
    UserInfoPhotoService.getUserPhotoUrl(mention.email).then(url => {
      if (url) {
        setImageUrl(url)
      }
    })
  }, [mention.email]);

  const theme = useTheme();

  return (
    <TooltipHost
      content={<Persona
        key={mention.mentionId}
        text={mention.name}
        secondaryText={mention.email}
        imageUrl={imageUrl}
        imageShouldFadeIn={false}
        size={PersonaSize.size48}
      />}>
      <strong style={{ color: theme.palette.themePrimary }}>@{mention.name}</strong>
    </TooltipHost>
  );
}

export const CommentText = (props: {
  text: string;
  mentions: IMention[];
}) => {
  const [commentText, setCommentText] = useState<string | JSX.Element[]>('');
  const { text, mentions } = props;
  const mentionsResults: IMention[] = mentions;

  React.useEffect(() => {
    const hasMentions = mentions?.length ? true : false;
    let result: string | JSX.Element[] = text;
    if (hasMentions) {
      result = regexifyString({
        pattern: /@mention\{\d+\}/g,
        decorator: (match, index) => {
          const mention = mentionsResults[index];
          return (<MentionItem mention={mention} />);
        },
        input: text,
      }) as JSX.Element[];
    }
    setCommentText(result);
  }, [mentions, text]);

  return (
    <>
      <Stack wrap tokens={{ padding: 's1 0' }} horizontal horizontalAlign='start' verticalAlign='center'>
        {isArray(commentText) ? (
          (commentText as any[]).map((el, i) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            if (isObject(el)) {
              return <span key={i} style={{ paddingRight: 5 }}>{el}</span>;
            } else {
              const _el: string = el.trim();
              if (_el.length) {
                return (
                  <Text style={{ paddingRight: 5 }} variant='medium' key={i}>
                    {decodeHTML(_el)}
                  </Text>
                );
              }
            }
          })
        ) : (
          <Text variant='small'>{decodeHTML(commentText)}</Text>
        )}
      </Stack>
    </>
  );
};
