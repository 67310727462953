import React from 'react';
import { IPanelStyles, Panel, PanelType, Text } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { useCommentSidebarService } from './CommentSidebarService';
import { CommentsList } from './components/CommentsList';
import { IAddCommentPayload, IComment, ICommentCard } from './IComment';
import { InfoBar, useErrorInfo } from 'components';
import { AddComment } from './components/AddComment';
import { AppContext } from 'services/AppContext';
import { UrlService } from 'shared/urlService';
import { CellKind } from 'shared/CellKind';
import { ApiEmailService } from 'services/api/ApiEmailService';
import { IEmailRequest } from 'shared/api/email';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const CommentSidebar = (props: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {

  const { hostKind, channelId, entityId } = React.useContext(EnvContext);
  const isWeb = (hostKind === 'WebMobile' || hostKind === 'WebDesktop');

  const { selectionRef } = React.useContext(AppContext);

  const sidebarStyles = {
    main: {
      animation: 'none',
      marginTop: isWeb ? 248 : 200,
      borderTopRightRadius: 4,
    },
    content: {
      paddingTop: 8,
    },
  };

  const [cards, setCards] = React.useState<ICommentCard[]>([]);

  const filteredCards = React.useMemo(() => {
    return cards.filter(c => {
      const filter = UrlService.parseSubEntityId(c.comment.subEntityId);
      return !selectionRef.current || selectionRef.current.guid === filter?.guid  || (selectionRef.current.cellKind === CellKind.TITLE && !filter);
    });
  }, [cards, selectionRef.current]);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const services = useCommentSidebarService();

  const loadComments = async () => {
    try {
      const comments = await services.getComments();
      const cards = comments.map(c => ({ comment: c, disabled: false }));
      setCards(cards);
    } catch (error) {
      setErrorInfo(error);
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      loadComments();
    }
  }, [props.isOpen]);

  const addComment = async (commentText: IAddCommentPayload) => {
    try {
      setErrorInfo(null);
      const created = services.makeComment(commentText);

      const preview = [{ comment: created, disabled: true }, ...cards];
      setCards(preview);

      const link = UrlService.makePlanDeepLink(entityId, channelId, created.subEntityId);
      const author = created.author.name;

      if (created.mentions.length > 0) {
        const email: IEmailRequest = {
          subject: TextService.format(strings.Comment_EmailSubject),
          body: TextService.format(strings.Comment_EmailBody, { link, author }),
          to: created.mentions.map(m => ({ address: m.email })),
        };
        await ApiEmailService.sendEmail(email);
      }

      const loaded = await services.getComments();
      const updated = [created, ...loaded];
      await services.setComments(updated);

      const udpatedCards = updated.map(c => ({ comment: c, disabled: false }));
      setCards(udpatedCards);

    } catch (error) {
      setErrorInfo(error);
    }
  }

  const deleteComment = async (commentId: string) => {
    try {
      const preview = cards.map(c => c.comment.guid === commentId ? { ...c, disabled: true } : c);
      setCards(preview);

      const updated = await services.getComments();
      const filtered = updated.filter(c => c.guid !== commentId);
      await services.setComments(filtered);

      const udpatedCards = filtered.map(c => ({ comment: c, disabled: false }));
      setCards(udpatedCards);
      
    } catch (error) {
      setErrorInfo(error);
    }
  }

  return <Panel
    type={PanelType.customNear}
    customWidth='400px'
    isBlocking={false}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    headerText={TextService.format(strings.Comment_PanelTitle)}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ styles: { root: { zIndex: 999 } } }}
  >
    <AddComment onAddComment={addComment} services={services} />
    <InfoBar {...errorInfo} />
    <CommentsList cards={filteredCards} deleteComment={deleteComment} services={services} />
  </Panel>;

}
